$nb-primary: #001341;
$nb-primary-lighter: #e8f1f7;
$nb-accent: #00d9ac;

$nb-primary-light: desaturate(lighten($nb-primary, 50%), 60);
$nb-primary-dark: desaturate(darken($nb-primary, 0%), 0);
$nb-primary-darker: desaturate(darken($nb-primary, 0%), 0);

$nb-accent-light: lighten($nb-accent, 10%);
// Cubic CTS aqua color is #00adbb
$nb-accent-bright: darken($nb-accent, 5%);
$nb-accent-dark: darken($nb-accent, 5%);
$nb-accent-darker: darken($nb-accent, 10%);

$nb-neutral-light: #f4f4f4;
$nb-neutral: #eaeaea;
$nb-neutral-dark: #dbdbdb;

$nb-textcolor: #3d3e40;
$nb-textcolor-lighter: lighten($nb-textcolor, 30%);
$nb-textcolor-light: lighten($nb-textcolor, 15%);

// Basic Colors
$navy: $nb-accent; // Primary color
$dark-gray: #c2c2c2; // Default color
$blue: #1c84c6; // Success color
$lazur: #23c6c8; // Info color
$yellow: #f8ac59; // Warrning color
$red: #ed5565; // Danger color

// Various colors
$text-color: #676a6c; // Body text
$gray: #f3f3f4; // Background wrapper color
$light-gray: #d1dade; // Default label, badget
$label-badget-color: #5e5e5e;
$light-blue: #f3f6fb;

// Spiner color and margin
$spin-color: $navy;
$spin-margin: 0 auto;

// IBOX colors ( default panel colors)
$border-color: #e7eaec; // IBox border
$ibox-title-bg: #ffffff; // IBox Background header
$ibox-content-bg: #ffffff; // IBox Background content

//Sidebar width
$sidebar-width: 140px;

// Boxed layout width
$boxed-width: 1200px;
$boxed-backgound: url("/assets/patterns/shattered.png");

//Border radius for buttons
$btn-border-radius: 3px;

//Navigation
$nav-bg: #2f4050;
$nav-profil-pattern: url("/assets/patterns/header-profile.png");
$nav-text-color: #a7b1c2;

//TEMPORARY: FOR NOTES
$temp: #e35729;
$white: #fff;

//ADHERENCE / STATUS
$bad: #e89015;
$bad-caution: #ffb852;

$acceptable-dark: #0c9f55;
$acceptable: #14ad3d;

$reallybad: #b00808;
$reallybad-caution: #eb3b3b;

$off-job: #fff; //Add a gray border
$disabled: #c3c3c4;

$nb-font: "Roboto", sans-serif;

/* set higher than imports due to dependencies - could rework this */
$nb-map-label-shadow: 0 0 4px rgba(0, 0, 0, 0.5);

.truncate {
	word-wrap: normal;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
