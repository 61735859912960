.leaflet-label {
	background: #ffffff;
	background-clip: padding-box;
	border-color: $nb-border-color;
	border-radius: $nb-border-radius;
	border-style: solid;
	border-width: 1px;
	display: block;
	padding: 1px 6px;
	position: absolute;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	white-space: nowrap;
	z-index: 6;
}

.leaflet-label.leaflet-clickable {
	cursor: pointer;
}

.leaflet-label:before,
.leaflet-label:after {
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
	content: none;
	position: absolute;
	top: 5px;
}

.leaflet-label:before {
	border-right: $nb-border;
	border-right-color: inherit;
	left: -10px;
}

.leaflet-label:after {
	border-left: $nb-border;
	border-left-color: inherit;
	right: -10px;
}

.leaflet-label-right:before,
.leaflet-label-left:after {
	content: "";
}

.leaflet-control-layers.leaflet-control {
	display: none;
}
