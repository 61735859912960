//Vehicle labels

/* ensures labels are always shown on top */
.leaflet-marker-icon:hover {
	z-index: 10000 !important;
}

@mixin data-value {
	text-align: center;
	padding: 3px 6px;
	border-radius: 2px;
	margin: 0 2px;
	height: 22px;
	padding-top: 4px;
}

.vehicle-map-labels {
	.label {
		display: inline-block;
		border: 1px solid $white;
		background-color: $white;
		box-shadow: $nb-map-label-shadow;
		overflow: hidden;
		padding: 2px 6px 2px 8px;
		font-size: 12px;
		line-height: 14px;

		.occupancy {
			margin-right: 2px;
		}

		.test.flexrow {
			padding-left: 10px;
			padding-right: 10px;
		}

		.label-route {
			padding-top: 5px !important;
			padding-bottom: 4px !important;
			margin-right: 2px;
			height: 22px;
		}

		&:hover {
			cursor: pointer;

			.label-summary + .label-details {
				display: block;
				z-index: 9000;
				margin-top: 2px;
				margin-bottom: 2px;
				margin-left: -16px;
				margin-right: -16px;
			}

			.label-summary + .label-details .label-route {
				width: 100%;
			}

			.label-summary {
				display: none;
				z-index: 2;
			}
		}

		.label-summary + .label-details {
			display: none;
			z-index: 2;
		}

		// The small view - visible when the labels are on initially. Details show on hover
		.label-summary {
			display: flex;
			margin: 0 -6px;
			z-index: 2;
			.data {
				display: flex;
				@include nb-map-label-text;
			}
		}

		.emdash {
			text-align: center;
			background-color: $nb-neutral;
			color: $nb-textcolor;
			padding: 3px 6px;
			border-radius: 2px;
			@include nb-map-label-chip-text;
		}

		.data,
		.data-value {
			text-align: center;
			padding: 3px 6px;
			border-radius: 2px;
			margin: 0 2px;
			height: 22px;
			padding-top: 4px;
		}

		.status {
			background: $nb-textcolor;
			color: $white !important;
		}

		.label-details {
			display: block;
			z-index: 9000;
			margin-top: 2px;
			margin-bottom: 2px;
			margin-left: -16px;
			margin-right: -16px;

			.row {
				display: block;

				.data-group {
					display: inline-block;
					width: 23%;
				}

				.data-label {
				}

				.data-value {
					@include nb-map-label-text;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;

					.emdash {
						text-align: center;
						background-color: $nb-neutral;
						color: $nb-textcolor;
						padding: 3px 6px;
						border-radius: 2px;
						margin-left: 100px !important;
						@include nb-map-label-chip-text;
					}
				}
			}

			.flexrow {
				@include nb-margin-bottom-xs;
				display: flex;
				justify-content: space-between;
				flex-direction: row;
				width: 100%;

				.data-label {
					flex: 1;
					@include nb-inline-label;
					font-size: 11px;
					margin: 0;
				}

				@mixin data-value {
					@include nb-map-label-text;
					flex: 1;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					margin-left: 15px;
					margin-right: 15px;
					padding-top: 4px;
					height: 22px;
				}

				.data-value {
					@include data-value;
				}

				.data-value-no-margin {
					@include data-value;
					margin-left: 0px;
					margin-right: 0px;
				}

				.data-value-no-right-margin {
					@include data-value;
					margin-right: 0px;
				}

				.passengers {
					position: relative;
					top: 4px;
				}
			}
			.emdash {
				margin-left: 30px !important;
				margin-right: 30px !important;
			}

			.label-route {
				width: 100%;
			}
		}

		.row2.flexrow {
			padding-left: 10px;
			padding-right: 10px;
		}

		.secondary {
			@include nb-label-meta-data;
		}

		.latlong {
			text-align: left;
			float: left;
		}

		.last-updated {
			text-align: right;
			float: right;
		}
	}
}
