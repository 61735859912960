$icomoon-font-path: "/assets/fonts";

$nb-add-solid: "\e900";
$nb-add: "\e901";
$nb-admintools: "\e902";
$nb-alert-solid: "\e903";
$nb-alert: "\e904";
$nb-arrival: "\e905";
$nb-backarrow: "\e906";
$nb-calendar: "\e907";
$nb-checkbox-selected: "\e908";
$nb-checkbox-unselected: "\e909";
$nb-clearfield: "\e90a";
$nb-clearmap: "\e90b";
$nb-close: "\e90c";
$nb-collapsepanel: "\e90d";
$nb-condenseview: "\e90e";
$nb-danger-solid: "\e90f";
$nb-danger: "\e910";
$nb-dashboard: "\e911";
$nb-delete: "\e912";
$nb-departure: "\e913";
$nb-dropdown: "\e914";
$nb-edit: "\e915";
$nb-expand: "\e916";
$nb-expandpanel: "\e917";
$nb-expandslider: "\e918";
$nb-expandview: "\e919";
$nb-favorite-solid: "\e91a";
$nb-favorite: "\e91b";
$nb-filter-down: "\e91c";
$nb-filter-up: "\e91d";
$nb-filtered: "\e91e";
$nb-first: "\e91f";
$nb-help-solid: "\e920";
$nb-help: "\e921";
$nb-hideslider: "\e922";
$nb-information-solid: "\e923";
$nb-information: "\e924";
$nb-junkdrawer: "\e925";
$nb-last: "\e926";
$nb-link: "\e927";
$nb-liveops: "\e928";
$nb-logout: "\e929";
$nb-menu: "\e92a";
$nb-messages-solid: "\e92b";
$nb-messages: "\e92c";
$nb-messaging: "\e92d";
$nb-next: "\e92e";
$nb-openmodal: "\e92f";
$nb-password: "\e930";
$nb-previous: "\e931";
$nb-print: "\e932";
$nb-radio-selected: "\e933";
$nb-radio-unselected: "\e934";
$nb-reassign: "\e935";
$nb-refresh: "\e936";
$nb-replay: "\e937";
$nb-reports: "\e938";
$nb-routes: "\e939";
$nb-schedule: "\e93a";
$nb-search: "\e93b";
$nb-settings: "\e93c";
$nb-showtrails: "\e93d";
$nb-stops: "\e93e";
$nb-success-solid: "\e93f";
$nb-success: "\e940";
$nb-timepoint: "\e941";
$nb-track: "\e942";
$nb-unfiltered: "\e943";
$nb-user: "\e944";
$nb-userprofile: "\e945";
$nb-vehicles: "\e946";
$nb-view-off: "\e947";
$nb-view-on: "\e948";
$nb-warning-solid: "\e949";
$nb-warning: "\e94a";
$nb-zoomin: "\e94c";
$nb-zoomout: "\e94b";
$nb-zoomtoobject: "\e94d";
$nb-next-stop: "\e94f";
$nb-rider-messages: "\e95e";
$nb-vehicle-messages: "\e95d";
$nb-solid-circle-x: "\e963";
$nb-deactivate: "\e964";
$nb-enable: "\e940";

//////////////////////////////////////////////////////////////////////
@font-face {
	font-family: "nb-icon-font";
	src: url("#{$icomoon-font-path}/nb-icon-font.eot?vw2bvp");
	src: url("#{$icomoon-font-path}/nb-icon-font.eot?vw2bvp#iefix")
			format("embedded-opentype"),
		url("#{$icomoon-font-path}/nb-icon-font.ttf?vw2bvp") format("truetype"),
		url("#{$icomoon-font-path}/nb-icon-font.woff?vw2bvp") format("woff"),
		url("#{$icomoon-font-path}/nb-icon-font.svg?vw2bvp#nb-icon-font")
			format("svg");
	font-weight: normal;
	font-style: normal;
}

.nb-icons {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "nb-icon-font" !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
//////////////////////////////////////////////////////////////////////////
.nb-add-solid {
	&:before {
		content: $nb-add-solid;
	}
}
.nb-add {
	&:before {
		content: $nb-add;
	}
}
.nb-admintools {
	&:before {
		content: $nb-admintools;
	}
}
.nb-alert-solid {
	&:before {
		content: $nb-alert-solid;
	}
}
.nb-alert {
	&:before {
		content: $nb-alert;
	}
}
.nb-arrival {
	&:before {
		content: $nb-arrival;
	}
}

.nb-solid-circle-x {
	&:before {
		content: $nb-solid-circle-x;
	}
}
.nb-backarrow {
	&:before {
		content: $nb-backarrow;
	}
}
.nb-calendar {
	&:before {
		content: $nb-calendar;
	}
}
.nb-checkbox-selected {
	&:before {
		content: $nb-checkbox-selected;
	}
}
.nb-checkbox-unselected {
	&:before {
		content: $nb-checkbox-unselected;
	}
}
.nb-clearfield {
	&:before {
		content: $nb-clearfield;
	}
}
.nb-clearmap {
	&:before {
		content: $nb-clearmap;
	}
}
.nb-close {
	&:before {
		content: $nb-close;
	}
}
.nb-collapsepanel {
	&:before {
		content: $nb-collapsepanel;
	}
}
.nb-condenseview {
	&:before {
		content: $nb-condenseview;
	}
}
.nb-danger-solid {
	&:before {
		content: $nb-danger-solid;
	}
}
.nb-danger {
	&:before {
		content: $nb-danger;
	}
}
.nb-dashboard {
	&:before {
		content: $nb-dashboard;
	}
}
.nb-delete {
	&:before {
		content: $nb-delete;
	}
}
.nb-departure {
	&:before {
		content: $nb-departure;
	}
}
.nb-dropdown {
	&:before {
		content: $nb-dropdown;
	}
}
.nb-edit {
	&:before {
		content: $nb-edit;
	}
}
.nb-expand {
	&:before {
		content: $nb-expand;
	}
}
.nb-expandpanel {
	&:before {
		content: $nb-expandpanel;
	}
}
.nb-expandslider {
	&:before {
		content: $nb-expandslider;
	}
}
.nb-expandview {
	&:before {
		content: $nb-expandview;
	}
}
.nb-favorite-solid {
	&:before {
		content: $nb-favorite-solid;
	}
}
.nb-favorite {
	&:before {
		content: $nb-favorite;
	}
}
.nb-filter-down {
	&:before {
		content: $nb-filter-down;
	}
}
.nb-filter-up {
	&:before {
		content: $nb-filter-up;
	}
}
.nb-filtered {
	&:before {
		content: $nb-filtered;
	}
}
.nb-first {
	&:before {
		content: $nb-first;
	}
}
.nb-help-solid {
	&:before {
		content: $nb-help-solid;
	}
}
.nb-help {
	&:before {
		content: $nb-help;
	}
}
.nb-hideslider {
	&:before {
		content: $nb-hideslider;
	}
}
.nb-information-solid {
	&:before {
		content: $nb-information-solid;
	}
}
.nb-information {
	&:before {
		content: $nb-information;
	}
}
.nb-junkdrawer {
	&:before {
		content: $nb-junkdrawer;
	}
}
.nb-last {
	&:before {
		content: $nb-last;
	}
}
.nb-link {
	&:before {
		content: $nb-link;
	}
}
.nb-liveops {
	&:before {
		content: $nb-liveops;
	}
}
.nb-logout {
	&:before {
		content: $nb-logout;
	}
}
.nb-menu {
	&:before {
		content: $nb-menu;
	}
}
.nb-messages-solid {
	&:before {
		content: $nb-messages-solid;
	}
}
.nb-messages {
	&:before {
		content: $nb-messages;
	}
}
.nb-messaging {
	&:before {
		content: $nb-messaging;
	}
}
.nb-next {
	&:before {
		content: $nb-next;
	}
}
.nb-openmodal {
	&:before {
		content: $nb-openmodal;
	}
}
.nb-password {
	&:before {
		content: $nb-password;
	}
}
.nb-previous {
	&:before {
		content: $nb-previous;
	}
}
.nb-print {
	&:before {
		content: $nb-print;
	}
}
.nb-radio-selected {
	&:before {
		content: $nb-radio-selected;
	}
}
.nb-radio-unselected {
	&:before {
		content: $nb-radio-unselected;
	}
}
.nb-reassign {
	&:before {
		content: $nb-reassign;
	}
}
.nb-refresh {
	&:before {
		content: $nb-refresh;
	}
}
.nb-replay {
	&:before {
		content: $nb-replay;
	}
}
.nb-reports {
	&:before {
		content: $nb-reports;
	}
}
.nb-routes {
	&:before {
		content: $nb-routes;
	}
}
.nb-schedule {
	&:before {
		content: $nb-schedule;
	}
}
.nb-search {
	&:before {
		content: $nb-search;
	}
}
.nb-settings {
	&:before {
		content: $nb-settings;
	}
}
.nb-showtrails {
	&:before {
		content: $nb-showtrails;
	}
}
.nb-stops {
	&:before {
		content: $nb-stops;
	}
}
.nb-success-solid {
	&:before {
		content: $nb-success-solid;
	}
}
.nb-success {
	&:before {
		content: $nb-success;
	}
}
.nb-timepoint {
	&:before {
		content: $nb-timepoint;
	}
}
.nb-track {
	&:before {
		content: $nb-track;
	}
}
.nb-unfiltered {
	&:before {
		content: $nb-unfiltered;
	}
}
.nb-user {
	&:before {
		content: $nb-user;
	}
}
.nb-userprofile {
	&:before {
		content: $nb-userprofile;
	}
}
.nb-vehicles {
	&:before {
		content: $nb-vehicles;
	}
}
.nb-view-off {
	&:before {
		content: $nb-view-off;
	}
}
.nb-next-stop {
	&:before {
		content: $nb-next-stop;
	}
}
.nb-view-on {
	&:before {
		content: $nb-view-on;
	}
}
.nb-warning-solid {
	&:before {
		content: $nb-warning-solid;
	}
}
.nb-warning {
	&:before {
		content: $nb-warning;
	}
}
.nb-zoomin {
	&:before {
		content: $nb-zoomin;
	}
}
.nb-zoomout {
	&:before {
		content: $nb-zoomout;
	}
}
.nb-zoomtoobject {
	&:before {
		content: $nb-zoomtoobject;
	}
}

.nb-download {
	&:before {
		content: $nb-arrival;
		display: inline-block;
		transform: rotate(90deg);
	}
}
.nb-upload {
	&:before {
		content: $nb-departure;
		display: inline-block;
		transform: rotate(-90deg);
	}
}

.nb-rider-messages {
	&:before {
		content: $nb-rider-messages;
	}
}

.nb-vehicle-messages {
	&:before {
		content: $nb-vehicle-messages;
	}
}

.nb-deactivate {
	&:before {
		content: $nb-deactivate;
	}
}

.nb-enable {
	&:before {
		content: $nb-enable;
	}
}

////////////////////////////////////////////////////////////////////////////
////Edits

.nb-download {
	font-size: 21px !important;
}
.nb-checkbox-selected,
.nb-checkbox-unselected,
.nb-radio-unselected,
.nb-radio-selected {
	font-size: 18px;
	color: $nb-accent !important;
}

.btn-primary {
	i {
		@include nb-padding-right-xs;
	}
	.nb-add {
		position: relative;
		top: 2px;
	}
}

.btn-action {
	.nb-edit {
		position: relative;
		top: 2px;
	}
	.nb-add {
		position: relative;
		top: 2px;
	}
	.nb-deactivate {
		position: relative;
		top: 2px;
	}
	.nb-enable {
		position: relative;
		top: 2px;
	}
}
.toolbar-group,
.toolbar-group:hover {
	.nb-add,
	.nb-edit,
	.nb-delete,
	.nb-expand,
	.nb-information,
	.nb-settings,
	.nb-deactivate,
	.nb-enable,
	.nb-success {
		font-size: 19px !important;
		position: relative;
		top: 1px;
	}
	.nb-clearfield {
		font-size: 15px;
		position: relative;
		top: 3px;
	}
}

.navbar-minimalize {
	.nb-menu {
		transition: 0.2s ease-in-out;
		font-size: 27px;
		position: relative;
		top: 2px;
		&:hover {
			color: $nb-accent !important;
		}
	}
}
.nb-zoomtoobject {
	font-size: 21px !important;
}

.nb-delete {
	font-size: 16px;
	position: relative;
	top: 2px;
}
.nb-information-solid,
.nb-information,
.nb-warning-solid,
.nb-warning,
.nb-danger-solid,
.nb-danger {
	font-size: 17px;
}

.nb-information-solid,
.nb-information {
	color: $nb-textcolor-light;
}
.nb-warning-solid,
.nb-warning {
	color: $bad;
}
.nb-danger-solid,
.nb-danger {
	color: $reallybad;
}

.nb-menu {
	color: $white;
	font-size: 25px;
	.btn-link-dark {
		color: $white !important;
	}
	.btn-link-dark:hover {
		color: $nb-accent;
	}
	.btn-link-dark:focus {
		color: $nb-accent;
	}
	.btn-link-dark:focus:hover {
		color: $nb-accent-dark;
	}
	.btn-link-dark:active {
		color: $nb-accent;
	}
	.btn-link-dark:active:hover {
		color: $nb-accent-dark;
	}
}
.nb-backarrow-1,
.nb-backarrow,
.nb-close {
	font-size: 16px;
	display: inline-block;
}
.nb-dropdown {
	padding-top: 2px !important;
	vertical-align: bottom !important;
	font-size: 16px;
	display: inline-block;
}
.nb-filtered,
.nb-unfiltered {
	font-size: 16px;
	display: inline-block;
}
.nb-unfiltered {
	color: $nb-textcolor-light;
}
.nb-refresh {
	font-size: 18px;
}
.nb-filtered {
	color: $nb-accent;
}
.nb-junkdrawer {
	font-size: 16px;
	vertical-align: middle;
}
.nb-view-on,
.nb-view-off {
	font-size: 21px !important;
	position: relative;
	top: 2.5px;
}

.nb-view-on-disabled,
.nb-view-off-disabled {
	color: #d1cece;
}

.nb-view-on-disabled,
.nb-view-off-disabled {
	cursor: not-allowed;
}

.ui-select-match {
	a {
		.nb-clearfield {
			color: $nb-textcolor !important;
			font-size: 22px !important;
			position: relative;
			top: 0px;
		}
	}
}
