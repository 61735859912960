// Selected Stop and Vehicle Icon
// Apply to the group of rings
.stop-icon-active {
	animation: growing-rings 1s infinite cubic-bezier(0.39, -0.03, 0.64, 0.96);
	-moz-transform-origin: 13px 13px;
	-ms-transform-origin: 13px 13px;
	-o-transform-origin: 13px 13px;
	-webkit-transform-origin: 13px 13px;
	transform-origin: 13px 13px;
	animation-direction: alternate-reverse;
	.glowrings {
		fill: #00a4ff;
	}
}
.vehicle-icon-active {
	animation: growing-rings 1s infinite cubic-bezier(0.39, -0.03, 0.64, 0.96);
	-moz-transform-origin: 9px 14px;
	-ms-transform-origin: 9px 14px;
	-o-transform-origin: 9px 14px;
	-webkit-transform-origin: 9px 14px;
	transform-origin: 9px 14px;
	animation-direction: alternate-reverse;
}

@keyframes growing-rings {
	from {
		box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.5);
		border-radius: 50%;
	}
	to {
		box-shadow: 0px 0px 8px 8px rgba(0, 84, 139, 0.5);
		border-radius: 50%;
	}
}

.glowrings-darkbg {
	fill: $nb-neutral-light;
}
.glowrings-lightbg {
	fill: $nb-textcolor;
}

//////////////////////////////////////////
// Live Ops Icons ///////////////////////
.liveops-nav {
	height: 85px;
}

.liveops-nav:hover {
	.liveops_bg-shadow-outer {
		opacity: 0.35;
		transition: 0.2s ease-in-out;
	}

	.liveops_bg-shadow-inner {
		opacity: 0;
		transition: 0.2s ease-in-out;
	}

	.liveops_icon-shadow {
		opacity: 0.3;
		transition: 0.2s ease-in-out;
	}

	.liveops_icons {
		transform: translatey(-2px);
		transition: 0.2s ease-in-out;
	}
}

.liveops_bg-shadow-outer {
	fill: $nb-textcolor;
	opacity: 0;
}
.liveops_bg {
	fill: $nb-accent;
}

.liveops_bg-shadow-inner {
	fill: $nb-textcolor;
	opacity: 0.2;
}

.liveops_icon-shadow {
	fill: $nb-textcolor;
	opacity: 0.3;
}

.liveops_icons {
	fill: $white;
}
.liveops_icon_container {
	width: 70px;
	display: inline-block;
}

//////////////////////////////////////////
// Loading container ///////////////////////

.nb-loading-container {
	position: absolute;
	top: 50%;
	left: 50%;
	padding: 15px;
	-ms-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: -1;
	text-align: center;
	.nb-loading-text {
		font-family: $nb-font;
		color: $nb-accent;
		font-size: 16px;
		letter-spacing: 0.3px;
	}
}

/*original loading spinner css before new component was introduced to repalce old angularjs loading service*/
.nb-loading-overlay-container-old {
	position: absolute;
	width: 100%;
	top: 50%;
	left: 50%;
	-ms-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-align: center;
	.nb-loading-text {
		font-family: $nb-font;
		color: $nb-accent;
		font-size: 16px;
		letter-spacing: 0.3px;
	}
}

.nb-loading-overlay-container {
	position: fixed;
	width: 100%;
	top: 50%;
	left: calc(
		50% + 70px
	); /* allow for the navigation bar (half of it's size)*/
	-ms-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-align: center;
	.nb-loading-text {
		font-family: $nb-font;
		color: $nb-accent;
		font-size: 16px;
		letter-spacing: 0.3px;
	}
}

.nb-loading-overlay-container-fit-content {
	position: relative !important;
	left: 50% !important;
}

.nb-spinner {
	margin: 0 auto 0;
	width: 70px;
	text-align: center;
}

.nb-spinner > div {
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.23);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.23);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.23);
	width: 18px;
	height: 18px;
	background-color: $nb-accent;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.nb-spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.nb-spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
