@import "../dashboard-main/dashboard-main.component.scss";

.dashboard-toolbar {
    padding-bottom: 8px;
    position: relative;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    background-color: #fff;
    border-top: 1px solid #DBDBDB;
    border-bottom: 1px solid #DBDBDB;
    text-align: right;
}

.dashboard-toolbar-btn {
    padding-top: 2px;
}

.ng-delete {
    color: #00D9AC;
    font-size: 19px !important;
    top: 2px;
    margin-right: 4px;
}

.ng-delete::before {
    content: "\e912";
}

.ng-settings {
    color: #00D9AC;
    font-size: 19px !important;
    top: 2px;
    margin-right: 4px;
}

.ng-settings::before {
    content: "\e93c";
}
