.nb-messages-control {
	@include nb-padding-all-sm;
	.nb-textarea {
		resize: none;
		width: 100%;
		height: fit-content;
		min-height: 65px;
	}

	.nb-character-count {
		position: relative;
		font-size: 11px;
		float: right;
		color: #888a8e;
		top: -23px;
		right: 10px;
		height: 0px;
		color: $nb-textcolor-lighter;
	}

	.nb-route-list {
		background-color: $nb-neutral-light;
		margin-top: 4px;
		color: $nb-textcolor;
		padding: 3px;
		padding-left: 6px;
		font-size: 13px;
	}

	.nb-route-list-remove {
		text-align: right;
		padding-right: 12px;
		height: 30px;
	}

	.nb-route-list-remove-item {
		padding: 0px;

		font-size: 15px;
		font-weight: bold;
		text-align: right;
	}

	.nb-remove-timerow {
		font-size: 15px;
		font-weight: bold;
	}

	.nb-remove-timerow-container {
		padding-left: 5px;
		padding-right: 8px;
		padding-top: 6px;
		padding-bottom: 0px;
	}

	.nb-route-selector {
		margin-bottom: 4px;
	}

	.nb-edit-heading-header {
		height: 40px;
		background: $white !important;
	}

	.nb-stops-count {
		color: $nb-textcolor-lighter;
	}

	.nb-time-delete {
		display: inline-flex;
	}

	.nb-time-separator-text {
		padding-left: 16px;
		padding-top: 6px;
	}

	.nb-dropdown-padding-left {
		padding-left: 10px !important;
	}

	.nb-dropdown-padding-right {
		padding-right: 10px !important;
	}

	.nb-route-dropdown-padding {
		padding-left: 30px !important;
	}
}
