/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

// Charts
// Vehicle Events
// Schedule Performance
// Performance
// Weather
// Traffic
// Data Ingestion
// Upcoming Blocks
// Block Assignment
// Prediction Accuracy

@mixin shadow {
	-webkit-box-shadow: 0 0 11px -1px rgba(0, 0, 0, 5);
	box-shadow: 0 0 11px -1px rgba(0, 0, 0, 5);
}

.shadow {
	@include shadow;
}

.nb-widget-container {
	.leaflet-control-zoom {
		display: block;
	}

	.no-data {
		margin-top: 10px;
		text-align: center;
	}
}

.widget-content-area {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: nowrap;
	align-items: flex-start;
}
.widget-timestamp {
	width: auto;
	@include nb-numerals;
	text-align: right;
}
.nb-inline-list {
	display: flex;
	flex-wrap: wrap;
	line-height: 25px;
}

////////////////////////////
//Charts

//Ex: 0 - 17, 12:33 - 12:38
//Add .bold to the first and last
.nb-chart-axis-text {
	@include nb-text;
}
//Ex: reports ingested
.nb-chart-axis-labels {
	@include nb-section-title;
	color: $nb-textcolor-light;
	font-weight: 600;
}
//Text for the legends, inside the table and hover states
.nb-chart-legend-text {
	@include nb-text;
}

//View more button
@mixin view-more {
	a {
		@include nb-text;
		color: $nb-accent;
	}
	a:hover,
	a:active {
		@include nb-text;
		color: $nb-accent-dark;
	}
}

.view-more {
	@include view-more;
}

.widget-content-area {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: nowrap;
	align-items: flex-start;
}
.widget-timestamp {
	width: auto;
	@include nb-numerals;
	text-align: right;
	flex: auto;
	flex-shrink: 1;
}
.nb-inline-list {
	flex-shrink: 1;
	display: flex;
	flex-wrap: wrap;
}

////////////////////////////
// Vehicle Events

////////////////////////////
// Schedule Performance
.nb-dashboard-schedule-performance-container {
	min-height: 600px;
}
.nb-dashboard-schedule-performance-summary-container {
}
.nb-dashboard-schedule-performance-container-list-only {
	min-height: 470px;
}
.nb-dashboard-schedule-performance-container-chart-only {
	min-height: 270px;
}
.schedule-performance-chart {
	text-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	position: relative;
	right: 10px;
}
.schedule-performance-table {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	.map-details-static-table {
		td:last-of-type {
			border-bottom: 0px !important;
		}
	}
}
.schedule-performance-nodata {
	width: 100%;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	text-align: center;
	@include nb-text;
}

.schedule-performance-footer {
	@include nb-margin-top-md;
	min-height: 45px;
	width: 100%;

	.update-text {
		width: 100%;
		text-align: center;
	}
}

.deadheading-footer {
	@include nb-margin-top-md;
	min-height: 45px;
	width: 100%;

	.update-text {
		width: 100%;
		text-align: center;
	}
}

////////////////////////////
// Occupancy
.occupancy-title {
	font-weight: bold;
}
.occupancy-title-chart {
	height: 10%;
	width: 20%;
}
.occupancy-title-table {
	height: 10%;
	width: 80%;
}
.occupancy-chart {
	width: 20%;
}
.occupancy-table {
	width: 80%;
}
.occupancy-table {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 2;
	.map-details-static-table {
		td:last-of-type {
			border-bottom: 0px !important;
		}
	}
}
.occupancy-table-tooltip {
	.tooltip-inner {
		width: auto;
	}
}

////////////////////////////
// Performance
//Edit
.performance-viewsettings {
	.nb-section-subtitle {
		@include nb-margin-bottom-sm;
	}
}
.performance-adherencecol {
	@media (min-width: 500px) {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: space-between;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.nb-form-control {
		@include nb-margin-right-lg;
	}
}
.performance-predictionscol {
	display: flex;
	flex-direction: column;
}

////////////////////////////
// Weather
.weather-widget {
	img {
		width: 100%;
	}
	.weather-alerts {
	}
	.weather-map {
	}
	.weather-details {
		text-align: center;
		.weather-detail-item {
			display: inline-block;
			text-align: center;
			@include nb-margin-right-lg;
			@include nb-margin-bottom-md;
		}
	}
	.weather-footer {
		@include nb-margin-top-md;
		@include nb-padding-top-md;
		min-height: 60px;
		width: 100%;
		border-top: $nb-border;

		.update-text {
			width: 100%;
			text-align: center;
		}
	}
	.weather-noinfo {
		width: 100%;
	}
}

.north-icon,
.north-northeast-icon,
.northeast-icon,
.east-northeast-icon,
.east-icon,
.east-southeast-icon,
.southeast-icon,
.south-southeast-icon,
.south-icon,
.south-southwest-icon,
.southwest-icon,
.west-southwest-icon,
.west-icon,
.west-northwest-icon .northwest-icon,
.north-northwest-icon {
	transform-origin: center;
	display: inline-block;
}

.north-icon {
	transform: rotate(-90deg);
}
.north-northeast-icon {
	transform: rotate(20deg);
}
.northeast-icon {
	transform: rotate(-45deg);
}
.east-northeast-icon {
	transform: rotate(-22.5deg);
}
.east-icon {
	transform: rotate(0deg);
}
.east-southeast-icon {
	transform: rotate(22.5deg);
}
.southeast-icon {
	transform: rotate(45deg);
}
.south-southeast-icon {
	transform: rotate(67.5deg);
}
.south-icon {
	transform: rotate(90deg);
}
.south-southwest-icon {
	transform: rotate(112.5deg);
}
.southwest-icon {
	transform: rotate(140deg);
}
.west-southwest-icon {
	transform: rotate(157.5deg);
}
.west-icon {
	transform: rotate(180deg);
}
.west-northwest-icon {
	transform: rotate(-157.5deg);
}
.northwest-icon {
	transform: rotate(-140deg);
}
.north-northwest-icon {
	transform: rotate(-112.5deg);
}

////////////////////////////
// Traffic

.traffic-footer {
	@include nb-margin-top-md;
	width: 100%;
	.update-text {
		text-align: center;
	}
}

.nb-vertical-flip {
	-moz-transform: scale(1, -1);
	-webkit-transform: scale(1, -1);
	-o-transform: scale(1, -1);
	-ms-transform: scale(1, -1);
	transform: scale(1, -1);
}

////////////////////////////
// Upcoming Blocks

@mixin up-blocks-info-row {
	display: flex;
	padding: 6px;
}

.up-blocks-info-row {
	@include up-blocks-info-row;
}

.up-blocks-row {
	@include up-blocks-info-row;
	border-top: 1px solid $nb-neutral-dark;
}

.up-blocks-col {
	display: flex;
	flex: 1;
}

.up-blocks-status {
	display: flex;
	min-width: 75px;
	max-width: 80px;
	margin-right: 3px;
}

.up-blocks-trip-cancel {
	display: flex;
	min-width: 25px;
	max-width: 25px;
}

.blocks-unassigned {
	background: $reallybad-caution;
	border-left: 4px solid $reallybad;
}
.blocks-assigned {
	background: $acceptable;
	border-left: 4px solid $acceptable-dark;
}

.routes-all-assigned {
	background: $acceptable;
	border-left: 4px solid $acceptable-dark;
}

.routes-some-assigned {
	background: #ffd41a;
	border-left: 4px solid #ffd41a;
}

.routes-none-assigned {
	background: red;
	border-left: 4px solid red;
}

.nb-dashboard-upcoming-blocks-tooltip {
	position: relative;
	display: inline-block;
}

.nb-dashboard-upcoming-blocks-tooltip .tooltiptext {
	visibility: hidden;
	@include shadow;
	width: 175px;
	height: 150px;
	background-color: #fff;

	border-radius: 4px;
	padding: 8px;
	position: absolute;
	z-index: 5 !important;
	top: 5%;
	left: -125px;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
}

.nb-dashboard-upcoming-blocks-tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}

.tooltip i.right-arrow {
	position: relative;
	top: 8px;
	right: 100%;
	color: #fff;
	text-shadow: 2px 0 3px rgba(0, 0, 0, 0.5);
	font-size: 24px;
	border: 1px #e0c dotted;
	display: block;
}

.nb-dashboard-upcoming-blocks-container {
	text-align: center;
	position: relative;
	overflow: hidden;
	margin-left: 0;
	margin-right: 0;
	min-height: 150px;
}

.nb-dashboard-upcoming-blocks-routes-tool-table {
	thead th {
		width: 25%;
		font-weight: normal;
		white-space: nowrap;
		text-align: left;
		@include nb-text;
		@include nb-padding-left-md;
		@include nb-padding-right-md;
		@include nb-padding-top-md;
		@include nb-padding-bottom-sm;
	}
	tbody {
		overflow: auto;
		width: 100%;
	}

	tbody tr td {
		text-align: left;
		white-space: nowrap;
		width: 25%;
		@include nb-text;
		@include nb-padding-left-md;
		@include nb-padding-right-md;
		@include nb-padding-top-md;
		@include nb-padding-bottom-sm;
	}

	thead {
		overflow: auto;
		width: 100%;
	}
}

.nb-dashboard-upcoming-blocks-table {
	width: 100%;
}

.nb-dashboard-upcoming-blocks-table-col-light {
	color: $white;
}

.nb-dashboard-upcoming-blocks-table-col-dark {
	background-color: $nb-neutral-light;
}

.nb-dashboard-upcoming-blocks-route-label {
	border-radius: 0.2em;
	line-height: 1;
	font-weight: 600;
	text-align: center;
	vertical-align: baseline;
	padding: 0.2em 0.6em 0.2em;
	font-size: 12px;
	cursor: pointer;
	min-width: 64px;
	max-width: 64px;
}

.nb-dashboard-upcoming-blocks-text-container {
	display: flex;
	position: absolute;
	top: 0;
	width: 100%;
	padding-left: 2px;
}

.nb-dashboard-upcoming-blocks-text {
	color: $white;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.6px;
	padding-left: 4px;
}

.nb-dashboard-upcoming-blocks-bar {
	width: 100%;
	cursor: default;
	border-radius: $nb-border-radius;
	top: 4%;
}

.nb-dashboard-upcoming-blocks-routes-bar {
	width: 100%;
	cursor: default;
	border-radius: $nb-border-radius;
	top: -10px;
}

td.nb-dashboard-upcoming-blocks-table-value {
	padding: 0;
	background-color: transparent;
}
td.nb-dashboard-upcoming-blocks-table-label {
	padding: 4px 6px;
	background-color: $white;
}

.nb-dashboard-upcoming-blocks-table-text-container {
	position: relative;
	text-align: left;
	padding-left: 3px;
	cursor: default;
	white-space: nowrap;
}

////////////////////////////
// Block Assignment

////////////////////////////
// Prediction Accuracy

.nb-dashboard-prediction-accuracy-container {
	min-height: 600px;
}

.nb-dashboard-prediction-accuracy-container-list-only {
	min-height: 470px;
}

.nb-dashboard-prediction-accuracy-container-chart-only {
	min-height: 270px;
}
.nb-dashboard-prediction-accuracy-summary-container {
	position: relative;
	top: -48px;
}

.nb-dashboard-row-container {
	.divrow {
		&:hover {
			background-color: #f1f5fb;
		}
	}
}
.prediction-accuracy-container {
	@media (max-width: 400px) {
		margin: 0 -15px;
	}
	@media (max-width: 300px) {
		transform: scale(0.9);
	}
	@media (max-width: 280px) {
		transform: scale(0.8);
	}
	@media (max-width: 260px) {
		transform: scale(0.7);
	}
}
.prediction-accuracy-chart {
	@media (max-width: 400px) {
		margin: 0 -60px;
	}
	max-height: 250px;
	text-align: center;
}
.prediction-accuracy-table {
	@media (max-width: 400px) {
		margin: 0 -15px;
	}
}
.prediction-accuracy-view-more {
	width: 100%;
	@include view-more;
}
.prediction-accuracy-nodata {
	@include no-data;
}

.nb-widget-pill {
	@include label-route-hr;
}

.nb-widget-agency-select-error {
	margin-top: 8px;
}
