/*
<!--Material Icons-->
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
manually including the contents of the material icon font css below;
*/

/* fallback */
@font-face {
	font-family: "Material Icons";
	font-style: normal;
	font-weight: 400;
	src: url("#{$icomoon-font-path}/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2")
		format("woff2");
}

.material-icons {
	font-family: "Material Icons";
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: "liga";
	-webkit-font-smoothing: antialiased;
}

.material-icons-events {
	font-family: "Material Icons";
	font-weight: normal;
	font-style: normal;
	font-size: 24px;

	letter-spacing: normal;
	text-transform: none;

	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	display: block;

	position: relative;
	top: 50%;
	left: 50%;

	transform: translate(-50%, -50%);
	-webkit-font-feature-settings: "liga";
	-webkit-font-smoothing: antialiased;
}
