// Stop Icon

// Normal State for the STOP ICON
.nb-stop-icon-shadow {
	box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.5);
	border-radius: 50%;
}

.nb-stop-icon-stops-top {
	z-index: 3000 !important;
}

.nb-stop-icon-stops-bottom {
	z-index: 1000 !important;
}

// Active State for the STOP ICON
.nb-stop-icon-shadow-active {
	// box-shadow: 0px 0px 16px 16px rgba(48,172,255,0.5);
	animation-name: stop-active-glow;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	border-radius: 50%;
	animation-direction: alternate-reverse;
}

@keyframes stop-active-glow {
	from {
		box-shadow: 0px 0px 6px 6px rgba(48, 172, 255, 0.5);
	}
	to {
		box-shadow: 0px 0px 20px 20px rgba(48, 172, 255, 0.5);
	}
}

// Vehicle Icon

// Normal State for the VEHICLE ICON
.nb-vehicle-icon-shadow {
	box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.5);
	// Need a fixed height for IE to display properly
	height: 36px;
}

// Active State for the VEHICLE ICON
.nb-vehicle-icon-shadow-active {
	// Need a fixed height for IE to display properly
	height: 36px;
	animation-name: vehicle-active-glow;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
}

@keyframes vehicle-active-glow {
	from {
		box-shadow: 0px 0px 6px 6px rgba(48, 172, 255, 0.5);
	}
	to {
		box-shadow: 0px 0px 20px 20px rgba(48, 172, 255, 0.5);
	}
}

.scaling-svg {
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
}

.label-cluster {
	z-index: 7000 !important;
}

@mixin label-route {
	width: 64px;
	display: block;
	padding: 4px 8px 4px 8px;
	border-radius: 0.25em;
	font-size: 12px;
	line-height: 12px;
	font-weight: bold;
	text-align: center;
	overflow: hidden;
	text-overflow: ellipsis;
}

.label-route {
	@include label-route;
}

.label-route-ladder {
	width: 40px;
	font-family: $nb-font;
	font-size: 9px;
	font-weight: 500;
	letter-spacing: 0.2px;
	text-align: center;
	display: block;
	padding: 4px 2px 2px 2px;
	color: $nb-textcolor;
}

@mixin label-route-hr {
	@include label-route;
	display: inline-block;
	text-overflow: ellipsis;
}

.label-route-hr {
	@include label-route-hr;
}
