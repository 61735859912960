.nb-dataview-group {
	.nb-dataview-group-title {
		@include nb-section-title;
		@include nb-padding-all-md;
		@include nb-padding-bottom-0;
	}
	background-color: white;
}

.nb-dataview-title {
	@include nb-subhead;
	@include nb-padding-all-md;
	background-color: #fff;
}

.nb-dataview {
	min-height: 30px;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;
	user-select: none;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;
	padding-right: 1px;

	.nb-dataview-row {
		padding: 8px;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: stretch;
		align-content: stretch;
		.nb-dataview-inner {
			flex: 1;
			display: flex;
			min-width: 0;
			word-break: break-all;
			min-height: 30px;
			cursor: pointer;
			flex-direction: row;
			flex-wrap: nowrap;

			.nb-dataview-cell {
				box-sizing: border-box;
				flex: 1;
				min-width: 80px;
				.nb-dataview-cell-contents {
				}
			}
			.nb-dataview-selector {
				width: 30px;
				box-sizing: border-box;
				padding: 8px 0px 5px 5px;
				font-family: fontawesome;
				user-select: none;
				overflow: hidden;
				border-right: 1px solid #fff;
				border-bottom: 1px solid #fafafa;

				&:hover,
				&:focus {
					background-color: $nb-primary-lighter;
				}
			}

			.nb-dataview-selector:before {
				box-sizing: border-box;
				display: inline-block;
				font-family: fontawesome;
				font-size: 14px;
				font-style: normal;
				font-weight: normal;
				speak: none;
				text-decoration: inherit;
				width: 1em;
				margin-right: 0.2em;
				text-align: center;
				font-variant: normal;
				text-transform: none;
				line-height: 1em;
				margin-left: 0.2em;
			}

			.nb-dataview-selector:before {
				content: url(/assets/img/nb-checkboxes/nb-checkbox.svg) !important;
			}
		}
	}

	.nb-dataview-row {
		.nb-dataview-inner {
			background-color: $white;
		}
	}

	.nb-dataview-row.selected {
		.nb-dataview-inner {
			background-color: $nb-primary-lighter;

			.nb-dataview-cell {
				@include nb-padding-all-sm;
				&:hover {
					background-color: $nb-primary-lighter;
				}
			}

			.nb-dataview-selector {
				&:hover,
				&:focus {
					background-color: $nb-primary-lighter;
				}
			}

			.nb-dataview-selector:before {
				content: url(/assets/img/nb-checkboxes/nb-checkbox-checked.svg) !important;
			}
		}
	}
}
