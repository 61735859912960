// -----------------------------------------------------
// i-check overrides
.icheckbox_square-green,
.iradio_square-green {
	display: inline-block;
	vertical-align: middle;
	margin: 0;
	padding: 0;
	width: 18px;
	height: 18px;
	background: url("/assets/img/nb-blue_ichecks_18.png") no-repeat;
	border: none;
	cursor: pointer;
}

.icheckbox_square-green {
	background-position: 0 0;
}
.icheckbox_square-green.hover {
	background-position: -20px 0;
}
.icheckbox_square-green.checked {
	background-position: -40px 0;
}
.icheckbox_square-green.disabled {
	background-position: -60px 0;
	cursor: default;
}
.icheckbox_square-green.checked.disabled {
	background-position: -80px 0;
}

.iradio_square-green {
	background-position: -100px 0;
}
.iradio_square-green.hover {
	background-position: -120px 0;
}
.iradio_square-green.checked {
	background-position: -140px 0;
}
.iradio_square-green.disabled {
	background-position: -160px 0;
	cursor: default;
}
.iradio_square-green.checked.disabled {
	background-position: -180px 0;
}
