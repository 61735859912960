// padding variables;

$padding-xs: 4px;
$padding-sm: $padding-xs * 2;
$padding-md: $padding-xs * 4;
$padding-lg: $padding-xs * 6;
$padding-xl: $padding-xs * 8;

.nb-padding-0 {
	padding-top: 0;
	padding-right: 0;
	padding-bottom: 0;
	padding-left: 0;
}

@mixin nb-padding-top-0 {
	padding-top: 0;
}
@mixin nb-padding-right-0 {
	padding-right: 0;
}
@mixin nb-padding-bottom-0 {
	padding-bottom: 0;
}
@mixin nb-padding-left-0 {
	padding-left: 0;
}

.nb-padding-top-0 {
	@include nb-padding-top-0;
}
.nb-padding-right-0 {
	@include nb-padding-right-0;
}
.nb-padding-bottom-0 {
	@include nb-padding-bottom-0;
}
.nb-padding-left-0 {
	@include nb-padding-left-0;
}
