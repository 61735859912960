/*
css general to modals
*/

.modal {
	.modal-dialog {
		.modal-content {
			display: flex;
			flex-direction: column;

			.modal-header {
				padding: 15px;
				border-bottom: $nb-border;
			}

			.modal-body {
				flex-direction: column;
				padding: 0px;

				.row {
					margin: 0px;
				}

				h2 {
					padding-left: $padding-md;
					padding-right: $padding-md;
				}
			}

			.modal-footer {
			}
		}

		.nb-form-footer {
			text-align: center;
		}

		.modal-dialog-tabs .modal-content .modal-body {
			top: 89px;
		}

		.modal-dialog-tabs .nb-sub-page-title {
			margin-top: 15px;
		}
	}

	.modal-inner {
		height: 100%;
		width: 70%;
		margin: auto;
	}

	.modal-component {
		height: 100%;
		width: 100%;
	}

	.modal-dialog-fullWidth {
		width: 100%;
		margin: 0px auto;

		padding-left: 30px;
		padding-right: 30px;
	}

	.modal-dialog-fullHeight {
		height: 100%;
		margin: 0px auto;

		padding-top: 60px;
		padding-bottom: 30px;
	}

	.modal-dialog-fullHeight .modal-content {
		height: 100%;
	}

	.modal-dialog-fullHeight .modal-content .modal-body {
		overflow: auto;
	}

	.modal-dialog-fullHeight .modal-content .modal-footer {
	}
}

/*
css specific to the modal-component
*/

.modal-component {
	.modal-content {
		.modal-header {
		}
		.modal-body {
			// cant use just 1, IE11 wasn't sizing properly
			flex: 1 1 auto;
		}
		.modal-footer {
		}
	}
}
