//Depot Labels

.depot-container {
	min-width: 280px;
	height: 325px;
	max-height: 325px;
	width: max-content;
	display: flex;
	flex-direction: column;
	background-color: $white;
	@include nb-shadow;

	.depot-row {
		display: inline-block;
		flex-direction: row;
		@include nb-title-sm;
		@include nb-padding-left-xs;
		@include nb-padding-right-xs;
		@include nb-padding-top-xs;

		border-bottom: $nb-border;
	}
	.depot-vehicle-label {
		white-space: nowrap;
		display: inline-block;
		@include nb-text;
		@include nb-padding-right-md;
		overflow: hidden;
	}
	.depot-vehicles-viewport {
		display: flex;
		height: 100%;
		max-width: 285px;
		overflow-x: auto;
		overflow-y: hidden;
		@include nb-padding-all-xs;
	}

	.depot-id-label {
		flex: 0 0 1;
		display: inline-block;
		background-color: #fff;
		overflow: hidden;
	}

	.depot-vehicles-ids {
		flex-flow: column wrap;
		display: flex;
		width: 30%;
	}
}
