.nb-tabset {
	background-color: $white;

	.nav-tabs {
		background-color: $white !important;
		@include nb-tab-labels;
		border-radius: 0 !important;

		.uib-tab {
			border-bottom: 4px solid $white !important;

			a:hover {
				background-color: $white;
			}
		}

		.uib-tab:hover {
			border-bottom: 4px solid $nb-neutral-dark !important;
		}

		.active,
		.active:hover {
			border-bottom: 4px solid $nb-accent !important;
		}

		.uib-tab .disabled {
		}
	}
}

.nb-tabset-small {
	.nav-tabs {
		font-size: 10px !important;
	}
}
