// Sub pages

.nb-sub-page-title {
	font-family: $nb-font;
	text-align: center;
	font-size: 16px;
	font-weight: normal;
	margin-top: 23px;
}

.nb-sub-page-title-link {
	@include all-caps;
	font-family: $nb-font;
	color: $nb-textcolor-light;
	font-weight: 500;
	font-size: 15px;
	padding-bottom: 10px;
}

.nb-sub-page-title-link-active {
	@include nb-tab-labels;
	border-bottom: 3px solid $nb-accent;
	padding-bottom: 10px;
	text-align: center;
}

.nb-sub-page-wrapper {
	background-color: #f3f3f4;
	padding-bottom: 4px;
}

.nb-sub-page-content {
	background-color: #ffffff;
	// box-shadow: 1px 1px 1px 1px #888888;
}

.nb-sub-element-title-link {
	@include nb-tab-labels;
	padding-bottom: 10px;
	text-align: center;
}

.nb-sub-element-title-link-active {
	color: $nb-textcolor-light;
	border-bottom: 3px solid $nb-accent;
	padding-bottom: 10px;
}
