.nb-user-view-default-icon {
	font-size: 32px;
	padding: 15px 20px;
	color: grey;
	display: inline-block;
	-moz-border-radius: 100px;
	-webkit-border-radius: 100px;
	border-radius: 100px;
	@include nb-shadow;
}

.nb-user-view-real-name-container {
	margin-top: 20px;
}

.nb-user-view-real-name-text {
	font-size: 16px;
}

.nb-user-view-email-container {
	margin-top: 20px;
}

.nb-user-view-email-text {
	font-size: 16px;
}

.nb-user-view-phone-container {
	margin-top: 20px;
}

.nb-user-view-phone-text {
	font-size: 16px;
}

.nb-user-view-role-agency {
	font-size: 16px;
}
