// Live Mode

.nb-map-live-mode {
	background: #fff;
	position: absolute;
	left: 190px;
	top: 450px;
	width: 120px;
	height: 50px;
	margin: 0;
	border: 1px solid grey;
	box-shadow: 1px 1px grey;
	border-radius: 2px 2px 2px 2px;
	pointer-events: auto;
	transition-duration: 0.3s;
}

.nb-map-live-mode.show {
	top: 430px;
	transition-duration: 0.3s;
}

.nb-onoffswitch-label {
	border: 2px solid $nb-accent;
}

.nb-replayindicator-text {
	font-family: $nb-font;
	letter-spacing: 2px;
	text-transform: uppercase;
	font-size: 13px;
	position: relative;
	top: 2px;
}
.nb-replay-mode-control-container {
	border-right: $nb-border;
	height: 100%;
}

.nb-replay-mode-control-switch {
	margin-top: 21px;
	margin-left: 10px;
}

.nb-replay-play-control-container {
	margin-top: 18px;
	height: 100%;
}

.nb-replay-play-control {
	color: $nb-textcolor;
	text-decoration: none;
}

.nb-replay-play-control-disabled,
.nb-replay-play-control-disabled:hover {
	@include nb-text;
	opacity: 0.6;
	cursor: not-allowed;
	text-decoration: none;
}

.nb-replay-position-control-bar {
	height: 8px;
	background-color: #ffffff;
	cursor: pointer;
}

.nb-replay-position-control-bar-disabled {
	height: 8px;
	background-color: #ffffff;
	cursor: not-allowed;
}

.nb-replay-position-control-progress-played {
	background-color: $nb-primary-light;
	-webkit-transition: none !important;
	transition: none !important;
}

.nb-replay-position-control-progress-buffer {
	background-color: $nb-primary-lighter;
	-webkit-transition: none !important;
	transition: none !important;
}

.nb-replay-position-control-progress-left {
	background-color: $nb-neutral;
	-webkit-transition: none !important;
	transition: none !important;
}

.nb-replay-position-marker {
	color: $nb-primary;
	position: absolute;
	top: -3px;
}

.nb-replay-position-start-label {
	position: absolute;
	top: 10px;
	left: 0%;
}

.nb-replay-position-end-label {
	position: absolute;
	top: 10px;
	left: 85%;
}

.nb-replay-position-control-label {
	position: absolute;
	top: 10px;
	left: 36%;
}

.nb-replay-position-msg-label-container {
	font-family: $nb-font;
	position: absolute;
	top: -22px;
	left: 27px;
	width: 343px;
	text-align: center;
}

.nb-replay-calendar-control-container {
	margin-top: 16px;
	height: 100%;
	padding-left: 0;
	padding-right: 0;
}

.nb-replay-speed-control-container {
	li {
		a {
			@include nb-text;
		}
	}
	margin-top: 16px;
	height: 100%;
	padding-left: 0;
	padding-right: 0;
}

.nb-replay-actions-control-container {
	margin-top: 16px;
	height: 100%;
	padding-left: 0;
	padding-right: 0;
}

.nb-replay-settings-control-container {
	margin-top: 16px;
	height: 100%;
	padding-left: 0;
	padding-right: 0;
}

.nb-replay-mode-control-container {
	border-right: $nb-border;
	height: 100%;
}

.nb-replay-mode-control-switch {
	margin-top: 21px;
	margin-left: 10px;
}

.nb-replay-play-control-container {
	margin-top: 18px;
	height: 100%;
}

.nb-replay-play-control {
	color: $nb-textcolor;
	text-decoration: none;
}

.nb-replay-play-control-disabled,
.nb-replay-play-control-disabled:hover {
	@include nb-text;
	opacity: 0.6;
	cursor: not-allowed;
	text-decoration: none;
}

.nb-replay-position-control-container {
	margin-top: 26px;
	height: 100%;
	padding-left: 0px;
	padding-right: 10px;
}

.nb-replay-position-control-bar {
	height: 8px;
	background-color: #ffffff;
	cursor: pointer;
}

.nb-replay-position-control-bar-disabled {
	height: 8px;
	background-color: #ffffff;
	cursor: not-allowed;
}

.nb-replay-position-control-progress-played {
	background-color: $nb-primary-light;
	-webkit-transition: none !important;
	transition: none !important;
}

.nb-replay-position-control-progress-buffer {
	background-color: $nb-primary-lighter;
	-webkit-transition: none !important;
	transition: none !important;
}

.nb-replay-position-control-progress-left {
	background-color: $nb-neutral;
	-webkit-transition: none !important;
	transition: none !important;
}

.nb-replay-position-marker {
	color: $nb-primary;
	position: absolute;
	top: -3px;
}

.nb-replay-position-start-label {
	position: absolute;
	top: 10px;
	left: 0%;
}

.nb-replay-position-end-label {
	position: absolute;
	top: 10px;
	left: 85%;
}

.nb-replay-position-control-label {
	position: absolute;
	top: 10px;
	left: 36%;
}

.nb-replay-speed-control-container {
	li {
		a {
			@include nb-text;
		}
	}
	margin-top: 16px;
	height: 100%;
	padding-left: 0;
	padding-right: 0;
}

.nb-replay-actions-control-container {
	margin-top: 16px;
	height: 100%;
	padding-left: 0;
	padding-right: 0;
}

.nb-replay-settings-control-container {
	margin-top: 16px;
	height: 100%;
	padding-left: 0;
	padding-right: 0;
}
