/* import overrides to third party controls */

@import "./angular-material/mat-theme.scss";
@import "./ngx-datatable/ngx-datatable.scss";
@import "./ngx-datatable/ngx-datetime.scss";
@import "./angular-material/mat-multi-select-list.scss";
@import "./angular-material/mat-dialog.scss";
@import "./angular-material/mat-tabs.scss";
@import "./angular-material/mat-checkbox.scss";
@import "./angular-material/mat-radio.scss";
@import "./angular-material/mat-list.scss";
@import "./angular-material/mat-datetime-picker.scss";

// Variables, Mixins
@import "variables.scss";
@import "mixins.scss";

// INSPINIA Theme Elements
@import "typography.scss";

@import "nb-styles/nb-colors.scss";
@import "nb-styles/nb-variables.scss";
@import "nb-styles/nb-icon-font.scss";
@import "nb-styles/nb-typography.scss";

@import "navigation.scss";
@import "top_navigation.scss";
@import "badgets_labels.scss";
@import "elements.scss";
@import "sidebar.scss";
@import "base.scss";
@import "pages.scss";
@import "chat.scss";
@import "metismenu.scss";
@import "spinners.scss";

// Landing page styles
@import "landing.scss";

// RTL Support
@import "rtl.scss";

// INSPINIA Skins
@import "skins.scss";
@import "md-skin.scss";

// Media query style
@import "media.scss";

// Reports tooltip style
@import "tooltip.scss";

// Flexbox helper classes
@import "flexbox.scss";

// Custom style
// Your custom style to override base style
@import "nextbus.scss";

// Clear layout on print mode
@media print {
	nav.navbar-static-side {
		display: none;
	}

	body {
		overflow: visible !important;
	}

	#page-wrapper {
		margin: 0;
	}
}
