.reporttooltip .tooltiptext {
	visibility: hidden;
	background-color: black;
	color: #fff;
	text-align: center;
	padding: 5px 5px;
	margin-top: 20px;
	position: absolute;
	z-index: 1;
}

.reporttooltip:hover .tooltiptext {
	visibility: visible;
}

.tooltip.text-left {
	.tooltip-inner {
		text-align: left;
	}
}

.tooltip-arrow-top .tooltiptext {
	visibility: hidden;
	width: auto;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px;
	position: absolute;
	z-index: 9999;
	bottom: 150%;
	left: 50%;
	margin-left: -26px;
	font-size: 13px;
	white-space: pre-wrap;
	overflow: visible;
}

.tooltip-arrow-top .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
	overflow: visible;
}

.tooltip-arrow-top :hover .tooltiptext {
	visibility: visible;
	overflow: visible;
}
