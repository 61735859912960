.bs-loading-container {
	position: relative;
}
$graybg: $nb-page-bg-color;

$nb-transition: 0.3s ease-in-out;

// margin variables;
$margin-xs: 4px;
$margin-sm: $margin-xs * 2;
$margin-md: $margin-xs * 4;
$margin-lg: $margin-xs * 6;
$margin-xl: $margin-xs * 8;

// Containers
$nb-border-radius: 2px;
@mixin nb-shadow {
	-webkit-box-shadow: 0px 0px 11px -1px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0px 0px 11px -1px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 0px 11px -1px rgba(0, 0, 0, 0.5);
}

@mixin nb-shadow-bottom {
	-webkit-box-shadow: 0px 4px 6px -5px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0px 4px 6px -5px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 4px 6px -5px rgba(0, 0, 0, 0.5);
}

@mixin nb-shadow-top {
	-webkit-box-shadow: 0px -4px 6px -5px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0px -4px 6px -5px rgba(0, 0, 0, 0.5);
	box-shadow: 0px -4px 6px -5px rgba(0, 0, 0, 0.5);
}

.nb-shadow {
	@include nb-shadow;
}

.nb-shadow-bottom {
	@include nb-shadow-bottom;
}

.nb-shadow-top {
	@include nb-shadow-top;
}

$nb-form-border: 1px solid $nb-border-color;
$nb-border: 1px solid $nb-border-color;
$nb-border-light: 1px solid $nb-neutral;

@mixin nb-border-top {
	border-top: $nb-border;
}

@mixin nb-border-bottom {
	border-bottom: $nb-border;
}

.nb-border-top {
	@include nb-border-top;
}

.nb-border-bottom {
	@include nb-border-bottom;
}

.nb-container {
	background-color: $white;
}
// Buttons
$nb-button-radius: 2px;

//Panel Header Heights
$nb-breadcrumbs-height: 40px;
$nb-mapsettings-header-height: 40px;

// padding variables;
$padding-xs: 4px;
$padding-sm: $padding-xs * 2;
$padding-md: $padding-xs * 4;
$padding-lg: $padding-xs * 6;
$padding-xl: $padding-xs * 8;

//////////////////////////

@mixin nb-padding-top-xs {
	padding-top: $padding-xs;
}
@mixin nb-padding-right-xs {
	padding-right: $padding-xs;
}
@mixin nb-padding-bottom-xs {
	padding-bottom: $padding-xs;
}
@mixin nb-padding-left-xs {
	padding-left: $padding-xs;
}

.nb-padding-top-xs {
	@include nb-padding-top-xs;
}
.nb-padding-right-xs {
	@include nb-padding-right-xs;
}
.nb-padding-bottom-xs {
	@include nb-padding-bottom-xs;
}
.nb-padding-left-xs {
	@include nb-padding-left-xs;
}

@mixin nb-padding-all-xs {
	@include nb-padding-top-xs;
	@include nb-padding-right-xs;
	@include nb-padding-bottom-xs;
	@include nb-padding-left-xs;
}

.nb-padding-all-xs {
	@include nb-padding-all-xs;
}
//////////////////////////

@mixin nb-padding-top-sm {
	padding-top: $padding-sm;
}
@mixin nb-padding-right-sm {
	padding-right: $padding-sm;
}
@mixin nb-padding-bottom-sm {
	padding-bottom: $padding-sm;
}
@mixin nb-padding-left-sm {
	padding-left: $padding-sm;
}

.nb-padding-top-sm {
	@include nb-padding-top-sm;
}
.nb-padding-right-sm {
	@include nb-padding-right-sm;
}
.nb-padding-bottom-sm {
	@include nb-padding-bottom-sm;
}
.nb-padding-left-sm {
	@include nb-padding-left-sm;
}

@mixin nb-padding-all-sm {
	@include nb-padding-top-sm;
	@include nb-padding-right-sm;
	@include nb-padding-bottom-sm;
	@include nb-padding-left-sm;
}

.nb-padding-all-sm {
	@include nb-padding-all-sm;
}

//////////////////////////

@mixin nb-padding-top-md {
	padding-top: $padding-md;
}
@mixin nb-padding-right-md {
	padding-right: $padding-md;
}
@mixin nb-padding-bottom-md {
	padding-bottom: $padding-md;
}
@mixin nb-padding-left-md {
	padding-left: $padding-md;
}

.nb-padding-top-md {
	@include nb-padding-top-md;
}
.nb-padding-right-md {
	@include nb-padding-right-md;
}
.nb-padding-bottom-md {
	@include nb-padding-bottom-md;
}
.nb-padding-left-md {
	@include nb-padding-left-md;
}

@mixin nb-padding-all-md {
	@include nb-padding-top-md;
	@include nb-padding-right-md;
	@include nb-padding-bottom-md;
	@include nb-padding-left-md;
}

.nb-padding-all-md {
	@include nb-padding-all-md;
}

//////////////////////////

@mixin nb-padding-top-lg {
	padding-top: $padding-lg;
}
@mixin nb-padding-right-lg {
	padding-right: $padding-lg;
}
@mixin nb-padding-bottom-lg {
	padding-bottom: $padding-lg;
}
@mixin nb-padding-left-lg {
	padding-left: $padding-lg;
}

.nb-padding-top-lg {
	@include nb-padding-top-lg;
}
.nb-padding-right-lg {
	@include nb-padding-right-lg;
}
.nb-padding-bottom-lg {
	@include nb-padding-bottom-lg;
}
.nb-padding-left-lg {
	@include nb-padding-left-lg;
}

@mixin nb-padding-all-lg {
	@include nb-padding-top-lg;
	@include nb-padding-right-lg;
	@include nb-padding-bottom-lg;
	@include nb-padding-left-lg;
}

.nb-padding-all-lg {
	@include nb-padding-all-lg;
}

//////////////////////////

@mixin nb-padding-top-xl {
	padding-top: $padding-xl;
}
@mixin nb-padding-right-xl {
	padding-right: $padding-xl;
}
@mixin nb-padding-bottom-xl {
	padding-bottom: $padding-xl;
}
@mixin nb-padding-left-xl {
	padding-left: $padding-xl;
}

.nb-padding-top-xl {
	@include nb-padding-top-xl;
}
.nb-padding-right-xl {
	@include nb-padding-right-xl;
}
.nb-padding-bottom-xl {
	@include nb-padding-bottom-xl;
}
.nb-padding-left-xl {
	@include nb-padding-left-xl;
}

.nb-padding-all-xl {
	@include nb-padding-top-xl;
	@include nb-padding-right-xl;
	@include nb-padding-bottom-xl;
	@include nb-padding-left-xl;
}

//////////////////////////////////////////////////////////////////////////
// margin variables;
$margin-xs: 4px;
$margin-sm: $margin-xs * 2;
$margin-md: $margin-xs * 4;
$margin-lg: $margin-xs * 6;
$margin-xl: $margin-xs * 8;

//////////////////////////

@mixin nb-margin-top-xs {
	margin-top: $margin-xs;
}
@mixin nb-margin-right-xs {
	margin-right: $margin-xs;
}
@mixin nb-margin-bottom-xs {
	margin-bottom: $margin-xs;
}
@mixin nb-margin-left-xs {
	margin-left: $margin-xs;
}

.nb-margin-top-xs {
	@include nb-margin-top-xs;
}

.nb-margin-right-xs {
	@include nb-margin-right-xs;
}

.nb-margin-bottom-xs {
	@include nb-margin-bottom-xs;
}

.nb-margin-left-xs {
	@include nb-margin-left-xs;
}

@mixin nb-margin-all-xs {
	@include nb-margin-top-xs;
	@include nb-margin-right-xs;
	@include nb-margin-bottom-xs;
	@include nb-margin-left-xs;
}

.nb-margin-all-xs {
	@include nb-margin-all-xs;
}

//////////////////////////

@mixin nb-margin-top-sm {
	margin-top: $margin-sm;
}
@mixin nb-margin-right-sm {
	margin-right: $margin-sm;
}
@mixin nb-margin-bottom-sm {
	margin-bottom: $margin-sm;
}
@mixin nb-margin-left-sm {
	margin-left: $margin-sm;
}

.nb-margin-top-sm {
	@include nb-margin-top-sm;
}
.nb-margin-right-sm {
	@include nb-margin-right-sm;
}
.nb-margin-bottom-sm {
	@include nb-margin-bottom-sm;
}
.nb-margin-left-sm {
	@include nb-margin-left-sm;
}

@mixin nb-margin-all-sm {
	@include nb-margin-top-sm;
	@include nb-margin-right-sm;
	@include nb-margin-bottom-sm;
	@include nb-margin-left-sm;
}

.nb-margin-all-sm {
	@include nb-margin-all-sm;
}

//////////////////////////

@mixin nb-margin-top-md {
	margin-top: $margin-md;
}
@mixin nb-margin-right-md {
	margin-right: $margin-md;
}
@mixin nb-margin-bottom-md {
	margin-bottom: $margin-md;
}
@mixin nb-margin-left-md {
	margin-left: $margin-md;
}

.nb-margin-top-md {
	@include nb-margin-top-md;
}
.nb-margin-right-md {
	@include nb-margin-right-md;
}
.nb-margin-bottom-md {
	@include nb-margin-bottom-md;
}
.nb-margin-left-md {
	@include nb-margin-left-md;
}

@mixin nb-margin-all-md {
	@include nb-margin-top-md;
	@include nb-margin-right-md;
	@include nb-margin-bottom-md;
	@include nb-margin-left-md;
}

.nb-margin-all-md {
	@include nb-margin-all-md;
}

//////////////////////////

@mixin nb-margin-top-lg {
	margin-top: $margin-lg;
}
@mixin nb-margin-right-lg {
	margin-right: $margin-lg;
}
@mixin nb-margin-bottom-lg {
	margin-bottom: $margin-lg;
}
@mixin nb-margin-left-lg {
	margin-left: $margin-lg;
}

.nb-margin-top-lg {
	@include nb-margin-top-lg;
}
.nb-margin-right-lg {
	@include nb-margin-right-lg;
}
.nb-margin-bottom-lg {
	@include nb-margin-bottom-lg;
}
.nb-margin-left-lg {
	@include nb-margin-left-lg;
}

@mixin nb-margin-all-lg {
	@include nb-margin-top-lg;
	@include nb-margin-right-lg;
	@include nb-margin-bottom-lg;
	@include nb-margin-left-lg;
}

.nb-margin-all-lg {
	@include nb-margin-all-lg;
}

//////////////////////////

@mixin nb-margin-top-xl {
	margin-top: $margin-xl;
}
@mixin nb-margin-right-xl {
	margin-right: $margin-xl;
}
@mixin nb-margin-bottom-xl {
	margin-bottom: $margin-xl;
}
@mixin nb-margin-left-xl {
	margin-left: $margin-xl;
}

.nb-margin-top-xl {
	@include nb-margin-top-xl;
}
.nb-margin-right-xl {
	@include nb-margin-right-xl;
}
.nb-margin-bottom-xl {
	@include nb-margin-bottom-xl;
}
.nb-margin-left-xl {
	@include nb-margin-left-xl;
}

@mixin nb-margin-all-xl {
	@include nb-margin-top-xl;
	@include nb-margin-right-xl;
	@include nb-margin-bottom-xl;
	@include nb-margin-left-xl;
}

.nb-margin-all-xl {
	@include nb-margin-all-xl;
}
