// Stop Labels

@mixin nb-text-bold {
	@include nb-text;
	line-height: 16px;
	font-weight: bold;
}

.nb-map-stop-label {
	.nb-text {
		@include nb-text;
		line-height: 16px;
	}
	.nb-text-bold {
		@include nb-text;
		line-height: 16px;
		font-weight: bold;
	}
	.label-route {
		width: 100%;
	}
	position: relative;
	cursor: default;
	height: min-content;
	background-color: $white;
	border: $nb-border;
	width: max-content;
	max-width: 400px;
	@include nb-shadow;

	.label-header {
		display: flex;
		border-bottom: $nb-border;
		@include nb-padding-all-sm;
		// Putting the first block of text in a div so the the last column contents will always align to the end of the container - we want these to flow seperately from the last column
		.text-block {
			display: flex;
			@include nb-text-bold;

			.stop-id {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: flex-start;

				@include nb-margin-right-sm;
			}

			.stop-code {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: flex-start;

				@include nb-margin-right-sm;
			}

			.stop-desc {
				@include nb-margin-right-sm;
			}
		}
		.stop-features {
			text-align: right;
			i {
				color: $nb-textcolor-light;
				font-size: 18px;
			}
		}
	}

	.label-body {
		cursor: default;
		height: min-content;
		.body-row {
			@include nb-padding-all-sm;
			border-bottom: $nb-border-light;
			&:last-of-type {
				border-bottom: none;
			}

			@include nb-margin-bottom-sm;
			display: flex;
			justify-content: space-between;
			.text-block {
				width: 100%;
				display: flex;

				.vehicle-id-col {
					@include nb-text-bold;
					@include nb-margin-right-sm;
					width: 15%;
				}
				.route-id-col {
					@include nb-margin-right-sm;
					width: 15%;
				}
				.trip-headsign-col {
					@include nb-text;
					@include nb-margin-right-sm;
					width: 65%;
				}
			}

			.predicted-time-col {
				@include nb-text-bold;
			}
		}

		.loader-container {
			vertical-align: middle;
			@include nb-padding-all-md;
		}
	}
}

.nb-map-stop-label-container {
	display: inline-block !important;
	background: #fff !important;
	border: 1px solid #fff !important;
	border-radius: 2.5px !important;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.5) !important;
	font-family: $nb-font !important;
	font-size: 14px !important;
	line-height: 14px !important;
	&:hover {
		z-index: 9001 !important;
	}
}

.nb-stop-label {
	background-color: #fff;
	border-radius: $nb-border-radius;
	padding-top: 3px;
}

.nb-maps-hidden-stop-label {
	position: relative;
	top: -2px;
}
