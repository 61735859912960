.mat-mdc-dialog-container {
	display: block;
}

.mat-mdc-dialog-surface {
    overflow-y: initial !important
}

.mat-mdc-dialog-content {
    margin: 0px !important;
    padding: 14px !important;
    max-height: 80vh !important;
	height: 100%;
    border-bottom: solid 1px #eee;
    hr {
        margin-top: 15px;
        margin-bottom: 15px;
        width: 100%;
    }
    overflow-y: auto;
    overflow-x: hidden !important;
}

.mat-dialog-header {
    padding: 15px 15px 5px 15px;
}

.mat-dialog-header-divide {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
}

.mat-mdc-dialog-actions {
    margin-bottom: 0px !important;
    padding: 0px !important;
    min-height: 60px !important;
    hr {
        margin-top: 0px;
        margin-bottom: 15px;
        width: 100%;
    }
}

.mat-mdc-dialog-actions {
    button+button {
        margin-left: 8px;
    }
}

.mat-dialog-footer-divide {
    margin-top: -5px;
    margin-bottom: 5px;
    padding: 0px;
    width: 97%;
}

.mat-mdc-dialog-container .mdc-dialog__content {
    font-family: "Roboto", sans-serif !important;
	letter-spacing: normal!important;
	font-weight: normal!important;
}

.mat-mdc-dialog-container .noUi-value {
	top: 10px;
}
