@import "nb-ladder-vertical.scss";

.nb-ladder-empty-container {
	position: fixed;
	top: calc((100vh / 2) - 64px);
	text-align: center;
	width: 100%;
}

.empty-ladder-icon {
	height: 60px;
	margin-bottom: 20px;
}

.empty-ladder-title {
	font-weight: 500;
	margin-bottom: 20px;
	font-size: 18px;
}

.empty-ladder-subtitle {
	position: relative;
	left: 6px;
	font-size: 14px;
	margin-bottom: 20px;
}

.nb-ladder-empty-container a {
	display: inline-block;
	border: 1px solid black;
	padding-left: 10px;
	padding-right: 10px;
	font-family: "Roboto", sans-serif !important;
	font-size: 13px !important;
	font-weight: 400;
	letter-spacing: 0.5px;
	border-radius: 2px;
}

.nb-ladder-empty-container a svg {
	display: inline-block;
	vertical-align: middle;
}

.nb-ladder-empty-container a span {
	position: relative;
	top: 1.5px;
	padding-right: 7px;
}
