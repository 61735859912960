.confirm-message {
	@include nb-padding-all-lg;
	@include nb-text;
	text-align: center;
}

.expiray-message {
	padding: 0;
	text-align: center;
}

.confirm-subhead {
	@include nb-padding-all-lg;
	@include nb-heading;
	text-align: center;
}

.nb-confirm-message {
	overflow-wrap: break-word;
	word-wrap: break-word;
}
