/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

$nb-map-details-width: 450px;
$nb-map-details-width-expanded: 750px;
// TO DO

//liveops header
.nb-map-details-title-bar {
	display: inline-block;
	vertical-align: middle !important;
	.nb-subhead {
		padding-left: 8px;
	}
}

.nb-maps-viewtables {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	.column {
		@include nb-margin-bottom-md;

		.view-table-label {
			@include nb-inline-label;
		}
		.view-table-field {
			@include nb-text-sm;
		}
	}
	.item {
		@include nb-margin-bottom-md;
		@include nb-margin-right-md;

		.view-table-label {
			@include nb-inline-label;
		}
		.view-table-field {
			@include nb-text-sm;
		}
	}
	.viewtable-row {
		.column-half-width {
			float: left;
			width: 50%;
		}
		.column-quarter-width {
			float: left;
			width: 25%;
		}
	}
	.viewtable-row:after {
		content: "";
		display: block;
		clear: both;
	}
}
.view-table-label,
.view-table-label span {
	@include nb-inline-label;

	nb-data-view-sort & {
		line-height: 34px;
		padding-right: 8px;
	}
}

.vehicle-status {
	background-color: $white;
	overflow: hidden;
	background: $nb-textcolor;
	color: $white !important;
	text-align: center;
	padding: 4px 8px 4px 8px;
	border-radius: 2px;
	margin: 0 2px;
	font-weight: bold;
	font-size: 12px;
	line-height: 12px;
	display: block;
}
//Align the flex columns with each other when in a small view
.nb-map-details {
	.nb-maps-viewtables {
		.item {
		}
	}
}
//Make the full-width view of the viewtables spread accross
.nb-map-details-expanded {
	.nb-maps-viewtables {
		.item {
		}
	}
}

//LIVEOPS / MAP DETAILS

//Live Ops icon container
.nb-map-details-nav {
	background-color: $toolbar-gray-color;
	text-align: center;
	height: 150px;
	@include nb-padding-all-md;
}

.nb-map-details-back-btn {
	color: $nb-textcolor;
}
///////////////////////////////////////////////////////////////////////////////
//Main pages - Routes / Vehicles / Stops

//Header
.nb-map-header-container {
	@include nb-border-bottom;
	background-color: $toolbar-gray-color;
	@include nb-padding-all-sm;
	.header-title-row {
		display: flex;
		width: 100%;
		.header-title {
			width: auto;
			@include nb-padding-left-xs;
		}
		.header-backbutton {
			width: auto;
			@include nb-padding-all-xs;
			transform: translateY(-3px);
		}
	}
	.header-search-row {
		display: flex;
		width: 100%;
		@include nb-padding-bottom-sm;
		.header-searchbar {
			//width:auto;
			width: 100%;
		}
		.header-menubutton {
			width: auto;
		}
	}
	.header-filter-row {
		display: flex;
		width: 100%;
		.header-searchresults {
			margin-left: 2px;
			margin-right: 2px;
			width: 100%;
		}
		.header-filter {
			width: auto;
		}
	}
}

///////////////////////////////////////////////////////////////////////////////
//Sub pages - Route details / Vehicle details / Stop details

//Header
.nb-map-subpage-header-container {
	display: flex;
	background-color: $toolbar-gray-color;
	@include nb-border-bottom;
	@include nb-padding-all-sm;
	.header-backbutton {
		width: inherit;
		@include nb-padding-all-xs;
		&.move-up {
			transform: translateY(-4px);
		}
	}
	.header-content {
		width: 100%;
		@include nb-padding-all-xs;

		.header-title {
			width: 100%;
			@include nb-padding-bottom-sm;
		}
		.header-subtitle {
			width: 100%;
		}
		.header-body {
			width: 100%;
			.body-label {
				@include nb-label;
			}
			.body-content {
				@include nb-text;
			}
		}
	}
}
.nb-mapheader-toolbar {
	@include nb-padding-all-sm;
	padding-top: 8px;
	padding-bottom: 8px;
	background-color: $white;
	@include nb-border-bottom;
	.btn {
		@include nb-padding-right-sm;
		margin-right: 4px;
	}
}
.nb-blocks-header-buttons {
	flex-wrap: nowrap;
	justify-content: space-between;
	@include nb-padding-all-sm;
	padding-top: 8px;
	padding-bottom: 4px;
	background-color: $white;
	@include nb-border-bottom;
	.btn {
		@include nb-padding-right-sm;
	}
}

.nb-blocks-trip-header-buttons {
	flex-wrap: nowrap;
	justify-content: space-between;
	@include nb-padding-all-sm;
	padding-top: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
	background-color: $white;
	.btn {
		@include nb-padding-right-sm;
	}
}

.nb-blocks-trip-header-action-buttons {
	display: inline-block;
	margin-left: 10px;
}

.nb-collapsable-toolbar {
	@include nb-margin-top-sm;
}
////////////////////////////////////////////////////////////////
//TABLES
// .nb-table-col-header {
//   border-bottom: 3px solid $nb-border-color;
//   padding-bottom:4px;
//   a {
//     .nb-label !important;
//     font-weight:500;
//     color:$nb-textcolor-light !important;
//   }
//   .nb-icons {
//     color:$nb-textcolor-light;
//     font-size:18px;
//     padding-top:2px;
//   }
// }
// .nb-table-col-header:hover {
//   border-bottom: 3px solid $nb-primary-lighter;
//   padding-bottom:4px;
// }
//
// .nb-table-col-header-active {
//   border-bottom: 3px solid $nb-accent;
//   padding-bottom:4px;
//   a {
//     .nb-label !important;
//     font-weight:500;
//     color:$nb-textcolor-light !important;
//   }
//   .nb-icons {
//     color:$nb-accent;
//     font-size:18px;
//     padding-top:2px;
//   }
// }
// .nb-table-col-header-active:hover{
//   border-bottom: 3px solid $nb-accent-dark;
//   padding-bottom:4px;
//   .nb-icons {
//     color:$nb-accent-dark;
//   }
// }
//   .nb-map-details-table {
//
//     .nb-view-on {
//       color:$nb-accent !important;
//     }
//
//     overflow:scroll;
//     @include nb-padding-all-sm;
//     .nb-view-toggle-checkbox {
//     }
//   label {
//     margin-bottom:0px !important;
//   }
//     table {
//       background-color:$white;
//       width:100%;
//       border-collapse: collapse;
//       th {
//         padding:0px !important;
//         height:48px;
//         .col-title {
//           @include nb-padding-left-xs;
//         }
//         .table-sorting-icon {
//           text-align:right;
//           .nb-padding-right-xs !important;
//         }
//         a {
//           .nb-label !important;
//           font-weight:500;
//           color:$nb-textcolor !important;
//         }
//         .nosorting-label {
//           .nb-label !important;
//           font-weight:500;
//           color:$nb-textcolor !important;
//         }
//         }
//         td {
//           border-top:0px !important;
//           cursor:pointer;
//             .nb-padding-bottom-sm ;
//             @include nb-padding-top-xs;
//             @include nb-padding-left-xs;
//             @include nb-padding-right-xs;
//         }
//         tr {
//             border-bottom:1px solid $nb-border-color;
//         }
//         tr:last-of-type {
//           border-bottom:0px;
//         }
//         .multiline-title {
//           .table-sorting-icon {
//             padding-top: 18px;
//           }
//         }
//         //View Icon Column - used throughout the Map Details
//         .view-column {
//           width:44px;
//           text-align:left;
//           @include nb-padding-left-md;
//         }
//         //Vehicles
//         .vehicle-id-col {
//           width:54px;
//         }
//         .vehicle-route-col {
//           width:64px;
//         }
//         .vehicle-status-col {
//           width:64px;
//         }
//         .vehicle-agency-col {
//           width:64px;
//         }
//         .vehicle-arrivaladherence-col {
//           width:68px;
//           @include multiline-title;
//         }
//         .vehicle-departureadherence-col {
//           width:68px;
//           @include multiline-title;
//         }
//         .vehicle-predictioncount-col {
//           width:64px;
//           @include multiline-title;
//         }
//         .vehicle-predictionaccuracy-col {
//           width:64px;
//           @include multiline-title;
//         }
//         //Vehicles / Trip Details
//         .trips-stop-id-col {
//           width:60px;
//         }
//         .trips-description-col {
//           // width:64px;
//         }
//         .trips-scheduled-arrival-col {
//           width:90px;
//           @include multiline-title;
//         }
//         .trips-actual-arrival-col {
//           width:70px;
//           @include multiline-title;
//         }
//         .trips-scheduled-departure-col {
//           width:90px;
//           @include multiline-title;
//         }
//         .trips-actual-departure-col {
//           width:90px;
//           @include multiline-title;
//         }
//         .trips-adherence-col {
//           width:90px;
//         }
//         .trips-predictionaccuracy-col {
//           width:90px;
//           @include multiline-title;
//         }
//         //Stops
//         .stop-id-col {
//           width:54px;
//         }
//         .stop-name-col {
//           width:220px;
//         }
//         .stop-agency-col {
//           width:64px;
//         }
//         .stop-arrivaladherence-col {
//           width:72px;
//           @include multiline-title;
//         }
//         .stop-departureadherence-col {
//           width:72px;
//           @include multiline-title;
//         }
//         .stop-predictioncount-col {
//           width:68px;
//           @include multiline-title;
//         }
//         .stop-predictionaccuracy-col {
//           width:68px;
//           @include multiline-title;
//         }
//
//         //Routes
//         .routes-id-col {
//           width:44px;
//         }
//         .routes-name-col {
//           width:180px;
//         }
//         .routes-activeblocks-col {
//           width:68px;
//           @include multiline-title;
//         }
//         .routes-assignedvehicles-col {
//           width:68px;
//           @include multiline-title;
//         }
//         .routes-predictability-col {
//           width:80px;
//         }
//         .routes-adherence-col {
//           width:80px;
//         }
//     }
// }

/*MY SAVED MAP VIEWS LIST */
.nb-map-saved-views {
	.flex-table {
		.flex-table-row {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: stretch;
			align-content: stretch;
			border-bottom: 1px solid $nb-border-color;
			cursor: pointer;

			.nb-map-view-function-button {
				color: $nb-accent;
				@include nb-padding-all-sm;
				@include nb-padding-right-xs;
				@include nb-padding-left-xs;

				visibility: hidden;
				&:hover {
					color: $nb-accent-darker;
				}
			}

			&:hover {
				background-color: $nb-primary-lighter;
				.nb-map-view-function-button {
					visibility: visible;
				}
			}
		}
	}
}

.testing > .paging-toolbar {
	border-top: 0px;
	padding-top: 0px;
}

.nb-map-details-static-table {
	.table > tbody + tbody {
		border-top: 0px !important;
	}

	table {
		background-color: $white;
		width: 100%;
		border-collapse: collapse;
		th {
			.col-title {
				@include nb-label;
				font-weight: 500;
				color: $nb-textcolor !important;
			}
		}

		td {
			border-top: 0px !important;
			cursor: pointer;
			@include nb-padding-bottom-sm;
			@include nb-padding-top-xs;
			@include nb-padding-left-xs;
			@include nb-padding-right-xs;
		}
		tr {
			border-bottom: 1px solid $nb-border-color !important;
		}
		tr:last-of-type {
			border-bottom: 0px;
		}
		td:last-of-type {
			border-bottom: 0px;
		}
	}
}

////////////////////////////////////////////////////////////////
//Body containers

@mixin content-block {
	@include nb-padding-all-md;
	@include nb-border-bottom;
	word-wrap: break-word;
	.sectiontitle-block {
		@include nb-margin-bottom-sm;
		display: inline-block;
		overflow: visible;
	}
}

.content-block {
	@include content-block;
}

@mixin content-block-no-border {
	@include content-block;
	border-bottom: 0px !important;
}

.content-block-no-border {
	@include content-block-no-border;
}

.nb-map-details-body {
	border-top: $nb-border;

	.content-block {
		@include nb-padding-all-md;
		@include nb-border-bottom;
		word-wrap: break-word;
		.sectiontitle-block {
			@include nb-margin-bottom-sm;
			display: inline-block;
			overflow: visible;
		}
	}
	.content-block-no-border {
		@include content-block;
		border-bottom: 0px !important;
	}
	.info-table {
		.row {
			@include nb-padding-bottom-xs;
			border-bottom: 1px dotted $nb-border-color;
			@include nb-margin-top-xs;
		}
		.row:last-of-type {
			border-bottom: 0px;
		}
	}
	.chart {
		.chart-labels {
			@include nb-label;
			@include all-caps;
			font-size: 11px;
			font-weight: 500;
			text-align: center;
		}
		.chart-row {
			@include nb-padding-bottom-xs;
		}
	}
	.view-more {
		@include nb-margin-top-sm;
		a {
			@include nb-text-link;
			color: $nb-accent;
			text-align: left;
			&:hover {
				color: $nb-accent-darker;
			}
		}
	}

	.flex-center-items {
		display: flex;
		align-items: center;
	}
}

@mixin chart-largenumbers {
	font-family: $nb-font;
	font-size: 23px;
	font-weight: 400;
	text-align: center;
	//Color is controlled by the state (late, early, etc.)
}

.chart-largenumbers {
	@include chart-largenumbers;
}

@mixin chart-smallnumbers {
	font-size: 13px;
	font-weight: 500;
	text-align: center;
	//Color is controlled by the state (late, early, etc.)
}

.chart-smallnumbers {
	@include chart-smallnumbers;
}

/////////////////////////////////////////////////////////////////////////////////////////////
//Map Details - Lists

//keep
.nb-map-details-list {
	margin-bottom: 0.3em;
	margin-top: 0.6em;
	//border-bottom:1px solid $nb-border-color;
	padding-bottom: 1px;
}
//keep
.nb-map-details-list-items {
	color: $nb-textcolor;
	font-size: 14px;
	@include all-caps;
}

/////////////////////////////////////////////////////////////////////////////////////////////
//Map Details - Containers

.nb-map-nav-view-container {
	position: absolute;
	top: 0px;
	width: 529px;
	left: 0;
	/*overflow: hidden;*/
	pointer-events: none;
	// transition: all .5s ease-in-out; */ moved to ngStyle within nav component to turn on when necessary as it was affecting child loading spinner */
	z-index: 3000;
}

.nb-map-nav-view-container-closed {
	position: absolute;
	top: 0;
	margin-right: -529px;
	height: 1200px;
	width: 529px;
	left: -529px;
	overflow: hidden;
	pointer-events: none;
	transition: all 0.5s ease-in-out;
	z-index: 2;
}

.map-nav-breadcrumbs-container {
	background-color: $white;
	height: 30px;
	@include nb-padding-all-sm;
	padding-top: 4px;
	white-space: nowrap;
	overflow: hidden;
	z-index: 10000;
	position: relative;
}

.nb-map-details {
	@include nb-shadow;
	background-color: $white;
	width: 529px;
	border-top: 1px solid #e7eaec;
	transition: all 0.5s ease-in-out;
	z-index: 1000;
	pointer-events: auto;
}

.nb-map-details-expanded {
	background-color: $white;
	width: $nb-map-details-width-expanded;
	border-top: 1px solid #e7eaec;
	transition: all 0.5s ease-in-out;
	pointer-events: auto;
}

.nb-map-details-content {
	pointer-events: auto;
}

.nb-map-details-view-content {
	background-color: $white;
	box-shadow: 1px 1px 1px 1px #888888;
	margin-bottom: 10px;
	padding-left: 0;
	padding-right: 0;
	pointer-events: auto;
}

.nb-map-details-views-wrapper {
	background-color: $white;
	margin-left: 0px;
	margin-right: 0px;
	overflow: hidden;
}

.nb-map-details-table-wrapper {
	background-color: $white;
	margin-left: -15px;
	margin-right: -15px;
	overflow: scroll;
}

.nb-map-details-route-wrapper {
	background-color: $white;
	margin-left: 0px;
	margin-right: 0px;
	overflow: auto;
	overflow-x: hidden;
}

.nb-map-details-vehicle-wrapper {
	background-color: $white;
	margin-left: 0px;
	margin-right: 0px;
	overflow: auto;
}

.nb-map-details-vehicle-schedule-wrapper {
	background-color: $white;
	margin-left: 0px;
	margin-right: 0px;
	overflow: auto;
}

.nb-map-details-vehicle-reassign-wrapper {
	background-color: $white;
	margin-left: 0px;
	margin-right: 0px;
	overflow: auto;
}

.nb-map-details-vehicle-reassign-blocks-wrapper {
	background-color: $white;
	margin-left: 0px;
	margin-right: 0px;
	overflow: auto;
}

.nb-map-details-stop-wrapper {
	background-color: $white;
	margin-left: 0px;
	margin-right: 0px;
	overflow: auto;
	overflow-x: hidden;
}

.nb-map-details-block-wrapper {
	background-color: $white;
	margin-left: 0px;
	margin-right: 0px;
	overflow: auto;
	overflow-x: hidden;
}

/////////////////////////////////////////////////////////////////////////////////////////////
//Map Details - expand / condense button

@mixin nb-mapdetails-controlbutton {
	background-color: $white;
	position: absolute;
	padding: 6px 8px 7px 6px;
	border-radius: 0px 7px 7px 0px;
	font-size: 16px;
	top: 0px;
	box-shadow: 1px 1px grey;
	cursor: pointer;
	border: $nb-border;
	width: 8px;
	color: $nb-textcolor;
	transition: all 0.5s ease-in-out;
	pointer-events: auto;
}

//Used on all states of the map details button (expanded / condensed / closed)
.nb-mapdetails-controlbutton {
	@include nb-mapdetails-controlbutton;
}

.nb-map-details-button-expand {
	@include nb-mapdetails-controlbutton;
	left: $nb-map-details-width;
}
.nb-map-details-button-expand:hover {
	padding: 6px 8px 7px 10px;
}

.nb-map-details-button-contract {
	@include nb-mapdetails-controlbutton;
	left: $nb-map-details-width-expanded;
}
.nb-map-details-button-contract:hover {
	padding: 6px 8px 7px 10px;
}

.nb-map-details-button-data-view-open {
	@include nb-mapdetails-controlbutton;
	top: 0;
	left: 0;
	z-index: 9000;
}

// .nb-map-details-nav-header-close {
//   // color: $nb-textcolor;
//   // position:relative;
//
// }
// @-moz-document url-prefix() {
//   .nb-map-details-nav-header-close  {
//     button {
//       position:relative;
//       top:-20px;
//     }
//   }
// }

/////////////////////////////////////////////////////////////////////////////////////////////
//Map Details - metrics

.map-details-metric-bar {
	height: 6px;
	background-color: $white;
}

.map-details-metric-assigned {
	// font-size: 24px;
	@include chart-largenumbers;
	color: $assigned;
}

.map-details-metric-assigned-pct {
	@include chart-smallnumbers;
	color: $assigned;
}

.map-details-metric-blocks {
	// font-size: 24px;
	@include chart-largenumbers;
	color: $blocks;
}

.map-details-metric-issues {
	// font-size: 24px;
	@include chart-largenumbers;
	color: $withissues;
}

.map-details-metric-issues-pct {
	@include chart-smallnumbers;
	color: $withissues;
}

.map-details-metric-assigned-bar {
	background-color: $assigned;
}

.map-details-metric-issues-bar {
	background-color: $withissues;
}

.map-details-metric-very-early {
	@include chart-largenumbers;
	color: $veryearly;
}

.map-details-metric-very-early-pct {
	@include chart-smallnumbers;
	color: $veryearly;
}

.map-details-metric-very-early-bar {
	background-color: $veryearly;
}

.map-details-metric-early {
	// font-size: 24px;
	@include chart-largenumbers;
	color: $early;
}

.map-details-metric-early-pct {
	@include chart-smallnumbers;
	color: $early;
}

.map-details-metric-early-bar {
	background-color: $early;
}

.map-details-metric-on-time {
	// font-size: 24px;
	@include chart-largenumbers;
	color: $ontime;
}

.map-details-metric-on-time-pct {
	@include chart-smallnumbers;
	color: $ontime;
}

.map-details-metric-on-time-bar {
	background-color: $ontime;
}

.map-details-metric-late {
	// font-size: 24px;
	@include chart-largenumbers;
	color: $late;
}

.map-details-metric-late-pct {
	@include chart-smallnumbers;
	color: $late;
}

.map-details-metric-late-bar {
	background-color: $late;
}

.map-details-metric-very-late {
	// font-size: 24px;
	@include chart-largenumbers;
	color: $verylate;
}

.map-details-metric-very-late-pct {
	color: $verylate;
	@include chart-smallnumbers;
}

.map-details-metric-very-late-bar {
	background-color: $verylate;
}

.map-details-metric-vehicles-ok {
	// font-size: 24px;
	@include chart-largenumbers;
	color: $ok;
}

.map-details-metric-vehicles-ok-pct {
	color: $ok;
	@include chart-smallnumbers;
}

.map-details-metric-vehicles-ok-bar {
	background-color: $ok;
}

.map-details-metric-vehicles {
	// font-size: 24px;
	@include chart-largenumbers;
	color: $vehicles;
}

#speedometer {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	margin: 0 auto;
	position: relative;

	border: 10px solid #777;

	background: radial-gradient(ellipse farthest-corner, white, #a3e2fc);

	box-shadow: 0 0 4px #000, 0 0 1px 8px #ccc, 5px 10px 15px #48436f;

	transition: transform 0.5s ease, box-shadow 0.5s ease;
}

#compass span {
	position: absolute;
	font-weight: bold;
}
#pointer {
	width: 60px;
	height: 30px;

	/* Centering it */
	position: relative;
	left: 50%;
	top: 50%;
	margin-left: -30px;
	margin-top: -15px;
}

@-moz-document url-prefix() {
	.nb-map-details-nav-header-close {
		button {
			position: relative;
			top: -20px;
		}
	}
}

map-details .table {
	margin-bottom: 16px;
	width: 100%;
}
