.nb-account-dropdown {
	width: auto !important;
	padding-right: 20px !important;
	background: $nb-primary-darker !important;
}
.nb-account-dropdown-title {
	height: 58px;
	background-color: $nb-primary-darker !important;
	transition: 0.3s ease-in-out;
	font-family: $nb-font;
	letter-spacing: 0.6px;
	font-size: 13px;
	font-weight: 400 !important;
	color: $white !important;
	i {
		@include nb-padding-right-sm;
		transition: 0.3s ease-in-out;
		color: $nb-primary-light !important;
		font-size: 15px;
	}
	&:hover,
	&:active {
		background-color: $nb-primary-dark !important;
	}
}
.nb-account-dropdown-menu {
	transition: 0.3s ease-in-out;
	width: -webkit-fill-available;
	background-color: $nb-primary-darker;
	li {
		transition: 0.3s ease-in-out;
		border-radius: 0 !important;
		background-color: $nb-primary-darker;
		a {
			transition: 0.3s ease-in-out;
			font-family: $nb-font;
			letter-spacing: 0.6px;
			font-size: 12px;
			font-weight: 400 !important;
			color: $white !important;
		}

		i {
			transition: 0.3s ease-in-out;
			@include nb-padding-right-sm;
			font-size: 15px;
			color: $nb-primary-light;
		}
	}

	li:hover,
	li:active {
		a {
			border-radius: 0 !important;
			background-color: $nb-primary-dark !important;
		}

		i {
			color: $white;
		}
	}
}

.nb-header-user-superscript {
	position: absolute;
	top: -3px;
	left: 8px;
	padding: 0px;
	width: 9px;
	height: 9px;
	min-width: 1px;
}
