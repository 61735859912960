.toolbar {
	padding-left: $padding-md;
	padding-right: $padding-md;
	padding-top: $padding-md;
	padding-bottom: 0px;
	background-color: $white;
	border-top: $nb-border;
	border-bottom: $nb-border;
	button {
		i {
			margin-right: $margin-xs;
		}
	}

	.toolbar-group {
		/* need to force the font size to 0px to prevent weird wrap at small sizes of floated button. */
		font-size: 0px;
		padding: 0px;
		padding-bottom: $padding-sm;

		button {
			margin-bottom: $margin-sm;
		}
	}

	.toolbar-group-right {
		float: right;
		button {
			margin-left: $margin-xs;
		}
	}

	.toolbar-group-left {
		button {
			margin-right: $margin-xs;
		}
	}
}

.toolbar-sm {
	padding-left: $padding-sm;
	padding-right: $padding-sm;
	padding-top: $padding-sm;
	padding-bottom: 0px;
	.toolbar-group {
		padding-bottom: 0;
	}
}
