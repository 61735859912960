//Bootstrap Overrides
.table-hover > tbody > tr:hover {
	background-color: $nb-primary-lighter;
}

.nb-table-header-search-row {
	background-color: $toolbar-gray-color;
	padding: 5px;
}

.nb-table-header-create-row {
	background-color: $white;
}

.nb-table-search-ctrl {
	width: 30%;
}

.nb-table-col-date {
	text-align: center;
	min-width: 90px;
}

.nb-table-col-status {
	text-align: center;
	min-width: 90px;
}

.nb-create-user-button {
	background-color: $white !important;
	border-color: $white;
	color: $nb-accent;
}

.nb-create-user-button:hover {
	background-color: $white !important;
	border-color: $white;
	color: $nb-accent-dark;
}

.nb-unfiltered-ctrl-text {
	color: #676a6c;
}

.nb-filtered-ctrl-text {
	color: $nb-accent;
}

.pagination-anchored {
	// position:fixed;
	//width:$nb-map-details-width;
	min-width: 435px;
	padding-top: 8px;
	margin-right: -8px;
	padding-bottom: 8px;
	margin-left: -8px;
	@include nb-border-top;
	bottom: 0;
	background-color: $nb-neutral-light;
}

.nb-flexbox-table-row-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	.divrow {
		display: flex;
		&:hover {
			background-color: #f1f5fb;
		}
		.flex-width1 {
			display: flex;
			flex: 1;
			min-width: 58px;
		}
		.flex-width2 {
			display: flex;
			flex: 2;
			min-width: 58px;
		}
		.flex-width3 {
			display: flex;
			flex: 3;
			min-width: 58px;
		}
	}
	.tablehead {
		display: flex;
		flex: 1;
		padding-bottom: 4px;
		border-bottom: 1px solid #dbdbdb;
		.flex-width1 {
			display: flex;
			flex: 1;
			min-width: 58px;
			font-weight: 500;
		}
		.flex-width2 {
			display: flex;
			flex: 2;
			min-width: 58px;
			font-weight: 500;
		}
		.flex-width3 {
			display: flex;
			flex: 3;
			min-width: 58px;
			font-weight: 500;
		}
	}
	.tablebody {
		overflow-y: scroll;
		height: 150px;
		flex-direction: column;
		.flex-width1 {
			display: flex;
			flex: 1;
			min-width: 58px;
			font-weight: 500;
		}
		.flex-width2 {
			display: flex;
			flex: 2;
			min-width: 58px;
			font-weight: 500;
		}
		.flex-width3 {
			display: flex;
			flex: 3;
			min-width: 58px;
			font-weight: 500;
		}
	}
}

.flexitem {
	display: flex;
}
