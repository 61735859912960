.nb-view-more {
	width: 100%;
	height: 30px;
	text-align: center;
	border: 1px solid $nb-border-color;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 1em;
	border-radius: 0.3em;
	a {
		color: $nb-primary;
		font-weight: 500;
	}
}
