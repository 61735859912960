.mapGrowlMsg {
	position: relative;
	background-color: $nb-accent;
	color: white;
	padding: 5px;

	i {
		color: white;
	}
}

#growlMessageContainer {
	position: absolute;
	bottom: 30px;
	width: 100%;
}
