/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
.map-vehicle-details-header {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.item {
		width: 45%;
		transition: width 0.6s ease;
	}

	&.expanded {
		.item {
			width: 30%;
		}
	}
}

// Compass & speedometer

.map-vehicle-compass-speedometer {
	margin-top: 20px;
	text-align: center;

	& > div {
		height: 130px;
		width: 160px;

		& > div {
			height: 130px;
			overflow: hidden;
			position: relative;
		}
	}

	.map-details-vehicle-compass {
		width: 130px;
		height: 130px;
	}

	.map-details-vehicle-compass-needle {
		width: 80px;
		height: 80px;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -40px 0 0 -40px;
		transition-duration: 1s;
	}

	// Speedometer

	.map-details-vehicle-speedo {
		width: 130px;
		height: 130px;
	}

	.map-details-vehicle-speedo-needle {
		width: 80px;
		height: 80px;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -40px 0 0 -40px;
		transition-duration: 1s;
	}

	.map-details-vehicle-speedo-digits-container {
		font-family: $nb-font;
		line-height: 16px;
		position: absolute;
		bottom: 1px;
		left: 50%;
		transform: translate(-50%, 0);
		text-align: center;
		.digits {
			font-size: 18px;
			font-weight: 600;
		}
		.units {
			font-size: 10px;
		}
	}
}
