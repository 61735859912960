$nb-panel-header-border-color: $nb-border-color;
$nb-panel-header-background-color: $white;

.nb-panel-header {
	background-color: $nb-panel-header-background-color;
	margin-bottom: 0px;
	border-bottom: 1px solid $nb-panel-header-border-color;
	border-radius: 0;
	.navbar {
	}
	.breadcrumb {
		margin-bottom: $padding-md;
		background-color: transparent;
	}
}
