.nb-view-table {
	.col-xs-12 {
		padding: 0 !important;
	}
	overflow: hidden;
	border: $nb-border;
	background-color: $white;
	@include nb-margin-left-md;
	@include nb-margin-right-md;
	@include nb-margin-bottom-md;

	.nb-view-table-heading {
		@include nb-subhead;
		@include nb-padding-all-sm;
		border-bottom: $nb-border;
	}

	.nb-view-table-content {
		@include nb-padding-all-sm;

		.row {
			display: flex;
			align-items: flex-start;
			@include nb-margin-bottom-md;
			flex-direction: row;
			margin: 0;

			.heading {
				@include nb-label;
				width: 30%;
				text-align: right;
				line-height: 22px;
				@include nb-margin-right-md;
			}

			.content {
				@include nb-text;
				width: 70%;
				text-align: left;
			}
		}
	}
}

.nb-view-table-4 {
	.nb-view-table-row {
		// @include nb-padding-all-sm;

		.nb-view-table-cell {
			@include nb-margin-bottom-sm;
			@include nb-margin-top-sm;
			label {
				@include nb-label;
			}
			div {
				@include nb-text;
			}
		}
	}
}
