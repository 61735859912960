.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
	color: #595959;
	opacity: 0.5;
	cursor: not-allowed;
	background-color: #fff;
	border-color: #ddd;
}
