.dashboard-container h1 a {
    text-decoration: none;
}

.dashboard-container .tooltip-inner {
    white-space: pre-wrap;
    width: 100px;
}

.button-margin {
    margin-right: 4px;
}

.ng-icon {
    font-family: 'nb-icon-font' !important;
    font-style: normal;
    font-weight: normal;
    position: relative;
}
