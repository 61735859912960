.nb-map-container-display-mode {
	/*border-top: 4px solid $nb-accent;*/
	position: absolute;
	top: 0px;
	right: 0;
	z-index: 1000;
	text-align: center;
	width: 100%;
}

.nb-map-container-display-mode-replay {
	background-color: $nb-accent;
	color: $white;
	height: 24px;
	margin-left: 10px;
	min-width: 200px;
	position: relative;
	display: inline-block;
	border-bottom-right-radius: 0.3em;
	border-bottom-left-radius: 0.3em;
	.close {
		opacity: 1 !important;
		color: $white !important;
		&:hover {
			color: $nb-accent-darker !important;
		}
	}

	.nb-icons {
		font-size: 14px;
		color: $white !important;
	}
}

.nb-map-container-display-mode-tracking {
	background-color: $nb-accent;
	color: $white;
	height: 24px;
	margin-left: 10px;
	min-width: 245px;
	position: relative;
	display: inline-block;
	border-bottom-right-radius: 0.3em;
	border-bottom-left-radius: 0.3em;
	.close {
		opacity: 1 !important;
		color: $white !important;
		&:hover {
			color: $nb-accent-darker !important;
		}
	}

	.nb-icons {
		font-size: 14px;
		color: $white !important;
	}
}

.nb-map-container-display-mode-trailing {
	background-color: $nb-accent;
	color: $white;
	height: 24px;
	margin-left: 10px;
	min-width: 245px;
	position: relative;
	display: inline-block;
	border-bottom-right-radius: 0.3em;
	border-bottom-left-radius: 0.3em;
	.close {
		opacity: 1 !important;
		color: $white !important;
		&:hover {
			color: $nb-accent-darker !important;
		}
	}

	.nb-icons {
		font-size: 14px;
		color: $white !important;
	}
}

.nb-map-container-display-mode-unassigned {
	background-color: $nb-accent;
	color: $white;
	height: 24px;
	margin-left: 10px;
	min-width: 300px;
	position: relative;
	display: inline-block;
	border-bottom-right-radius: 0.3em;
	border-bottom-left-radius: 0.3em;
	.close {
		opacity: 1 !important;
		color: $white !important;
		&:hover {
			color: $nb-accent-darker !important;
		}
	}

	.nb-icons {
		font-size: 14px;
		color: $white !important;
	}
}

.nb-map-container-display-mode-stale {
	background-color: $nb-accent;
	color: $white;
	height: 24px;
	margin-left: 10px;
	min-width: 200px;
	position: relative;
	display: inline-block;
	border-bottom-right-radius: 0.3em;
	border-bottom-left-radius: 0.3em;
	.close {
		opacity: 1 !important;
		color: $white !important;
		&:hover {
			color: $nb-accent-darker !important;
		}
	}

	span {
		position: relative;
		top: 1px;
	}

	.nb-icons {
		font-size: 14px;
		color: $white !important;
	}
}

.nb-map-container-display-mode-depot {
	background-color: $nb-accent;
	color: $white;
	height: 24px;
	margin-left: 10px;
	min-width: 270px;
	position: relative;
	display: inline-block;
	border-bottom-right-radius: 0.3em;
	border-bottom-left-radius: 0.3em;
	.close {
		opacity: 1 !important;
		color: $white !important;
		&:hover {
			color: $nb-accent-darker !important;
		}
	}

	span {
		position: relative;
		top: 1px;
	}

	.nb-icons {
		font-size: 14px;
		color: $white !important;
	}
}

.nb-map-container-display-paused {
	border-bottom: 4px solid $reallybad;
	position: absolute;
	bottom: 0;
	width: 100%;
	z-index: 1000;
	text-align: center;
}

.nb-map-container-display-paused-on {
	color: $white !important;
	background-color: $reallybad;
	height: 24px;
	padding-top: 2px;
	min-width: 360px;
	position: relative;
	display: inline-block;
	border-top-right-radius: 0.3em;
	border-top-left-radius: 0.3em;
	.close {
		opacity: 1 !important;
		color: $white !important;
		&:hover {
			color: $nb-accent-darker !important;
		}
	}

	.nb-icons {
		font-size: 14px;
		color: $white !important;
		position: relative;
		top: -4px;
	}
}
