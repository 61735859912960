.fa-circle,
.fa-envelope-o {
	color: $nb-accent !important;
}
.nb-header-icon {
	font-size: 17px !important;
	vertical-align: bottom;
	display: inline-block;
}
.nb-header-icon-selected {
	color: $white;
}

.nb-side-nav-icons {
	font-size: 30px;
}

.nb-sorticon {
	font-size: 10px !important;
	display: inline-block;
}

.material-icons {
	display: inline;
	vertical-align: middle;
}
.material-icons.md-18 {
	font-size: 18px;
}
.material-icons.md-24 {
	font-size: 24px;
}
.material-icons.md-36 {
	font-size: 36px;
}
.material-icons.md-48 {
	font-size: 48px;
}

//SVG Styling

//Put on the surrounding DIV
.liveops-nav {
}
//Put on SVG element
.liveops-nav-item {
	width: 60px;
}
//Put on Circle
.liveops-nav_bg {
	fill: $nb-accent-bright;
}
//Put on icon path
.liveops-nav_icon {
	fill: $white;
}
//Hover state when hovering over the entire SVG
.liveops-nav:hover {
	.liveops-nav_bg {
		fill: $nb-accent;
		transition: 0.2s ease;
	}
	.liveops-nav_icon {
		// transform:scale(.7 , .7);
		// transition: transform .5s ease-out;
		// transform-origin:center;
	}
}

button {
	.nb-information-solid,
	.nb-success-solid,
	.nb-view-on {
		position: relative;
		top: 2px;
	}
}

.prediction-control-icon {
	width: 36px;
	height: 36px;
	margin-bottom: 7px;
	position: relative;
	left: -2px; /* off center it from the others - it actually looks more centered due to the diagonal line - optical illusion */
}
