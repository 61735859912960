//Style navigation
.nb-style-page {
	//Larger Navigation

	.navigation-bar {
		position: sticky;
		top: 46px;
		padding: 0 60px;
		height: auto;
		background-color: $white;
		@include nb-shadow;
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}

		a {
			@include nb-text;
			li {
				border-bottom: 4px solid rgba(0, 0, 0, 0);
				display: inline-block;
				@include nb-padding-right-lg;
				div {
					@include nb-padding-top-sm;
					@include nb-padding-bottom-sm;
				}
			}
			&:hover {
				li {
					border-bottom: 4px solid $nb-accent;
				}
			}
		}
	}

	//Mobile Navigation
	/* The side navigation menu */
	.sidenav {
		height: 100%; /* 100% Full-height */
		width: 0; /* 0 width - change this with JavaScript */
		position: fixed; /* Stay in place */
		z-index: 1; /* Stay on top */
		top: 0;
		left: 0;
		background-color: $white;
		@include nb-shadow;
		overflow: hidden;
		transition: 0.3s; /* 0.5 second transition effect to slide in the sidenav */
	}

	.sidenav-header {
		background-color: $nb-primary-dark;
		height: 130px;
		.logo {
			display: block;
			padding-top: 50px;
			text-align: center;
			img {
				width: 120px;
			}
		}
	}
	.sidenav-body {
		// @include nb-padding-all-md;
	}
	/* The navigation menu links */
	.sidenav {
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}
		a {
			@include nb-text;
			li {
				transition: 0.3s ease-in-out;
				@include nb-padding-bottom-md;
				@include nb-padding-top-md;
				border-left: 4px solid rgba(0, 0, 0, 0);
				padding-left: 15px;
				&:hover,
				&:active {
					color: $nb-accent;
					text-decoration: none !important;
					border-left: 4px solid $nb-accent;
				}
				&:visited,
				&:focus {
					text-decoration: none !important;
					color: $nb-textcolor;
				}
			}
			&:hover,
			&:active {
				color: $nb-accent;
				text-decoration: none !important;
			}
			&:visited,
			&:focus {
				text-decoration: none !important;
				color: $nb-textcolor;
			}
		}
	}
	.sidenav .closebtn {
		position: absolute;
		top: -10px;
		right: 10px;
		font-size: 36px;
		color: $white;
		&:hover,
		&:active {
			color: $nb-accent;
		}
	}
	#main {
		transition: margin-left 0.3s;
	}

	/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
	@media screen and (max-height: 450px) {
		.sidenav {
			padding-top: 15px;
		}
		.sidenav a {
			font-size: 18px;
		}
	}
}
