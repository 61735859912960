// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();


$my-green: (
  50: #00cfa4,
  100: #00cfa4,
  200: #00cfa4,
  300: #00cfa4,
  400: #00cfa4,
  500: #00cfa4,
  600: #00cfa4,
  700: #00cfa4,
  800: #00cfa4,
  900: #00cfa4,
  A100: #00cfa4,
  A200: #00cfa4,
  A400: #00cfa4,
  A700: #00cfa4,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  )
);

$my-black: (
  50: #3d3e40,
  100: #3d3e40,
  200: #3d3e40,
  300: #3d3e40,
  400: #3d3e40,
  500: #3d3e40,
  600: #3d3e40,
  700: #3d3e40,
  800: #3d3e40,
  900: #3d3e40,
  A100: #3d3e40,
  A200: #3d3e40,
  A400: #3d3e40,
  A700: #3d3e40,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  )
);


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$client-primary: mat.m2-define-palette($my-black, A400);
$client-accent: mat.m2-define-palette($my-green, A200, A100, A400);

// The warn palette is optional (defaults to red).
$client-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$client-theme: mat.m2-define-light-theme((
  color: (
    primary: $client-primary,
    accent: $client-accent,
    warn: $client-warn,
  ),
  typography: mat.m2-define-typography-config(),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($client-theme);

/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.6.2
 *
*/




/*@import '../../../../node_modules/@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

@include mat-core();


$app-primary: mat-palette($my-black, A400);
$app-accent:  mat-palette($my-green, A200, A100, A400);

$app-theme:   mat-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
  )
));

// Include the default theme styles (color and default density)
@include angular-material-theme($app-theme);
*/
