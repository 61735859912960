.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
    background: none !important;
}

.mat-mdc-radio-button .mdc-radio {
    padding: calc((var(--mdc-radio-state-layer-size, 40px) - 28px) / 2) !important;
}

.mdc-form-field>label {
    margin-bottom: 0px;
}
