@mixin btn-text {
	font-family: $nb-font !important;
	font-size: 13px !important;
	font-weight: 400;
	letter-spacing: 0.5px;
}

.btn-text {
	@include btn-text;
}

$border-width: 0.1em solid;
$nb-btn-radius: 2px;
$text-white: #fff;

$btn-gray-fill: $nb-neutral-light;
$btn-gray-border: $nb-neutral-dark;

///////////////////////////////////////////////////////
//All buttons

.btn,
.btn:hover,
.btn:active,
.btn:focus {
	//Search bar is 34px high - good for alignment
	box-shadow: none !important;
	transition: 0.2s ease-in-out;
	@include nb-padding-top-xs;
	@include nb-padding-bottom-xs;
	@include nb-padding-left-sm;
	@include nb-padding-right-sm;
	i {
		font-size: 16px;
		vertical-align: middle;
	}
	@include btn-text;
	border-radius: $nb-btn-radius;
}

.btn-xs {
	max-height: 30px !important;
}
///////////////////////////////////////////////////////
//Disabled state for all buttons except Links
.btn:disabled,
.btn-primary:disabled,
.btn-secondary:disabled,
.btn-action:disabled,
.btn-toggle:disabled {
	background-color: $btn-gray-fill !important;
	color: $nb-textcolor !important;
	border: $border-width $btn-gray-border !important;
	.btn-text,
	i {
		opacity: 0.6;
	}
	&:hover {
		background-color: $btn-gray-fill !important;
		color: $nb-textcolor !important;
		border: $border-width $btn-gray-border !important;
		.btn-text,
		i {
			opacity: 0.6;
		}
	}
	&:active {
		background-color: $btn-gray-fill !important;
		color: $nb-textcolor !important;
		border: $border-width $btn-gray-border !important;
		.btn-text,
		i {
			opacity: 0.6;
		}
		&:hover {
			background-color: $btn-gray-fill !important;
			color: $nb-textcolor !important;
			border: $border-width $btn-gray-border !important;
			.btn-text,
			i {
				opacity: 0.6;
			}
		}
	}
	// &:focus {
	//   background-color: $btn-gray-fill !important;
	//   color:$nb-textcolor !important;
	//   border:$border-width $btn-gray-border !important;
	//   .btn-text, i{
	//     opacity:.6;
	//   }
	//   &:hover {
	//     background-color: $btn-gray-fill !important;
	//     color:$nb-textcolor !important;
	//     border:$border-width $btn-gray-border !important;
	//     .btn-text, i{
	//       opacity:.6;
	//     }
	//   }
	// }
}

.btn-primary,
.btn-default,
.btn-secondary,
.btn-action,
.btn-toggle,
.btn-toggle-on {
	height: 34px;
}
///////////////////////////////////////////////////////
//Primary
.btn-primary {
	color: $text-white !important;
	background-color: $nb-accent !important;
	border: $border-width $btn-gray-border !important;
	&:hover {
		color: $text-white !important;
		background-color: $nb-accent-dark !important;
		border: $border-width $btn-gray-border !important;
	}
	&:active {
		color: $text-white !important;
		background-color: $nb-accent-dark !important;
		border: $border-width $btn-gray-border !important;
		&:hover {
			color: $text-white !important;
			background-color: $nb-accent-darker !important;
			border: $border-width $btn-gray-border !important;
		}
	}
	// &:focus {
	//   color:$text-white !important;
	//   background-color:$nb-accent-dark !important;
	//   border:$border-width $btn-gray-border !important;
	//   &:hover {
	//     color:$text-white !important;
	//     background-color:$nb-accent-darker !important;
	//     border:$border-width $btn-gray-border !important;
	//   }
	// }
}
///////////////////////////////////////////////////////
//Secondary
.btn-secondary {
	color: $nb-accent !important;
	background-color: $white !important;
	border: $border-width $nb-accent !important;
	&:hover {
		color: $nb-accent !important;
		background-color: $btn-gray-fill !important;
		border: $border-width $nb-accent !important;
	}
	&:active {
		color: $text-white !important;
		background-color: $nb-accent !important;
		border: $border-width $nb-accent !important;
		&:hover {
			color: $text-white !important;
			background-color: $nb-accent-dark !important;
			border: $border-width $nb-accent-dark !important;
		}
	}
	// &:focus {
	//   color:$text-white !important;
	//   background-color:$nb-accent !important;
	//   border:$border-width $nb-accent !important;
	//   &:hover {
	//     color:$text-white !important;
	//     background-color:$nb-accent-dark !important;
	//     border:$border-width $nb-accent-dark !important;
	//   }
	// }
}
///////////////////////////////////////////////////////
//Toggle Buttons
.btn-toggle {
	color: $nb-textcolor !important;
	background-color: $white !important;
	border: $border-width $btn-gray-border !important;
	&:hover {
		color: $nb-accent !important;
		background-color: $btn-gray-fill !important;
		border: $border-width $btn-gray-border !important;
	}
	&:active {
		color: $text-white !important;
		background-color: $nb-accent !important;
		border: $border-width $nb-accent !important;
		&:hover {
			color: $text-white !important;
			background-color: $nb-accent-dark !important;
			border: $border-width $nb-accent-dark !important;
		}
	}
	// &:focus {
	//   color:$text-white !important;
	//   background-color:$nb-accent !important;
	//   border:$border-width $nb-accent !important;
	//   &:hover {
	//     color:$text-white !important;
	//     background-color:$nb-accent-dark !important;
	//     border:$border-width $nb-accent-dark !important;
	//   }
	// }
}
///////////////////////////////////////////////////////
//Toggle Buttons
.btn-toggle-on {
	color: $text-white !important;
	background-color: $nb-accent !important;
	border: $border-width $nb-accent !important;
	&:hover {
		color: $text-white !important;
		background-color: $nb-accent-dark !important;
		border: $border-width $nb-accent-dark !important;
	}
	&:active {
		color: $text-white !important;
		background-color: $nb-accent !important;
		border: $border-width $nb-accent !important;
		&:hover {
			color: $text-white !important;
			background-color: $nb-accent-dark !important;
			border: $border-width $nb-accent-dark !important;
		}
	}
	// &:focus {
	//   color:$text-white !important;
	//   background-color:$nb-accent !important;
	//   border:$border-width $nb-accent !important;
	//   &:hover {
	//     color:$text-white !important;
	//     background-color:$nb-accent-dark !important;
	//     border:$border-width $nb-accent-dark !important;
	//   }
	// }
}
///////////////////////////////////////////////////////
//Action Buttons
.btn-action {
	color: $nb-textcolor !important;
	background-color: $white !important;
	border: $border-width $btn-gray-border !important;
	&:hover {
		color: $nb-accent !important;
		background-color: $btn-gray-fill !important;
		border: $border-width $btn-gray-border !important;
	}
	&:active {
		color: $nb-accent-dark !important;
		background-color: $white !important;
		border: $border-width $nb-accent-dark !important;
		&:hover {
			color: $nb-accent-darker !important;
			background-color: $btn-gray-fill !important;
			border: $border-width $nb-accent-darker !important;
		}
	}
	&:focus {
		color: $nb-accent-dark !important;
		background-color: $white !important;
		border: $border-width $nb-accent-dark !important;
		&:hover {
			color: $nb-accent-darker !important;
			background-color: $btn-gray-fill !important;
			border: $border-width $nb-accent-darker !important;
		}
	}
}

.btn-action a {
	color: $nb-textcolor !important;
	background-color: $white !important;
	&:hover {
		color: $nb-accent !important;
		background-color: $btn-gray-fill !important;
	}
	&:active {
		color: $nb-accent-dark !important;
		background-color: $white !important;
		&:hover {
			color: $nb-accent-darker !important;
			background-color: $btn-gray-fill !important;
		}
	}
	&:focus {
		color: $nb-accent-dark !important;
		background-color: $white !important;
		&:hover {
			color: $nb-accent-darker !important;
			background-color: $btn-gray-fill !important;
		}
	}
}

///////////////////////////////////////////////////////
//Link Buttons
.btn-link-dark {
	padding: 0 !important;
	color: $nb-textcolor-light !important;
	background: none !important;
	border: none !important;
	&:hover {
		color: $nb-accent !important;
		background: none !important;
		border: none !important;
	}
	&:active {
		color: $nb-accent-dark !important;
		background: none !important;
		border: none !important;
		&:hover {
			color: $nb-accent-darker !important;
			background: none !important;
			border: none !important;
		}
	}
	&:focus {
		color: $nb-accent-dark !important;
		background: none !important;
		border: none !important;
		&:hover {
			color: $nb-accent-darker !important;
			background: none !important;
			border: none !important;
		}
	}
}

.btn-link-dark:disabled {
	background: none !important;
	color: $nb-textcolor !important;
	border: none !important;
	.btn-text,
	i {
		opacity: 0.6;
	}
	&:hover {
		background: none !important;
		color: $nb-textcolor !important;
		border: none !important;
		.btn-text,
		i {
			opacity: 0.6;
		}
	}
	&:active {
		background: none !important;
		color: $nb-textcolor !important;
		border: none !important;
		.btn-text,
		i {
			opacity: 0.6;
		}
		&:hover {
			background: none !important;
			color: $nb-textcolor !important;
			border: none !important;
			.btn-text,
			i {
				opacity: 0.6;
			}
		}
	}
	&:focus {
		background: none !important;
		color: $nb-textcolor !important;
		border: none !important;
		.btn-text,
		i {
			opacity: 0.6;
		}
		&:hover {
			background: none !important;
			color: $nb-textcolor !important;
			border: none !important;
			.btn-text,
			i {
				opacity: 0.6;
			}
		}
	}
}

.ui-select-multiple {
	.close {
		position: relative;
		top: 3px;
	}
	.btn-default {
		height: 30px !important;
	}
}

//
// .delete-button {
//     color: $nb-textcolor-light;
//     -webkit-appearance: none;
//     padding: 0;
//     cursor: pointer;
//     background: 0 0;
//     border: 0;
//     font-size: 21px;
//     line-height: 1;
//     transition: .3s ease-in-out;
//     &:hover {
//       color: $nb-accent;
//     }
// }

///////////////////////////////////////////////////////
// Non-button buttons
.pointer {
	cursor: pointer;
}

nb-filter-button {
	.btn {
		margin: 0 0 0 8px;
		i.fa {
			font-size: 11px;
			position: relative;
			top: -1px;
		}
	}
}
