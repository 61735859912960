// //Forms
//
.nb-label-required {
	@include nb-required;
	float: right;
}

.nb-label-required-minheight {
	min-height: 15px;
}

.required-text {
	font-family: $nb-font;
	@include all-caps;
	color: $nb-accent;
	float: right;
	font-size: 12px;
}

.nb-checkbox-label {
	font-size: 13px;
	font-weight: 400;
	letter-spacing: $smalltext-spacing;
	color: $nb-textcolor;
}

// For situations where a note is necessary (example: "Passwords must include a capital letter, and a number")
.text-note {
	@include nb-text;
	color: $error;
}

// Styling general form labels, and the content inside the input
form {
	label {
		@include nb-label;
	}
	// little help icon in form field labels
	.nb-help-text {
		margin: 0 4px 0 4px;
		cursor: pointer;
	}
	// Clear Field Icon (x)
	.nb-clearfield {
		font-size: 23px;
		position: relative;
		top: -6px;
	}
	// Styling the text inside the inputs - will NOT effect icons
	input:not(i) {
		width: 100%;
		@include nb-text;
		@include nb-padding-all-xs;
		border: $nb-border;
		border-radius: 4px;
	}
}

.nb-form-control-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

// This div container + class combo is the label for both checkboxes and radios
.nb-form-control {
	vertical-align: middle;
	// CHECKBOX
	.nb-checkbox {
		label {
			@include nb-text-sm;
			cursor: pointer;
			// Hide the browser checkbox
			input[type="checkbox"] {
				position: absolute;
				z-index: -1;
				width: 1px;

				&:checked + div.checkbox-label:before {
					//Checkbox - checked
					content: url(/assets/img/nb-checkboxes/nb-checkbox-checked.svg);
				}

				&:disabled + div.checkbox-label:before {
					//Checkbox - disabled
					content: url(/assets/img/nb-checkboxes/nb-checkbox-disabled.svg);
				}
			}

			// Use a psuedo-class of :before to display the image of a checkbox
			div.checkbox-label:before {
				cursor: pointer;
				content: url(/assets/img/nb-checkboxes/nb-checkbox.svg);
				display: inline-block;
				vertical-align: middle;
				width: 18px;
				height: 18px;
				@include nb-margin-right-sm;
				vertical-align: middle;
				margin-bottom: 0px !important;
			}
		}
	}

	// RADIO
	.nb-radio {
		label {
			@include nb-text;
			cursor: pointer;
			// Hide the browser checkbox
			input[type="radio"] {
				position: absolute;
				z-index: -1;
				margin: 6px;
				width: 1px;
				&:focus + div.checkbox-label {
					color: $nb-accent;
					font-weight: bold;
				}

				&:checked + div.checkbox-label:before {
					//Checkbox - checked
					z-index: 5;
					content: url(/assets/img/nb-checkboxes/nb-radio-checked.svg);
				}

				&:disabled + div.checkbox-label:before {
					//Checkbox - disabled
					z-index: 5;
					content: url(/assets/img/nb-checkboxes/nb-radio-checked-disabled.svg);
				}
			}

			// Use a psuedo-class of :before to display the image of a checkbox
			div.checkbox-label:before {
				cursor: pointer;
				content: url(/assets/img/nb-checkboxes/nb-radio.svg);
				display: inline-block;
				vertical-align: middle;
				width: 18px;
				height: 18px;
				@include nb-margin-right-sm;
				vertical-align: middle;
				margin-bottom: 0px !important;
			}
		}
	}
}

.nb-form-control-vertical {
	// vertical-align:middle;
	// CHECKBOX
	.nb-checkbox {
		max-width: 50px;

		align-items: center;
		text-align: center;
		display: flex;
		flex-direction: column;

		label {
			@include nb-text-sm;
			cursor: pointer;
			// Hide the browser checkbox
			input[type="checkbox"] {
				position: absolute;
				z-index: -1;
				width: 1px;
				&:focus + div.checkbox-label {
					color: $nb-accent;
					font-weight: bold;
				}

				&:checked + div.checkbox-label:before {
					//Checkbox - checked
					content: url(/assets/img/nb-checkboxes/nb-checkbox-checked.svg);
				}

				&:disabled + div.checkbox-label:before {
					//Checkbox - disabled
					content: url(/assets/img/nb-checkboxes/nb-checkbox-checked-disabled.svg);
				}
			}

			// Use a psuedo-class of :before to display the image of a checkbox
			div.checkbox-label:before {
				position: relative;
				left: 5px;

				cursor: pointer;
				content: url(/assets/img/nb-checkboxes/nb-checkbox.svg);
				display: inline-block;
				vertical-align: middle;
				width: 18px;
				height: 18px;
				@include nb-margin-right-sm;
				vertical-align: middle;
				margin-bottom: 0px !important;
			}

			.vertical-checkbox-label-text {
			}
		}
	}

	// RADIO
	.nb-radio {
		label {
			@include nb-text;
			cursor: pointer;
			// Hide the browser checkbox
			input[type="radio"] {
				position: absolute;
				z-index: -1;
				margin: 6px;
				width: 1px;
				display: none;
				&:focus + div.radio-label {
					color: $nb-accent;
					font-weight: bold;
				}

				&:checked + div.radio-label:before {
					//Checkbox - checked
					z-index: 5;
					content: url(/assets/img/nb-checkboxes/nb-radio-checked.svg);
				}

				&:disabled + div.radio-label:before {
					//Checkbox - disabled
					z-index: 5;
					content: url(/assets/img/nb-checkboxes/nb-radio-checked-disabled.svg);
				}
			}

			// Use a psuedo-class of :before to display the image of a checkbox
			div.radio-label:before {
				cursor: pointer;
				content: url(/assets/img/nb-checkboxes/nb-radio.svg);
				display: inline-block;
				vertical-align: middle;
				width: 18px;
				height: 18px;
				@include nb-margin-right-sm;
				vertical-align: middle;
				margin-bottom: 0px !important;
			}
		}
	}
}

.nb-form-control-centered {
	// vertical-align:middle;
	// CHECKBOX
	.nb-checkbox {
		align-items: center;
		text-align: center;
		display: flex;
		flex-direction: column;

		label {
			@include nb-text-sm;
			cursor: pointer;
			// Hide the browser checkbox
			input[type="checkbox"] {
				position: absolute;
				z-index: -1;
				width: 1px;

				&:checked + div.checkbox-label:before {
					//Checkbox - checked
					content: url(/assets/img/nb-checkboxes/nb-checkbox-checked.svg);
				}

				&:disabled + div.checkbox-label:before {
					//Checkbox - disabled
					content: url(/assets/img/nb-checkboxes/nb-checkbox-disabled.svg);
				}
			}

			div.checkbox-label[disabled] {
				color: grey;
			}

			// Use a psuedo-class of :before to display the image of a checkbox
			div.checkbox-label:before {
				cursor: pointer;
				content: url(/assets/img/nb-checkboxes/nb-checkbox.svg);
				display: inline-block;
				vertical-align: middle;
				width: 18px;
				height: 18px;
				@include nb-margin-right-sm;
				vertical-align: middle;
				margin-bottom: 0px !important;
			}
		}
	}

	// RADIO
	.nb-radio {
		label {
			@include nb-text;
			cursor: pointer;
			// Hide the browser checkbox
			input[type="radio"] {
				position: absolute;
				z-index: -1;
				margin: 6px;
				width: 1px;
				&:focus + div.radio-label {
					color: $nb-accent;
					font-weight: bold;
				}

				&:checked + div.radio-label:before {
					//Checkbox - checked
					z-index: 5;
					content: url(/assets/img/nb-checkboxes/nb-radio-checked.svg);
				}

				&:disabled + div.radio-label:before {
					//Checkbox - disabled
					z-index: 5;
					content: url(/assets/img/nb-checkboxes/nb-radio-checked-disabled.svg);
				}
			}

			// Use a psuedo-class of :before to display the image of a checkbox
			div.radio-label:before {
				cursor: pointer;
				content: url(/assets/img/nb-checkboxes/nb-radio.svg);
				display: inline-block;
				vertical-align: middle;
				width: 18px;
				height: 18px;
				@include nb-margin-right-sm;
				vertical-align: middle;
				margin-bottom: 0px !important;
			}
		}
	}
}

.form-group {
	.nb-form-control {
		@include nb-margin-right-md;
	}
}
