.form-control {
	border: $nb-form-border;
	@include nb-text;
}

.nb-agencyselect-btn {
}

@mixin nb-required-text {
	font-family: $nb-font;
	font-size: 12px;
	letter-spacing: 1px;
	text-transform: uppercase;
	align-items: baseline;
	font-weight: 400;
}

.nb-required-text {
	@include nb-required-text;
}

@mixin nb-required {
	@include nb-required-text;
	position: relative;
	top: 6px;
	color: $nb-accent-dark;
	float: right;
}

.nb-required {
	@include nb-required;
}

.nb-required-events {
	@include nb-required-text;
	position: relative;
	top: 16px;
	color: $nb-accent-dark;
	float: right;
}
.nb-label-events {
	display: block;
	float: left;
	padding-top: 16px;
}

@mixin nb-label-events-div {
	padding-left: 25px;
	padding-right: 25px;
}

.nb-label-events-div {
	@include nb-label-events-div;
}

.nb-agency-filter-settings {
	@include nb-label-events-div;
	padding-top: 35px;
}

.has-error .help-block {
	@include nb-required-text;
	color: $error;
}
.help-block {
	@include nb-required-text;
}

.form-group {
	@include nb-margin-bottom-md;
}
.nb-field,
.form-group {
	label {
		@include nb-label;
	}

	.nb-required-label {
		color: $nb-accent;
		float: right;
	}

	input {
		@include nb-margin-bottom-sm;
		@include nb-text;
	}

	.ng-invalid.ng-touched {
		border-color: $error;
	}

	.form-control {
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.nb-input-character-count {
		text-align: right;
		font-size: 0.7em;
		vertical-align: text-top;
		margin-top: -0.2em;
	}
}
//Forms

.form-control {
	background-color: $white;
}

.form-control:focus {
	border-color: $nb-accent !important;
}

.nb-unselectable {
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* form with input fields that have error messages*/
form {
	&.has-error-messages {
		.form-group {
			margin: 0;
			min-height: 90px;
		}
		input {
			margin-bottom: 0;
		}
	}
}

nb-authority-prediction-accuracy {
	.authority-prediction-accuracy-row {
		@include flex;
		justify-content: space-between;
		background: #eee;
		margin: 0 auto;
		max-width: 500px;
		padding: 1% 2%;

		&.labels {
			background: none;
			color: #ccc;
		}

		& > div {
			&:first-child {
				@include flex;
				align-items: center;
				justify-content: center;
				width: 180px;
			}
		}
	}
}

.nb-route-not-default-options {
	font-style: italic;
}
