//-----------------------------------------------------
// Navigation

// top navigation
.nb-navbar {
	background: $nb-primary-darker;
	margin-bottom: 0;
	min-width: 460px;
	z-index: 4000;
}

// left navigation
.nb-left-nav {
	font-weight: 500;
	padding-top: 5px;
	.nav {
		li {
			a {
				font-weight: 500;
				color: $nb-primary-light;
				&:hover,
				&:focus {
					background-color: $nb-primary-darker;
					color: white;
				}

				.nb-navicon {
					margin-top: 10px;
					margin-bottom: 5px;
					text-align: center;
					margin-left: -4px;
				}

				.nav-label {
					font-family: $nb-font;
					text-align: center;
					font-weight: 400;
					letter-spacing: 0.6px;
					display: inline-block;
					width: 107px;
				}

				.label-primary,
				.badge-primary {
					background-color: $nb-accent;
					color: #ffffff;
				}

				i.nb-side-nav-icons {
					display: block;
					margin: 0;
				}
			}
		}

		li.active {
			background: $nb-primary-darker;
			border-left: 4px solid $nb-accent-bright;

			a {
				color: white;
			}
		}
	}

	.nb-side-nav {
		text-align: center;
		display: flex;
		float: left;
	}

	.nb-side-nav-flex {
		display: flex;
	}

	.nb-side-nav-flex-column {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding-left: 19px;
	}

	// agency selector button and modal
	.nav-agency-select-panel {
		font-family: $nb-font;
		font-size: 14px;
		line-height: 15px;
		color: $nb-primary-light;
		background-color: $nb-primary-dark;
		width: 100%;
		text-align: center;
		padding-top: 12px;
		padding-bottom: 12px;
		display: inline-block;
		border-bottom: 1px solid $nb-primary-light;
		:hover {
			background-color: $nb-primary-darker;
		}
	}
	.nav-agency-select-panel-icon {
		font-size: 18px;
		line-height: 15px;
		display: inline-block;
	}

	.nav-agency-multiselect {
		font-family: $nb-font;
		font-size: 13px;
		color: $nav-text-color;
		background-color: Transparent;
		background-repeat: no-repeat;
		border: none;
		cursor: pointer;
		overflow: hidden;
		outline: none;
		width: 100%;
		text-align: left;
		margin-top: 3px;
		padding-bottom: 12px;
		padding-left: 25px;
	}

	.nav-agency-multiselect-group {
		width: 100%;
		margin-bottom: 8px;
	}

	.nav-agency-multiselect-options-box {
		margin-left: 20px;
		color: $nav-text-color;
		background-color: $nav-bg;
	}

	.nav-agency-multiselect-counter {
		font-size: 10px;
		padding: 3px 5px;
	}

	.nb-modal-agency-selector {
		max-height: 600px;
	}

	.nb-modal-agency-selector-body {
		padding-top: 8px;
		min-height: 325px;
		max-height: 425px;
	}
}
