.nb-message-panel-header {
	border-bottom: $nb-border;
	@include nb-padding-all-sm;
	min-width: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.status-icon {
		width: 48px;

		i {
			font-size: 20px;
		}
	}

	.header-text-group {
		min-width: 0;
		display: flex;
		width: 100%;
		flex-direction: row;
		justify-content: space-between;
	}
}

.nb-message-panel-expanded {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	@include nb-padding-all-sm;
}
