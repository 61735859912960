@import "./styleguide/nb-styleguide-navigation.scss";

$code-snippet-bg: #3b485e;
$code-comment-color: #6d7a8e;

.style-section-title {
	@include nb-padding-top-lg;
	position: relative;
	right: 20px;
	span {
		@include nb-heading;
		font-size: 26px;
	}
}
.style-block-title {
	@include nb-margin-bottom-md;
	span {
		@include nb-subhead;
		font-size: 16px;
	}
}
.nb-code-snippet {
	max-width: 60%;
	border: $nb-border;
	background-color: $code-snippet-bg;
	border-radius: 10px;
	.code-title-bar {
		@include nb-padding-all-sm;
		border-bottom: 1px solid $code-comment-color;
		.code-title {
			@include nb-section-title;
			color: $code-comment-color;
		}
		i {
			color: white;
			font-size: 16px;
		}
	}
	.code-block {
		@include nb-padding-all-md;
		code {
			color: $nb-accent !important;
			background-color: $code-snippet-bg;
			.comment {
				color: $code-comment-color;
			}
			.code-text {
				color: white;
			}
		}
	}
}

.hidden {
	display: none;
}

.no-animate {
	-webkit-transition: none !important;
	transition: none !important;
}

.nb-style-page {
	font-family: $nb-font;
	.title {
		@include nb-section-title;
		@include nb-margin-bottom-sm;
		@include nb-margin-top-sm;
		color: $nb-textcolor-light;
	}

	body {
		padding-top: 0 !important;
		margin: 0 !important;
		background-color: $nb-neutral;
	}

	//nextbus logo, mobile navigation trigger and "last-edited" message
	.header {
		position: fixed;
		background-color: $nb-primary-darker;
		width: 100%;
		@include nb-padding-all-sm;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		img {
			width: 120px;
		}
		.header-left {
			display: flex;
			.open-nav {
				@include nb-margin-left-sm;
				position: relative;
				top: 5px;
				&:hover {
					.nb-icons {
						color: $nb-accent;
					}
					cursor: pointer;
				}
			}
		}
		.last-edited {
			padding-top: 9px;
			@include nb-helper-text;
			color: $nb-primary-light;
		}
	}

	.intro-block {
		background-color: $nb-primary-dark;
		padding: 50px 20px;
		h1 {
			@include nb-heading;
			font-size: 50px;
			color: $white;
		}
		h2 {
			@include nb-heading;
			color: $nb-primary-light;
			font-weight: normal;
			letter-spacing: 0.8px;
			@include nb-padding-bottom-md;
		}
		p {
			@include nb-text;
			color: $white;
			line-height: 25px;
			font-weight: 300;
		}
	}

	.content {
		padding: 0 20px;
		.block {
			h3 {
				@include nb-heading;
				font-size: 24px;
				@include nb-margin-bottom-lg;
			}
			@include nb-padding-bottom-md;
			@include nb-padding-top-md;
			border-bottom: $nb-border;
		}

		.color-section {
			display: flex;
			justify-content: space-between;
			.color-group {
				flex: 1 1 0;
				.title {
					@include nb-section-title;
					@include nb-margin-bottom-sm;
					@include nb-margin-top-sm;
					color: $nb-textcolor-light;
				}
				.swatch-section {
					display: flex;
					flex-direction: column;

					.swatch-block {
						display: flex;
						@include nb-margin-bottom-lg;
						.swatch {
							height: 50px;
							width: 50px;
							border: $nb-border;
						}
						.swatch-info {
							flex: 1;
							@include nb-padding-left-md;
							div {
								@include nb-padding-bottom-xs;
							}
						}
					}
				}
			}
		}

		.adherence-color-group {
			.swatch-section {
				@include nb-margin-bottom-lg;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				.swatch-block {
					display: flex;
					flex-direction: row;
					@include nb-margin-right-lg;
					.swatch {
						height: 50px;
						width: 50px;
						border: $nb-border;
					}
					.swatch-info {
						flex: 1;
						@include nb-padding-left-sm;
					}
				}
			}
		}
		.typography-section {
			.title {
				@include nb-section-title;
				@include nb-margin-bottom-sm;
				@include nb-margin-top-sm;
				color: $nb-textcolor-light;
			}
			.typeface {
				.description {
					@include nb-margin-bottom-md;
				}

				.examples {
					// background-color: $white;
					@include nb-padding-all-sm;
					// border-radius:$nb-border-radius;
					@include nb-margin-bottom-lg;
				}

				.light,
				.normal,
				.bold {
					font-size: 17px !important;
					@include nb-margin-bottom-md;
				}
				.light {
					@include nb-text;
					font-weight: 300;
				}
				.normal {
					@include nb-text;
					font-weight: 400;
				}
				.bold {
					@include nb-text;
					font-weight: 500;
				}
			}

			.text-styles {
				.examples {
					// background-color: $white;
					@include nb-padding-all-sm;
					// border-radius:$nb-border-radius;
					div {
						@include nb-margin-bottom-sm;
					}
				}
			}
		}

		.date-formatting-section {
			display: flex;
			flex-direction: column;

			.date-section {
				display: flex;
				@include nb-margin-bottom-md;

				.time-example {
					@include nb-margin-right-xl;
					.desc {
						@include nb-text;
					}
					.example {
						@include nb-text;
						width: max-content;
						background-color: $white;
						border-radius: $nb-border-radius;
						@include nb-padding-all-xs;
					}
				}
			}
		}

		.iconography-section {
			display: flex;
			flex-wrap: wrap;

			.icon-item {
				cursor: pointer;
				flex: 1 1 0;
				@include nb-margin-all-lg;
				text-align: center;
				min-width: 100px;
				i {
					color: $nb-textcolor-light !important;
					font-size: 30px !important;
				}
				div {
					@include nb-text;
				}
				&:hover {
					i,
					div {
						color: $nb-accent !important;
					}
				}
			}
		}

		.button-section {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
			.button-type {
				flex: 1 1 0;
				min-width: 200px;
				@include nb-margin-right-md;
				@include nb-margin-bottom-md;
				.title {
					@include nb-section-title;
					color: $nb-textcolor-light;
					@include nb-padding-bottom-sm;
				}
				.buttons {
					@include nb-padding-bottom-sm;
				}
				.description {
					@include nb-text;
				}
			}
		}

		.graphics-section {
			display: flex;
			flex-wrap: wrap;
			.logo-section {
				@include nb-padding-bottom-md;
				.title {
					@include nb-section-title;
					color: $nb-textcolor-light;
					@include nb-padding-bottom-md;
				}
				width: 100%;
				img {
					max-width: 200px;
					@include nb-padding-right-md;
				}
				.avatar {
					max-width: 80px;
				}
			}
			.dark-bg-example {
				background-color: $nb-primary-dark;
				@include nb-padding-all-md;
				@include nb-margin-bottom-md;
			}
			.graphics-group {
				flex: 1 1 0;
				min-width: 200px;
				@include nb-margin-bottom-md;
				.title {
					@include nb-section-title;
					color: $nb-textcolor-light;
					@include nb-padding-bottom-md;
				}
				.map {
					img {
						width: 30px;
						@include nb-padding-right-sm;
					}
				}
				.thumbnails {
					img {
						@include nb-padding-right-sm;
						@include nb-margin-bottom-md;
					}
				}
			}
		}

		.input-section {
			display: flex;
			flex-direction: column;
			.input-checkboxes {
				flex: 1;
			}
		}
	}

	.map-label-section {
		width: 100%;
		display: flex;
		.nb-map-stop-label {
			@include nb-margin-right-md;
		}
	}

	.map-icon-section {
		display: flex;
		flex-wrap: wrap;
		.map-example {
			@include nb-margin-right-md;
			@include nb-margin-bottom-md;
			// background-size: 500px 500px;
			background-position: right top;
			height: 150px;
			width: 300px;
		}

		.styleguide-vehicle-icon {
			position: relative;
			top: 31px;
			left: 141px;
			transform: rotate(81deg);
		}
		.styleguide-vehicle-icon-active {
			position: relative;
			top: 45px;
			left: 215px;
			transform: rotate(-10deg);
		}

		.styleguide-stop-icon {
			position: relative;
			top: 48px;
			left: 80px;
		}
		.styleguide-stop-icon-active {
			position: relative;
			top: 62px;
			left: 216px;
		}

		.styleguide-hidden-stop-icon {
			position: relative;
			top: 47px;
			left: 166px;
		}
		.styleguide-hidden-stop-icon-active {
			position: relative;
			top: 23px;
			left: 90px;
		}

		.light-map {
			background-image: url("/img/StyleGuide/light-map.png");
		}
		.dark-map {
			background-image: url("/img/StyleGuide/dark-map.png");
		}
		.street-map {
			background-image: url("/img/StyleGuide/street-map.png");
		}
		.satellite-map {
			background-image: url("/img/StyleGuide/satellite-map.png");
		}
		.traffic-map {
			background-image: url("/img/StyleGuide/traffic-map.png");
		}
	}

	.adherence-color-section {
		display: flex;
		.example-grouping {
			div {
				@include nb-margin-right-md;
				@include nb-margin-bottom-sm;
			}
		}

		.adherence-pill-example {
			border-radius: 3px;
			@include nb-padding-all-xs;
			color: white !important;
			@include nb-text;
			width: max-content;
		}

		.adherence-text-example-white {
			@include nb-padding-all-xs;
			background-color: white;
		}

		.adherence-text-example-neutral {
			@include nb-padding-all-xs;
			background-color: $nb-neutral;
		}
	}

	#snackbar {
		@include nb-text;
		visibility: hidden; /* Hidden by default. Visible on click */
		min-width: 250px; /* Set a default minimum width */
		margin-left: -125px; /* Divide value of min-width by 2 */
		background-color: $nb-accent; /* Black background color */
		color: #fff; /* White text color */
		text-align: center; /* Centered text */
		border-radius: 2px; /* Rounded borders */
		padding: 16px; /* Padding */
		position: fixed; /* Sit on top of the screen */
		z-index: 1; /* Add a z-index if needed */
		left: 50%; /* Center the snackbar */
		bottom: 30px; /* 30px from the bottom */
	}

	/* Show the snackbar when clicking on a button (class added with JavaScript) */
	#snackbar.show {
		visibility: visible; /* Show the snackbar */

		/* Add animation: Take 0.5 seconds to fade in and out the snackbar.
However, delay the fade out process for 2.5 seconds */
		-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
		animation: fadein 0.5s, fadeout 0.5s 2.5s;
	}

	/* Animations to fade the snackbar in and out */
	@-webkit-keyframes fadein {
		from {
			bottom: 0;
			opacity: 0;
		}
		to {
			bottom: 30px;
			opacity: 1;
		}
	}

	@keyframes fadein {
		from {
			bottom: 0;
			opacity: 0;
		}
		to {
			bottom: 30px;
			opacity: 1;
		}
	}

	@-webkit-keyframes fadeout {
		from {
			bottom: 30px;
			opacity: 1;
		}
		to {
			bottom: 0;
			opacity: 0;
		}
	}

	@keyframes fadeout {
		from {
			bottom: 30px;
			opacity: 1;
		}
		to {
			bottom: 0;
			opacity: 0;
		}
	}

	//////////////////////////////////////////////////////
	//MEDIA QUERIES///////////////////////////////////////
	//////////////////////////////////////////////////////

	// Viewport sizes less than 576px - phones //
	@media screen and (max-width: 576px) {
		.navigation-bar {
			display: none;
		}
	}

	// Viewport sizes between 576px and 768px - large phones //
	@media screen and (min-width: 576px) and (max-width: 768px) {
		.navigation-bar {
			display: none;
		}
	}

	// Viewport sizes between 768 and 992px - tablets / small screen widths //
	@media screen and (min-width: 768px) and (max-width: 992px) {
		#mySidenav,
		.open-nav {
			display: none;
		}

		.color-section {
			.swatch-section {
				flex-direction: row !important;
				flex-wrap: wrap;
				justify-content: flex-start;
				.swatch-block {
					min-width: 200px;
					max-width: 250px;
					@include nb-margin-all-md;
				}
			}
		}

		.typography-section {
			display: flex;
			.typeface {
				max-width: 50%;
				@include nb-margin-right-lg;
			}

			.text-styles {
				max-width: 50%;
			}
		}
	}
	// Viewport sizes over 992px - large screens //
	@media screen and (min-width: 992px) {
		#mySidenav,
		.open-nav {
			display: none;
		}

		.intro-block {
			padding: 60px;
		}
		.content {
			max-width: 1400px;
			padding-left: 20%;
		}

		.typography-section {
			display: flex;
			justify-content: space-between;
			.typeface {
				max-width: 50%;
				@include nb-margin-right-lg;
			}

			.text-styles {
				max-width: 50%;
			}
		}
	}
}
