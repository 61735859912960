/////////////////
// LOADING BAR

$loader-height: 7px;
$loader-width: 100%;

$loader-bg-color: $nb-neutral-light;
$loader-bar-color: $nb-accent;

$loader-text-color: $nb-accent;

/////////////////

.loader {
	height: $loader-height;
	width: $loader-width;
	border-radius: 3em;
	position: relative;
	overflow: hidden;
	background-color: $loader-bar-color;
}
.loader:before {
	display: block;
	position: absolute;
	content: "";
	left: -200px;
	width: 200px;
	height: $loader-height;
	background-color: $loader-bg-color;
	animation: loading 2s linear infinite;
}

@keyframes loading {
	from {
		left: -200px;
		width: 30%;
	}
	50% {
		width: 30%;
	}
	70% {
		width: 70%;
	}
	80% {
		left: 50%;
	}
	95% {
		left: 120%;
	}
	to {
		left: 100%;
	}
}

.loader-text {
	width: $loader-width;
	text-align: center;
	.text {
		@include nb-text;
		color: $loader-text-color;
	}
}

/////////////////
// LOADING CIRCLE
