.trigger-box:hover,
.triggerSelected {
	background: #addfff; /* make this whatever you want */
}

.stop-hover {
	background: rgba(0, 0, 0, 0.26);
	color: #fff;
}

.stop-hover-bg {
	padding: 5px;
	font-size: 16px;
	background: rgba(0, 0, 0, 0.6);
	color: #fff;
}

.width-57-percent {
	width: 57%;
}

.trigger-box {
	height: 40px;
	font-family: "Roboto", sans-serif;
}

.trigger-container {
	margin-left: initial;
	margin-right: initial;
}

.bs-loading-container {
	position: relative;
}

.trigger-table {
	margin: 0px;
	padding: 10px;
	height: 180px;
	overflow-y: auto;
	background: #f4f4f4;
	font-family: "Roboto", sans-serif;
}

.trigger-heading {
	text-align: center;
	font-size: 40px;
	color: #ccc;
}

.trigger-table-head {
	font-weight: 600;
	height: 40px;
	font-family: "Roboto", sans-serif;
}

.trigger-point-details {
	margin: 0px;
	padding: 10px;
	height: 50px;
	font-size: 25px;
	font-weight: 400;
	font-family: "Roboto", sans-serif;
}

.trigger-add {
	text-align: center;
	height: 40px;
}

.leaflet-row {
	margin: 0px;
	padding: 10px;
}

.stop-details {
	position: absolute;
	bottom: 0;
	right: 0;
	height: 265px;
	width: 450px;
	padding: 20px;
	z-index: 99999;
	background: #fff;
}

.layer-btns {
	width: 110px;
	position: absolute;
	z-index: 999999;
	right: 40px;
	background: #fff;
}

.margin-bottom-10 {
	margin-bottom: 10px;
}

.closePopup {
	position: absolute;
	top: 2px;
	right: 12px;
}

.stop-details-footer {
	border-top: 1px solid #ccc;
	text-align: center;
}

.icon-hover:hover {
	color: #0a6aa1;
}

.leaflet-container img {
	background: none !important;
}

.trigger-del {
	display: none;
}
.leaflet-control-zoom {
	display: block !important;
}
.highlight-stop {
	z-index: 9999999999 !important;
}
.stopName {
	font-size: 20px;
	font-weight: 500;
}
.heading {
	font-weight: 600;
}
.stopDir {
	font-size: 16px;
}
.ui-select-placeholder {
	display: block !important;
}
