//Component color variables
$nb-border-color: $nb-neutral-dark;
$nb-page-bg-color: $nb-neutral;
$toolbar-gray-color: $nb-neutral-light;

$nb-table-hover-color: $nb-primary-lighter;
$nb-table-border-color: $nb-neutral;

$nb-information: $nb-neutral-dark;
$nb-danger: $reallybad;
$nb-warning: $bad;

.bg-bad {
	background-color: $bad;
}
.bg-bad-caution {
	background-color: $bad-caution;
}
.bg-acceptable {
	background-color: $acceptable;
}
.bg-reallybad {
	background-color: $reallybad;
}
.bg-reallybad-caution {
	background-color: $reallybad-caution;
}

//Map Detail color settings
//assignments
$assigned: $acceptable;
$blocks: $nb-textcolor-light;
$withissues: $bad;

//adherence
$veryearly: $reallybad;
$early: $reallybad-caution;
$ontime: $acceptable;
$late: $bad-caution;
$verylate: $bad;

.bg-very-late {
	background-color: $verylate;
}
.bg-late {
	background-color: $late;
}
.bg-acceptable {
	background-color: $acceptable;
}
.bg-early {
	background-color: $early;
}
.bg-very-early {
	background-color: $veryearly;
}

.text-very-late {
	color: $verylate;
}
.text-late {
	color: $late;
}
.text-acceptable {
	color: $acceptable;
}
.text-early {
	color: $early;
}
.text-very-early {
	color: $veryearly;
}

.text-very-distant {
	color: $bad;
}
.text-distant {
	color: $bad-caution;
}
.text-close {
	color: $reallybad-caution;
}
.text-very-close {
	color: $reallybad;
}

//vehicle status
$ok: $acceptable;
$vehicles: $nb-textcolor-light;

//Forms
$error: $reallybad;
$nb-error: $reallybad;

.bg-primary-lighter {
	background-color: $nb-primary-lighter;
}
.bg-primary-light {
	background-color: $nb-primary-light;
}
.bg-primary {
	background-color: $nb-primary;
}
.bg-primary-dark {
	background-color: $nb-primary-dark;
}
.bg-primary-darker {
	background-color: $nb-primary-darker;
}

.bg-accent {
	background-color: $nb-accent;
}
.bg-accent-dark {
	background-color: $nb-accent-dark;
}
.bg-accent-darker {
	background-color: $nb-accent-darker;
}

.bg-neutral-light {
	background-color: $nb-neutral-light;
}
.bg-neutral {
	background-color: $nb-neutral;
}
.bg-neutral-dark {
	background-color: $nb-neutral-dark;
}

.bg-textcolor {
	background-color: $nb-textcolor;
}
.bg-textcolor-light {
	background-color: $nb-textcolor-light;
}
.bg-textcolor-lighter {
	background-color: $nb-textcolor-lighter;
}

$nb-neutral-green: #00d9ac;
