// Removes the blue ring that browsers provide for focus
:focus {
	outline: 0px !important;
}

// Overrides Google Chrome's yellow fill on auto-fills
input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 1000px $nb-neutral-light inset !important;
}

.nb-unselectable {
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

input[type="file"] {
	background-color: transparent !important;
	border: $nb-border;
}

input[required="required"] {
	box-shadow: none;
}
