.nb-chart-tooltip-container {
	background-color: $white;
}

.nb-chart-tooltip-title {
	padding-top: 4px;
	text-align: center;
	width: 100%;
}

.tick line {
	visibility: hidden;
}
.nv-y .tick:first-child line {
	visibility: visible;
	stroke: #384452;
}

.nb-map-vehicle-chart-marker {
	font-size: 8px;
}

.ng-chart-tooltip-adherence-summary-table {
}

.ng-chart-tooltip-adherence-summary-table-tr {
	border: 1px solid $nb-border-color;
	padding: 0;
}

.ng-chart-tooltip-adherence-summary-table-td {
	background-color: $nb-neutral-light;
	border: 1px solid $nb-border-color;
	padding: 0;
	min-width: 40px;
	text-align: center !important;
}

.nb-chart-vehicle-capacity-bar {
	background: $nb-border-color;
	height: 4px;
	width: 60px;
	position: relative;
	& > div {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
	}
}

vehicle-capacity-bar {
	background: #ccc;
	display: block;
	height: 4px;
	margin-top: 4px;
	width: 71px;

	div {
		height: 100%;
	}
}

#dottedLineChart .nv-point {
	stroke-opacity: 1 !important;
	stroke-width: 1px !important;
	fill-opacity: 1 !important;
}

#dottedLineChart .nv-scatterWrap {
	display: inherit;
}

.nv-scatterWrap {
	display: none;
}

svg.nvd3-svg {
	height: auto !important;
}
