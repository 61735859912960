@import "nb-styles/nb-wcag-overrides.scss";
@import "nb-styles/nb-overrides.scss";
@import "nb-styles/nb-placeholders.scss";
@import "nb-styles/nb-dashboard.scss";
@import "nb-styles/nb-admintools.scss";
@import "nb-styles/nb-icons.scss";
@import "nb-styles/nb-interfaceelements.scss";
@import "nb-styles/nb-login.scss";
@import "nb-styles/nb-mapdetails.scss";
@import "nb-styles/nb-map-icons.scss";
@import "nb-styles/nb-mapdetails-tables.scss";
@import "nb-styles/nb-charts.scss";
@import "nb-styles/nb-header.scss";
@import "nb-styles/nb-navigation.scss";
@import "nb-styles/nb-subpages.scss";
@import "nb-styles/nb-tables.scss";
@import "nb-styles/nb-userview.scss";
@import "nb-styles/nb-i-check.scss";
@import "nb-styles/nb-typeahead.scss";
@import "nb-styles/nb-dashboard.scss";
@import "nb-styles/nb-widgets.scss";
@import "nb-styles/nb-material-icon-font.scss";
@import "nb-styles/nb-animations.scss";
@import "nb-styles/nb-loading-animations.scss";
@import "nb-styles/nb-forms.scss";
@import "nb-styles/nb-padding.scss";
@import "nb-styles/nb-formcontrols.scss";
@import "nb-styles/nb-buttons.scss";
@import "nb-styles/nb-inputgroups.scss";

@import "nb-styles/nb-toastr.scss";
@import "nb-styles/nb-style-page.scss";
@import "directives/directives.scss";
@import "components/components.scss";
@import "modules/modules.scss";
@import "bower_components/bower_components.scss";

@import "../../app/features/dashboard/dashboard-toolbar/dashboard-toolbar.component.scss";

//Global
html {
	font-size: 16px;
}

body {
	background-color: $nb-primary-dark;
	font-family: $nb-font;
	font-size: 14px;
	padding-top: 60px;
	-ms-overflow-style: scrollbar;
}

.inputWidth {
	width: 200px;
}
.widget-btn {
	position: absolute;
	bottom: 5px;
	left: 40%;
}
.margin-bottom-60 {
	margin-bottom: 60px;
}
.ng-hide.ng-hide-animate {
	display: none !important;
}

.ng-animate.no-animate {
	transition: 0s none;
	-webkit-transition: 0s none;
	animation: 0s none;
	-webkit-animation: 0s none;
}
//-----------------------------------
//Misc

@media screen {
	.no-screen {
		display: none !important;
	}

	.only-print {
		display: none !important;
	}
}

@media print {
	.no-print {
		display: none !important;
	}

	.no-screen {
	}

	.do-print {
		display: block;
	}

	.only-print {
		display: block;
	}

	a[href]:after {
		content: none !important;
	}
}

.nb-unselectable {
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

// Footer
.nb-footer {
	//  z-index: 10000;
	height: 0;
}

::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}

// Signs css
.mySignGrid .signs_problems ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.mySignGrid .problmes_content li {
	padding-left: 1em;
	text-indent: -0.7em;
}

.mySignGrid .problmes_content li::before {
	content: "• ";
	color: #00545c; /* or whatever color you prefer */
}

.mySignGridDB .ui-grid-header-cell-label {
	font-size: 10px !important;
	color: #3d3e40 !important;
	letter-spacing: 0.3px !important;
	font-weight: 500 !important;
}

.messageSignsGrid .ui-grid-grid-footer {
	float: left;
	width: 100%;
	margin-top: 10px;
}
.mySignGridDB .ui-grid-column-menu-button .ui-grid-icon-angle-down {
	display: none;
}
.mySignGrid .ui-grid-column-menu-button .ui-grid-icon-angle-down {
	display: none;
}
.messageSignsGrid .ui-grid-column-menu-button .ui-grid-icon-angle-down {
	display: none;
}
.uib-datepicker-popup.dropdown-menu {
	display: block;
	float: none;
	margin: 0;
	position: relative;
	z-index: 1000;
}
