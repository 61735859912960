.liveops-table-body {
	//.nb-padding-all-sm;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	background-color: $toolbar-gray-color;

	.liveops-header-item {
		padding: 0;
		vertical-align: bottom;
		align-items: baseline;
	}
	.liveops-container-fluid {
		height: 100%;
		display: flex;
		flex-direction: column;
		background-color: $white;
		// min-height:100%;
	}
	.liveops-header {
		flex: 0 0 auto;
	}
	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.liveops-footer {
		flex: 0 0 auto;
		//align-self: flex-end;
	}

	.liveops-table-content {
		height: 100%;
		padding-bottom: 65px;
		flex: 1 1 auto;
		position: relative; /* need this to position inner content */
		overflow-y: auto;
	}

	.liveops-table-row-header {
		display: flex;
		display: -webkit-flex;
		flex-direction: row;
		-webkit-flex-direction: row;
		flex-grow: 0;
		-webkit-flex-grow: 0;
		flex-wrap: nowrap;
		-webkit-flex-wrap: nowrap;
		align-items: flex-end;
		overflow-y: hidden;
		overflow-x: hidden;

		.liveops-header-item {
			// height:47px;
			flex-shrink: 0;
			flex-basis: auto;
			align-self: flex-end;
		}
	}

	.liveops-table-row {
		display: flex;
		display: -webkit-flex;
		flex-direction: row;
		-webkit-flex-direction: row;
		flex-grow: 0;
		-webkit-flex-grow: 0;
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		cursor: pointer;
		&:hover {
			background-color: $nb-primary-lighter;
		}
	}

	/*
   * General good-look styles, not mandatory.
   */
	.heading-text {
		@include nb-label;
		line-height: 12px;
	}

	.padding-btm {
		padding-bottom: 7px;
	}

	.liveops-table-row {
		@include nb-padding-top-sm;
		@include nb-padding-bottom-sm;
		border-collapse: collapse;
		border-bottom: $nb-border;
	}

	// Normal, Hover and Active states for column sorting
	.nb-table-col-header {
		border-bottom: 3px solid $nb-border-color;
		padding-bottom: 4px;
		cursor: pointer;
		.nb-icons {
			color: $nb-textcolor-light;
			font-size: 18px;
			padding-top: 2px;
		}
	}
	.nb-table-col-header:hover {
		border-bottom: 3px solid $nb-accent-light;
		padding-bottom: 4px;
	}

	.nb-table-col-header-active {
		cursor: pointer;
		border-bottom: 3px solid $nb-accent;
		padding-bottom: 4px;
		.nb-icons {
			color: $nb-accent;
			font-size: 18px;
			padding-top: 2px;
		}
	}
	.nb-table-col-header-active:hover {
		border-bottom: 3px solid $nb-accent-dark;
		padding-bottom: 4px;
		.nb-icons {
			color: $nb-accent-dark;
		}
	}
}
////////////////////////////////
//Routes
.route-view-condensed {
	width: 10%;
}
.route-view-expanded {
	width: 10%;
}
.route-id-condensed {
	width: 8%;
	min-width: 34px;
}
.route-id-expanded {
	width: 20%;
	min-width: 34px;
}

.route-name-expanded {
	width: 68%;
	min-width: 75px;
}

//Expanded columns
.routes-active-blocks {
	width: 11%;
	min-width: 80px;
}
.routes-assigned-vehicles {
	width: 11%;
	min-width: 90px;
}
.routes-predictability {
	width: 15%;
	min-width: 120px;
}
.routes-adherence {
	width: 13%;
	min-width: 110px;
}

////////////////////////////////
//Stops
.stop-view-condensed {
	width: 10%;
}
.stop-view-expanded {
	width: 10%;
}
.stop-id-condensed {
	width: 20%;
}
.stop-id-expanded {
	width: 20%;
	min-width: 33px;
}
.stop-name-condensed {
	width: 70%;
}
.stop-name-expanded {
	width: 28%;
}
.stop-name-head-expanded {
	width: 26%;
	min-width: 62px;
}

//Expanded columns
.stops-arrival-adherence {
	width: 18%;
	min-width: 89;
}
.stops-departure-adherence {
	width: 15%;
}
.stops-prediction-count {
	width: 13%;
}
.stops-prediction-accuracy {
	width: 15%;
}

////////////////////////////////
//Blocks
.blocks-id-expanded {
	width: 20%;
	min-width: 33px;
}

.blocks-id-condensed {
	width: 20%;
	min-width: 33px;
}

.blocks-time-expanded {
	width: 20%;
	min-width: 33px;
}

.blocks-time-condensed {
	width: 20%;
	min-width: 33px;
}

.blocks-status-expanded {
	width: 20%;
	min-width: 100px;
}

.blocks-status-condensed {
	width: 20%;
	min-width: 33px;
}
.blocks-vehicles-expanded {
	width: 20%;
	min-width: 33px;
}

.blocks-vehicles-condensed {
	width: 20%;
	min-width: 33px;
}

.blocks-trip-id-expanded {
	width: 15%;
	min-width: 33px;
}

.blocks-trip-id-condensed {
	width: 15%;
	min-width: 33px;
}

.blocks-route-expanded {
	min-width: 70px;
}

.blocks-route-condensed {
	min-width: 70px;
}

.blocks-direction-expanded {
	width: 30%;
	min-width: 100px;
}

.blocks-direction-condensed {
	width: 30%;
	min-width: 100px;
}

.blocks-cancel-icon {
	min-width: 20px;
	color: darkred;
	font-size: 13px;
	font-weight: 400;
	letter-spacing: 0.3px;
	line-height: 22px;
}

.blocks-cancel-icon-seperator {
	margin-left: 2px;
}

/* $ STEVE REMOVE */
.future-trip-row-REMOVE {
	background-color: #f7f7f7;
}

/* $ STEVE REMOVE */
.future-trip-text-REMOVE {
	font-style: italic;
}

/* $ STEVE REMOVE */
.active-trip-REMOVE {
	font-weight: bold;
}

////////////////////////////////
//Vehicles
.vehicle-view-condensed {
	width: 10%;
}
.vehicle-view-expanded {
	width: 10%;

	min-width: 25px;
}

.vehicle-id-condensed {
	width: 15%;
}
.vehicle-id-expanded {
	width: 8%;
	min-width: 36px;
}
.vehicle-route-condensed {
	width: 35%;
}
.vehicle-route-expanded {
	width: 17%;
	min-width: 60px;
}
//Need these to prevent the scrollbar from off-centering the content when the scrollable area outgrows the height
.vehicle-route-head-expanded {
	width: 15%;
}

.vehicle-status-condensed {
	width: 40%;
}
.vehicle-status-expanded {
	width: 20%;
}
//Need these to prevent the scrollbar from off-centering the content when the scrollable area outgrows the height
.vehicle-status-head-expanded {
	width: 18%;
}

//Expanded columns
.vehicles-arrival-adherence {
	width: 13%;
}
.vehicles-departure-adherence {
	width: 13%;
}
.vehicles-prediction-count {
	width: 12%;
	min-width: 78 px;
}
.vehicles-prediction-accuracy {
	width: 12%;
	min-width: 81px;
}

.vehicles-agency-name {
	padding-left: 10%;
	margin-top: 0;
	width: 90%;
}

////////////////////////////////
//Vehicle Trips

//LiveOps / Vehicles / Select a Vehicle that is "On Route" / Expand the drawer in the Toolbar of the details page / View Schedule Button /
.vehicle-trip-stopid-condensed {
	width: 20%;
}
.vehicle-trip-sequence-condensed {
	width: 18%;
}
.vehicle-trip-description-condensed {
	width: 62%;
}

.vehicle-trip-stopid-expanded {
	width: 9%;
}
.vehicle-trip-sequence-expanded {
	width: 11%;
}
.vehicle-trip-description-expanded {
	width: 17%;
}

.vehicle-trip-description-head-expanded {
	width: 16%;
}

.vehicle-trip-scheduled-arrival {
	width: 10%;
}
.vehicle-trip-actual-arrival {
	width: 8%;
}
.vehicle-trip-scheduled-departure {
	width: 11%;
}
.vehicle-trip-actual-departure {
	width: 11%;
}
.vehicle-trip-adherence {
	width: 12%;
}
.vehicle-trip-prediction-accuracy {
	width: 11%;
}

.liveops-table-body.is-headway {
	.vehicle-trip-stopid-expanded {
		width: 12%;
	}
	.vehicle-trip-actual-arrival {
		width: 12%;
	}
	.vehicle-trip-actual-departure {
		width: 13%;
	}
}

////////////////////////////////
//Reassign Vehicle
.reassign-vehicle-route {
	width: 20%;
	min-width: 34px;
}
.reassign-vehicle-name {
	width: 75%;
	min-width: 75px;
}

////////////////////////////////
//Reassign Blocks
.reassignblock-block-condensed {
	width: 20%;
	min-width: 33px;
}

.reassignblock-time-condensed {
	width: 20%;
	min-width: 66px;
}

.reassignblock-status-condensed {
	width: 20%;
	min-width: 100px;
}

.reassignblock-reassign-condensed {
	min-width: 83px;
}

.reassignblock-block-expanded {
	width: 20%;
	min-width: 33px;
}

.reassignblock-time-expanded {
	width: 20%;
	min-width: 66px;
}

.reassignblock-status-expanded {
	width: 20%;
	min-width: 100px;
}

.reassignblock-reassign-expanded {
	min-width: 83px;
}
