.mat-mdc-tab-group.mat-primary .mat-ink-bar, .mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #00cfa4;
}

.mat-ink-bar {
    height: 4px !important;
}

/* the following 3 ovverrides ensure tooltips are not cut off within tabs
   Typically within search filters that live rigt at the top of the tab area
*/
.mat-mdc-tab-body-wrapper {
	overflow: visible !important;
}

.mat-mdc-tab-body.mat-mdc-tab-body-active {
	overflow: visible !important;
}

.mat-mdc-tab-body-content {
	overflow: visible !important;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
    flex-grow: 1;
    font-family: Roboto,sans-serif;
    font-size: 13px;
	font-weight: 500;
	letter-spacing: 1px;
	color:#3d3e40 !important;
	border-bottom: 1px solid #dbdbdb;
}

.mdc-tab--active {
	color: #555;
	border: 1px solid #dbdbdb !important;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline, .mdc-tab-indicator__content--underline {
    border-color: #00cfa4 !important;
}

.mdc-tab-indicator .mdc-tab-indicator__content--underline {
    border-top-width: 4px !important;
}
