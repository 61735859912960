@mixin flex {
	display: flex;

	&.space-around {
		justify-content: space-around;
	}

	&.space-between {
		justify-content: space-between;
	}

	&.space-evenly {
		justify-content: space-evenly;
	}

	&.center {
		align-items: center;
		justify-content: center;
	}

	&.wrap {
		flex-wrap: wrap;
	}

	.grow {
		flex-grow: 1;
	}

	.no-grow {
		flex-grow: 0;
	}

	.no-shrink {
		flex-shrink: 0;
	}
}

.flex {
	@include flex;
}
