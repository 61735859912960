//No data or errors - in place of a blank area

@mixin unselectable {
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.unselectable {
	@include unselectable();
}

@mixin no-data {
	width: 100%;
	font-family: $nb-font;
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: 1.3px;
	color: lighten($nb-textcolor, 45%);
	@include unselectable;
}

.no-data {
	@include no-data;
}

.no-data-sm {
	font-family: $nb-font;
	font-size: 12px;
	color: lighten($nb-textcolor, 40%);
	text-transform: uppercase;
	letter-spacing: 1px;
	@include unselectable;
}

.nb-loading-text {
	@include nb-text;
	color: $nb-accent;
}
