$nb-ui-grid-header-border-color: $white;
$nb-ui-grid-header-background-color: $white;
$nb-ui-grid-header-hover-border-color: $nb-table-hover-color;
$nb-ui-grid-border-color: $nb-table-border-color;

/*  Custom Styled Checkbox overrides */

/*

TODO needed???? check sort icons in list headers

.ui-grid-selection-row-header-buttons.ui-grid-row-selected:before {
  content: "\e908" !important;
  font-family: 'nb-icon-font' !important;
  color:$nb-accent;
}

.ui-grid-icon-ok:before {
  content: "\e909" !important;
  font-family: 'nb-icon-font' !important;
  color:$nb-textcolor-light;
}

&.ui-grid-all-selected::before {
  content: "\e908" !important;
  font-family: 'nb-icon-font' !important;
  color:$nb-accent;
  opacity: 1;
}
*/

/*bug fix for ie11 to force the headers to be the correct height. */
.ui-grid-header-canvas {
	max-height: 33px;
}

/*
add modal-body-flex to a modal; note that all of these min-height:0 lines are to fix a bug in firefox
source:https://stackoverflow.com/questions/28636832/firefox-overflow-y-not-working-with-nested-flexbox

*/
.modal-body-flex {
	min-height: 0;

	.modal-body {
		display: flex;
		min-height: 0;

		.flex-grid {
			min-height: 0;
			display: flex;
			flex-direction: column;
			flex: 1 1 auto;
		}

		nb-search-grid {
			min-height: 0;
			display: flex;
			flex: 1 1 auto;
			flex-direction: column;

			.nb-search-grid-inner {
				display: flex;
				flex: 1 1 auto;
				flex-direction: column;
			}

			.nb-search-grid-toolbar {
				height: auto;
			}

			.nb-search-grid-wrapper {
				overflow: auto;
				flex: 1 1 auto;

				.ui-grid {
				}
			}

			.nb-search-grid-paging-toolbar {
				height: auto;
			}
		}
	}
}

.ui-grid-row {
	background-color: $nb-ui-grid-header-background-color;
	cursor: pointer;
	&:hover {
		color: darken(rgb(0, 173, 187), 20%);
		background-color: $nb-primary-lighter;

		.ui-grid-cell {
			background-color: transparent;
		}
	}
}

.ui-grid,
.ui-grid-viewport {
	height: auto !important;
	overflow-y: hidden !important;
	overflow-x: auto !important;
}

.ui-grid {
	background-color: $white;
	border-color: $nb-ui-grid-border-color;
}

/*
all header overrides:
*/

/*
 column header row
*/

.ui-grid-top-panel {
	background: $nb-ui-grid-header-background-color;
}

/*
grid column header cell
*/
.ui-grid-header {
	border-bottom-color: $nb-ui-grid-border-color;
	.ui-grid-header-cell {
		border-color: $nb-ui-grid-header-border-color;
	}
}

/* add the sort styling to the sort column header */
.ui-grid-header-cell div[aria-sort="ascending"],
.ui-grid-header-cell div[aria-sort="descending"] {
	border-bottom: 3px solid;
	border-color: $nb-accent;
}

.ui-grid-header-cell div[aria-sort="none"] {
	border-bottom: 3px solid;
	border-color: transparent;
}

/*
select all checkbox in header
*/

.ui-grid-pinned-container.ui-grid-pinned-container-left
	.ui-grid-header-cell:last-child {
	border-right-color: $nb-ui-grid-header-border-color;

	.ui-grid-cell-contents {
		padding: 0px;

		.ui-grid-selection-row-header-buttons {
			padding: 8px 0px 5px 5px;
		}
	}
}

/*
disables the default browser focus styling
*/
.ui-grid-header-cell div {
	outline: none;
	box-shadow: none;
}

.ui-grid-row .ui-grid-cell.ui-grid-row-header-cell {
	border-right-color: transparent;
	border-bottom: 1px solid $nb-ui-grid-border-color;

	.ui-grid-cell-contents {
		padding: 0px;

		.ui-grid-selection-row-header-buttons {
			padding: 5px 0px 5px 5px;
		}
	}
}

.ui-grid-column-resizer {
	width: 3px;
}

.ui-grid-column-resizer.right {
	border-right: 1px solid $nb-ui-grid-header-background-color;
	&:hover {
		border-color: $nb-ui-grid-header-hover-border-color;
		background-color: #ccc;
	}
}

.ui-grid-header-cell:last-child .ui-grid-column-resizer.right {
	border-right: 1px solid $nb-ui-grid-header-border-color;
	&:hover {
		border-color: $nb-ui-grid-header-hover-border-color;
		background-color: #ccc;
	}
}

/*
this will set a focus style on the header column as you tab through them
*/
.ui-grid-header-cell div:focus {
	background-color: #fafafa;
}

.ui-grid-menu-button {
	height: 33px;
	border-radius: 3px;
	border-right: 1px solid;
	border-top: 1px solid;
	border-bottom: 1px solid;
	border-left: 1px solid;
	border-color: #eaeaea;
	.ui-grid-icon-container {
		background-color: #eaeaea;
		margin: 0;
		padding: 5px 0 6px 4px;
	}
}

.ui-grid-menu-button .ui-grid-menu .ui-grid-menu-mid {
	overflow: hidden;
}
.ui-grid-menu-item {
	&:before {
		padding: -5px 1px -5px 0;
		vertical-align: -15%;
		content: url(/assets/img/AdminToolsThumbnails/file_download_black_24dp.svg) !important;
	}
}

.ui-grid-row .ui-grid-cell.ui-grid-row-header-cell {
	background-color: #ffffff;
}

.ui-grid-selection-row-header-buttons.ui-grid-row-selected {
	color: $nb-accent;
	opacity: 1;

	&:before {
		content: "\f14a";
	}
}

.ui-grid-selection-row-header-buttons {
	opacity: 1;
}

.ui-grid-icon-ok:before {
	content: "\f096";
	font-family: fontawesome;
}

.ui-grid-pinned-container.ui-grid-pinned-container-left
	.ui-grid-cell:last-child {
	border-right-color: $nb-ui-grid-header-border-color;
}

.ui-grid-row .ui-grid-cell.ui-grid-row-header-cell {
	border-bottom-color: $nb-ui-grid-border-color;

	&:hover {
		background-color: $nb-neutral-light;
	}
}

.ui-grid-row.ui-grid-row-selected > [ui-grid-row] > .ui-grid-cell {
	background-color: $nb-primary-lighter;
}

.ui-grid-cell {
	border-color: $nb-ui-grid-border-color;
}

/**
* $section Grid styles
*/
$gridBorderWidth: 1px;
$gridBorderRadius: 0px;
$borderColor: #d4d4d4;

/**
* $section Header styles
*/

/** $description Colors for header gradient */
$headerBackgroundColor: #ffffff;
$headerGradientStart: #fff;
$headerGradientStop: #fff;
$headerVerticalBarColor: $borderColor;

/**
* $section Grid body styles
*/

/** $description Colors used for row alternation */
$verticalBarColor: $borderColor;
$rowColorEven: #ffffff;
$rowColorOdd: #ffffff;

// TODO: colors for buttons

/**
* $section Sort arrow colors
*/

$sortArrowBackgroundColor: #aaaaaa;
$sortArrowBorderColor: #777777;

// TODO: color for menu background
$rowHeaderCell: #f0f0ee;
$rowSelected: #c9dde1;
$rowSavingForeground: #848484;
$rowErrorForeground: #ff0000;
$rowDirtyForeground: #610b38;

// TODO: color for cell selections
$focusedCell: #b3c4c7;

// Color to use for enabled or selected settings/items/cells, etc. Should probably override the one above
$selectedColor: #cecece;

/**
* $section Scrollbar styles
*/
$scrollbarBackground: darken($rowColorEven, 15%);
$scrollbarBackgroundHover: darken($scrollbarBackground, 15%);
$scrollbarBackgroundScrolling: darken($scrollbarBackgroundHover, 15%);
$scrollbarWidth: 10px;
$scrollbarBorderRadius: 2px;
$scrollbarShadow: 0 0 0px #fff;
$scrollbarBorder: 1px solid darken($scrollbarBackground, 15%);
$scrollbarBorderScrolling: 1px solid darken($scrollbarBackgroundScrolling, 15%);

//Border to be applied to editors when the input value or invalid
$invalidValueBorder: 1px solid rgb(252, 143, 143);
$validValueBorder: 1px solid $borderColor;

//Pagination controls
$paginationButtonColor: $headerGradientStart;
$paginationButtonBackgroundColor: $headerBackgroundColor;
$paginationButtonBorderColor: $nb-border-color;

/**
* $section font library path
*/
$font-path: "../../node_modules/angular-ui-grid/fonts/";

/*-- END VARIABLES (DO NOT REMOVE THESE COMMENTS) --*/
