.nb-contact-card-wrap {
	max-width: 500px;
}

.nb-contact-card .contact-box {
	background-color: $white;
	border: $nb-border;

	.nb-none-assigned {
		text-align: center;
		@include no-data;
		padding-top: 20px;
	}
}
