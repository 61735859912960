@mixin border-radius($radius: 3px) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;

	-moz-background-clip: padding;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
}

@mixin opacity($opacity: 0.5) {
	-webkit-opacity: $opacity;
	-moz-opacity: $opacity;
	opacity: $opacity;
}

@mixin nb-map-label-text {
	font-family: $nb-font;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: 0.2px;
	color: $nb-textcolor;
}

@mixin nb-map-label-chip-text {
	font-family: $nb-font;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.2px;
	color: white;
}

@mixin nb-label-meta-data {
	font-family: $nb-font;
	font-size: 12px;
	font-weight: 400;
	color: $nb-textcolor;
	opacity: 0.8;
}
