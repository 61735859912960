.message {
	left: 85px;
	bottom: 220px;
	@include nb-label;
}

.step-line {
	position: relative;
	height: 80px;
	left: 3px;
	border-left: 3px solid;
}

.verticalStopIconBottom {
	position: absolute;
	z-index: 1;
	cursor: pointer;
	height: 8px;
}

.verticalStopIconTop {
	position: absolute;
	z-index: 1000;
	cursor: pointer;
	height: 8px;
}

.vertical-ladder-header1 {
	width: 100%;
	height: 30px;
	border: 1px solid black;
	background-color: chocolate;
}

.vertical-ladder-header2 {
	width: 100%;
	height: 30px;
	border: 1px solid black;
	background-color: silver;
	margin-top: 6px;
	margin-bottom: 15px;
	text-align: center;
}

.vertical-ladder-stop-tooltip {
	position: absolute;
	float: left;
	left: 8px;
	font-size: 12px;
	font-weight: 100;
	margin-top: -1px;
	white-space: nowrap;
	z-index: 9001 !important;
}

.vertical-ladder-stop-tooltip-right {
	position: absolute;
	float: right;
	right: 8px;
	font-size: 9px;
	font-weight: 900;
	margin-top: -1px;
	white-space: nowrap;
	z-index: 9001 !important;
}

.vertical-stop-label-left {
	position: absolute;
	z-index: 0;
	float: left;
	left: 11px;
	font-size: 9px;
	font-weight: 900;
	margin-top: -1px;
	white-space: nowrap;
}

.vertical-stop-label-right {
	position: absolute;
	z-index: 0;
	float: right;
	right: 6px;
	font-size: 9px;
	font-weight: 900;
	margin-top: -1px;
	white-space: nowrap;
}

.vertical-ladder-header1 .label-route.truncate {
	height: 100% !important;
	width: 100% !important;
	padding: 8px 2px 2px 2px;
}

.up-dead-gray-box-vehicle {
	cursor: pointer;
	height: 17px;
	width: 9px;
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
	margin: 5px;
	position: relative;
}

.down-dead-gray-box-vehicle {
	cursor: pointer;
	height: 17px;
	width: 9px;
	-webkit-transform: rotate(360deg);
	-ms-transform: rotate(360deg);
	transform: rotate(360deg);
	margin: 5px;
	position: relative;
}

.ladder-width {
	width: 300px;
}

.half-ladder-width {
	width: 50%;
}

.single-ladder {
	position: relative;
}

.single-ladder-right {
	left: 10px;
}

.single-ladder-left {
	float: right;
}

.center-text {
	text-align: center;
}

.stop-label {
	cursor: pointer;
	//position: absolute;
	height: 17px;
	//width: 9px;
	z-index: 10000;
}
