.nb-form-error {
	border: 1px solid $error;
	background-color: lighten($error, 60%);
	@include nb-padding-all-sm;

	h1 {
		color: $error;
		font-size: 18px;
		margin: 0px 0px $padding-sm 0px;
	}

	p {
		margin: 0px;
		color: $error;
	}

	.close {
	}
}
