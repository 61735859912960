/* selection lists (stops in rider messages/predictions */
.mat-mdc-list-base .mat-mdc-list-item, .mat-list-base .mat-list-option {
    height: 24px !important;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    padding: 0;
    padding-top: 0px;
}

span.mdc-list-item__content {
	position: relative;
	top: -1px;
}
