adf-widget-content {
	table.table {
		th {
			font-size: 13px;
			color: #3d3e40;
			letter-spacing: 0.3px;
			font-weight: 500;
		}
	}
}

.add-dashboard-icon {
	color: $nb-accent;
	font-size: 24px;
	position: relative;
	top: 3px;
	&:hover,
	&:active,
	&:focus {
		color: $nb-accent-dark;
	}
}

.add-dashboard-icon-disabled {
	color: $nb-neutral;
	font-size: 24px;
	position: relative;
	top: 3px;
}

button.btn.btn-dashboard-customize {
	@media (max-width: 480px) {
		&,
		&:hover {
			border: none !important;
			background: none !important;
		}
		span {
			display: none;
		}
	}
}

.nb-editwidget-icon {
	color: $nb-accent;
	&:hover {
		color: $nb-accent-dark;
	}
}

.nb-grey-bg {
	background-color: $white; //$graybg;//
}

@mixin dashboard-title-text {
	.nb-heading {
		transition: $nb-transition;
	}
	font-family: $nb-font;
	font-size: 16px;

	&:hover,
	&:active,
	&:visited {
		.nb-heading {
			color: $nb-accent !important;
		}
	}
}

.dashboard-title-text {
	@include dashboard-title-text;
}

.dashboard-title-btn {
	cursor: pointer;
	@include dashboard-title-text;
	background: none;
	border: none;
	color: $nb-textcolor;

	&:hover,
	&:active,
	&:visited {
		color: $nb-accent;
	}

	.nb-icons {
		font-size: 15px;
		top: 0;
	}
}

.panel-default {
	border-color: $nb-border-color !important;
}
.panel {
	border-radius: $nb-border-radius;
	margin-bottom: 30px;
	background-color: $white;
	border: none; //border:1px solid $nb-border-color !important;
	@include nb-shadow-bottom; /*-webkit-box-shadow:none !important;
  box-shadow:none !important;*/
}

.nb-dashboard-metrics-text-big {
	font-family: $nb-font;
	font-size: 30px;
	color: $nb-primary;
}

.nb-dashboard-metrics-text-med {
	font-family: $nb-font;
	font-size: 18px;
	color: $nb-primary;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
	display: none !important;
}

.widget {
	min-width: 280px;
}

.widget h3 a {
	text-decoration: none;
}

.dashboard-container {
	h1 a {
		text-decoration: none;
	}

	.tooltip-inner {
		white-space: pre-wrap;
		width: 100px;
	}
}

.padding-bottom {
	padding-bottom: 5px;
}

/*Add Widget Modal dialog*/

.db-add-widget {
	background-color: $graybg;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	@include nb-padding-all-sm;
	// justify-content: flex-start;
	// align-items: stretch;
	// align-content: stretch;
	// justify-content: space-around;

	.db-add-widget-item {
		width: 23%;
		padding: 0;
		@include nb-margin-all-sm;
		background-color: $white;
		@include nb-shadow;
		.add-widget-descriptions {
			@include nb-padding-all-sm;
		}
		.db-add-widget-item-thumb {
			background-color: $white;
			.base {
				display: inline;
			}

			.hover {
				display: none;
			}
		}

		&:hover,
		&:focus {
			cursor: pointer;
			background-color: $nb-primary-lighter;

			.base {
				display: none;
			}
			.hover {
				display: inline;
			}
		}
		&:last-of-type {
			margin-right: 0;
		}
	}

	.db-add-widget-item.selected {
		cursor: pointer;
		background-color: $nb-primary-lighter;

		.base {
			display: none;
		}
		.hover {
			display: inline;
		}
	}
}

/*
 modal dialog in fullscreen
*/
.dashboard-modal.widget-fullscreen .modal-dialog {
	width: 98%;
}

.adf-move {
	cursor: move;
	cursor: -webkit-grabbing;
}

.dashboard {
	padding: 8px;

	&.edit {
		.column-wrapper {
			border: 1px #ccc dashed;
			& > div {
				min-height: 130px;
				padding: 0px 0px 48px 0px;
			}
		}

		& > div {
		}
	}

	.column {
		overflow: hidden;
		padding: 3px;
	}

	.column-wrapper {
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		padding: 0;

		& > div {
			padding: 0;
		}

		.btn-add-widget {
			position: absolute;
			bottom: 14px;
			left: 50%;
			transform: translate(-50%, 0);
		}

		.widget-container {
			padding: 4px;
		}
	}

	.row {
		margin: 0;
		padding: 0;
	}
}

pre.edit {
	margin-top: 15px;
}

.column .placeholder {
	opacity: 0.4;
	background: #e8e8e8;
}

.adf-flip {
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
	filter: FlipH;
	-ms-filter: "FlipH";
}

.structure-preview {
	height: 150px;
	width: 200px;
	position: relative;
	cursor: pointer;
}

.structure-preview .column {
	border: 1px $nb-border-color dashed;
	background-color: $nb-neutral-light;
	height: 100%;

	@media (min-width: 992px) {
		//override responsive columns in dashboard structure preview
		&.col-lg-4.col-md-6 {
			width: 33.3333333%;
		}
	}
}

.structure-preview h4 {
	display: none;
}

.structure-preview:hover .column,
.structure-preview.selected .column {
	border: 1px $nb-accent dashed;
	background-color: $nb-primary-lighter;
}
.structure-preview:hover h4,
.structure-preview.selected h4 {
	color: $nb-accent;
}
.nb-add-widget-ctrl {
	background-color: $graybg;
	// border:1px dashed $nb-border-color;
	width: 100%;
	height: 300px;
	@include nb-margin-bottom-md;
}

.nb-add-widget-ctrl-button {
	position: relative;
	top: 150px;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.dashboard {
	background-color: $graybg;
	a {
		color: $nb-accent;
		&:hover {
			color: $nb-accent-dark;
		}
		.addwidget-text {
			font-family: $nb-font;
			font-size: 14px;
			font-weight: 400;
		}
	}
}

.nb-widget-title-container {
	@include nb-padding-all-md;
	background-color: $white;
	//border-bottom:$nb-border;
	border-top-left-radius: $nb-border-radius;
	border-top-right-radius: $nb-border-radius;
}

.nb-widget-title {
	margin-top: 0;
	margin-bottom: 0;
}

.nb-widget-select-row {
	margin-top: 20px;
	margin-left: 20px;
	margin-right: 20px;
}

.nb-widget-container {
	min-height: 70px;
}

.nb-widget-select-button-container {
	padding-bottom: 10px;
}

.nb-widget-select-button {
	width: 155px;
}

.nb-widget-select-description {
	padding-bottom: 10px;
}

.nb-dashboard-chart-container {
	height: 250px;
	// border-bottom: 1px $nb-border-color solid;
	text-align: center;
}

.nb-dashboard-table-container {
}

.nb-dashboard-widget-footer {
	@include nb-padding-top-sm;
	// border-top:$nb-border;
}

.nb-dashboard-chart-title {
	padding-bottom: 20px;
}

.nvd3.nv-pie .nv-pie-title {
	fill: $nb-textcolor !important;
}

.nb-dashboard-details-list {
	margin-bottom: 0.3em;
	margin-top: 0.6em;
	//border-bottom:1px solid $nb-border-color;
	padding-bottom: 1px;
}

.nb-dashboard-details-labels {
	@include all-caps;
	color: $nb-textcolor-light;
	font-size: 11px;
	text-align: right;
	margin-top: 0.3em;
}
.nb-dashboard-details-list-items {
	color: $nb-textcolor;
	font-size: 14px;
	@include all-caps;
}

.nb-dashboard-flex-center {
	display: flex;
	align-items: center;
}
