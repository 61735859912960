.map-block-details-header {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.field-label {
		width: 30%;
		transition: width 0.6s ease;
		font-weight: bold !important;
		padding-left: 4px;
		padding-right: 4px;
		height: 30px;
	}

	.field-value {
		width: 70%;
		transition: width 0.6s ease;
		padding-left: 4px;
		padding-right: 4px;
		height: 30px;
	}
}

.nb-block-assigned-full-width {
	@include label-route;
	background-color: green;
	color: white;
	width: 100%;
	line-height: 12px !important;
	display: inline-block;
}

.nb-block-assigned-full-width a {
	color: white !important;
}

.nb-block-assigned {
	@include label-route;
	background-color: green;
	color: white;
	width: 82px;
	line-height: 12px !important;
	display: inline-block;
}

.nb-block-assigned a {
	color: white !important;
}

.nb-block-unassigned-full-width {
	@include label-route;
	background-color: gray;
	color: white;
	width: 100%;
	line-height: 12px !important;
	display: inline-block;
}

.nb-block-unassigned-full-width a {
	color: white !important;
}

.nb-block-unassigned {
	@include label-route;
	background-color: gray;
	color: white;
	width: 82px;
	line-height: 12px !important;
	display: inline-block;
}

.nb-block-unassigned a {
	color: white !important;
}

.nb-block-canceled-full-width {
	@include label-route;
	background-color: darkred;
	color: white;
	width: 100%;
	line-height: 12px !important;
	display: inline-block;
}

.nb-block-canceled-full-width a {
	color: white !important;
}

.nb-block-canceled {
	@include label-route;
	background-color: darkred;
	color: white;
	width: 82px;
	line-height: 12px !important;
	display: inline-block;
}

.nb-block-canceled a {
	color: white !important;
}

@mixin nb-report-block {
	border-radius: 0.25em;
	text-align: center;
	border: 1px solid black;
	width: 80%;
	line-height: 22px;
	display: block;
	white-space: nowrap;
	overflow-x: hidden;
	text-overflow: ellipsis;
}

.nb-report-block {
	@include nb-report-block;
}

.nb-report-block-assigned {
	@include nb-report-block;
	background-color: #005389;
}

.nb-report-block-assigned a {
	color: white !important;
}

.nb-report-block-upcoming {
	@include nb-report-block;
	background-color: #dbdbdb;
}

.nb-report-block-upcoming a {
	color: black !important;
}

.nb-report-block-missed {
	@include nb-report-block;
	background-color: #bc0000;
}

.nb-report-block-missed a {
	color: white !important;
}

.nb-report-block-unscheduled {
	@include nb-report-block;
	background-color: #ef8d07;
}

.nb-report-block-unscheduled a {
	color: white !important;
}

.nb-report-block-difference-warning a {
	color: red !important;
}

.cancelled-inactive-no-vehicles {
	width: 70%;
	transition: width 0.6s ease;
	color: red;
	@include nb-padding-all-xs;
}

.cancelled-inactive-no-vehicles a {
	color: red;
}

.vehicles-on-block {
	width: 70%;
	transition: width 0.6s ease;
	@include nb-text;
	@include nb-padding-all-xs;
	padding-left: 0px;
}

.default-block {
	width: 70%;
	transition: width 0.6s ease;
	color: black;
	@include nb-padding-all-xs;
}

.default-block a {
	color: black;
}

.nb-block-canceled {
	@include label-route;
	background-color: darkred;
	color: white;
	width: 72px;
}
