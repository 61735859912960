.nb-none-available {
	@include no-data;
	text-align: center;
}

.modal-neutral-bg {
	background: $nb-neutral;
}
//Admin Page Landing
.admintools-thumbnail-group {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.thumbnail-card {
		width: 180px;
		text-align: center;
		background-color: $nb-accent;
		padding: 0;
		@include nb-margin-right-md;
		@include nb-shadow;
		@include nb-margin-bottom-lg;
		transition: 0.3s ease-in-out;
		img {
			max-height: 96.7px;
		}

		.thumbnail-title {
			@include nb-section-title;
			@include nb-padding-all-sm;
			background-color: $white;
		}

		&:hover {
			background-color: $nb-accent-dark;
		}
	}
}

//Details Pages
.nb-admin-details-grid-bg {
	background-color: $white;
	@include nb-padding-all-md;
}

.ui-grid-header-cell:hover {
	background-color: $nb-primary-lighter;
}

.ui-grid-header-cell-label {
	@include nb-label;
}

.ui-grid-row {
	@include nb-label;
}

.nb-role-edit-container {
	@include nb-border-bottom;
	background-color: $toolbar-gray-color;
	height: 51px;
	@include nb-padding-all-sm;
	.header-title-row {
		display: flex;
		width: 100%;
		@include nb-padding-bottom-xs;
		.header-title {
			width: auto;
			@include nb-padding-left-xs;
		}
		.header-backbutton {
			width: auto;
			@include nb-padding-all-xs;
		}
	}
	.header-search-row {
		width: 40%;
		.header-searchbar {
			//width:auto;
			width: 100%;
		}
		.header-menubutton {
			width: auto;
		}
	}
	.header-filter-row {
		display: flex;
		width: 100%;
		.header-searchresults {
			@include nb-margin-right-sm;
			width: 100%;
		}
		.header-filter {
			width: auto;
		}
	}
}
.panel-heading {
	align-items: center;
	border-bottom: 0;
}
.panel-body {
	border-top: $nb-border;
}
.nb-role-edit-panel-header {
	min-height: 40px;
	height: auto;
	background: $white !important;
	& > div:last-child {
		padding-left: 8px;
	}
}
.nb-user-default-agency-container {
	@include nb-margin-top-sm;
	@include nb-margin-bottom-sm;
}
.nb-user-authority-roles-container {
	@include nb-padding-bottom-sm;
	border-bottom: $nb-border;
}
