$adherence-color: #bf1f4a;
$headway-color: #9cb741;
$ridership-color: #2c97d0;
$avl-color: #e3b51d;
$blocks-color: #3fa893;
$configuration-color: #eb7144;
$prediction-accuracy-color: #eb7144;
$disabled-report-color: #666;
$misc-color: #673147;
$adherence-color-dark: darken($adherence-color, 5%);
$headway-color-dark: darken($headway-color, 5%);
$ridership-color-dark: darken($ridership-color, 5%);
$avl-color-dark: darken($avl-color, 5%);
$blocks-color-dark: darken($blocks-color, 5%);
$configuration-color-dark: darken($configuration-color, 5%);
$prediction-accuracy-color-dark: darken($prediction-accuracy-color, 5%);
$misc-color-dark: darken($misc-color, 5%);
$disabled-report-color-dark: darken($disabled-report-color, 5%);
$disabled-report-color-light: lighten($disabled-report-color, 35%);

@mixin nb-card-shadow {
	-webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.4);
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.4);
}

@mixin nb-card-shadow-hover {
	-webkit-box-shadow: -6px 5px 27px 0 rgba(0, 0, 0, 0.3);
	-moz-box-shadow: -6px 5px 27px 0 rgba(0, 0, 0, 0.3);
	box-shadow: -6px 5px 27px 0 rgba(0, 0, 0, 0.3);
}

.nb-report-status-text {
	font-family: $nb-font;
	text-transform: uppercase;
	letter-spacing: 0.3px;
	font-size: 13px;
}

.nb-available-report-dataview {
	background-color: $nb-neutral-light;
	.nb-dataview-inner {
		border: $nb-border;
	}
}

.nb-saved-report-templates-dataview {
	background-color: $nb-neutral-light;
	.nb-dataview-inner {
		border: $nb-border;
	}
}

@mixin adherence-color-bg {
	background-color: $adherence-color;
}

@mixin headway-color-dark-bg {
	background-color: $headway-color-dark;
}

@mixin headway-color-bg {
	background-color: $headway-color;
}

@mixin ridership-color-bg {
	background-color: $ridership-color;
}

@mixin avl-color-bg {
	background-color: $avl-color;
}

@mixin misc-color-bg {
	background-color: $misc-color;
}

@mixin blocks-color-bg {
	background-color: $blocks-color;
}

@mixin configuration-color-bg {
	background-color: $configuration-color;
}

@mixin prediction-accuracy-color-bg {
	background-color: $prediction-accuracy-color;
}

@mixin adherence-color-dark-bg {
	background-color: $adherence-color-dark;
}

@mixin ridership-color-dark-bg {
	background-color: $ridership-color-dark;
}

@mixin avl-color-dark-bg {
	background-color: $avl-color-dark;
}

@mixin misc-color-dark-bg {
	background-color: $misc-color-dark;
}

@mixin blocks-color-dark-bg {
	background-color: $blocks-color-dark;
}

@mixin prediction-accuracy-color-dark-bg {
	background-color: $prediction-accuracy-color-dark;
}

@mixin saved-reports-disable-bg {
	background-color: $disabled-report-color;
}

@mixin saved-reports-disable-dark-bg {
	background-color: $disabled-report-color-dark;
}

@mixin saved-reports-disable-light-bg {
	background-color: $disabled-report-color-light;
}

.group-by-adherence {
	.report-card-header {
		@include adherence-color-bg;
	}
	.report-title-card:hover {
		.report-card-header {
			@include adherence-color-dark-bg;
		}
	}
}

.saved-reports-disabled {
	.report-title-card {
		.report-card-header {
			@include saved-reports-disable-bg;
		}
		.report-content {
			@include saved-reports-disable-light-bg;
		}
		@include saved-reports-disable-light-bg;
	}
	.report-title-card:hover {
		.report-card-header {
			@include saved-reports-disable-dark-bg;
		}
	}
}

.group-by-headway {
	.report-card-header {
		@include headway-color-bg;
	}
	.report-title-card:hover {
		.report-card-header {
			@include headway-color-dark-bg;
		}
	}
}
.group-by-ridership {
	.report-card-header {
		@include ridership-color-bg;
	}
	.report-title-card:hover {
		.report-card-header {
			@include ridership-color-dark-bg;
		}
	}
}
.group-by-avl {
	.report-card-header {
		@include avl-color-bg;
	}
	.report-title-card:hover {
		.report-card-header {
			@include avl-color-dark-bg;
		}
	}
}
.group-by-misc {
	.report-card-header {
		@include misc-color-bg;
	}
	.report-title-card:hover {
		.report-card-header {
			@include misc-color-dark-bg;
		}
	}
}
.group-by-block {
	.report-card-header {
		@include blocks-color-bg;
	}
	.report-title-card:hover {
		.report-card-header {
			@include blocks-color-dark-bg;
		}
	}
}
.group-by-configuration {
	.report-card-header {
		@include configuration-color-bg;
	}
}

.group-by-prediction_accuracy {
	.report-card-header {
		@include prediction-accuracy-color-bg;
	}
	.report-title-card:hover {
		.report-card-header {
			@include prediction-accuracy-color-dark-bg;
		}
	}
}

.ui-grid-row:nth-child(odd) .ui-grid-cell.report-grid-status-Running,
.ui-grid-row:nth-child(even) .ui-grid-cell.report-grid-status-Running {
	background-color: #e8f1f7;
}

.ui-grid-row.ui-grid-row-selected
	> [ui-grid-row]
	> .ui-grid-cell.report-grid-status-Running {
	background-color: #b5dffb;
}

.ui-grid-row:hover .ui-grid-cell.report-grid-status-Running,
.ui-grid-row:hover.ui-grid-row-selected
	> [ui-grid-row]
	> .ui-grid-cell.report-grid-status-Running {
	background-color: #b5dffb;
}

.ui-grid-row:nth-child(odd) .ui-grid-cell.report-grid-status-Queued,
.ui-grid-row:nth-child(even) .ui-grid-cell.report-grid-status-Queued {
	background-color: #e8f1f7;
}

.ui-grid-row.ui-grid-row-selected
	> [ui-grid-row]
	> .ui-grid-cell.report-grid-status-Queued {
	background-color: #b5dffb;
}

.ui-grid-row:hover .ui-grid-cell.report-grid-status-Queued,
.ui-grid-row:hover.ui-grid-row-selected
	> [ui-grid-row]
	> .ui-grid-cell.report-grid-status-Queued {
	background-color: #b5dffb;
}
/* special hover look for when displayed in the selector */

.nb-report-selector {
	.nb-dataview-row {
		.nb-dataview-inner {
			@include nb-card-shadow;
			transition: 0.2s ease-in-out;
			&:hover,
			&:active,
			&:focus {
				background-color: $white !important;
				@include nb-card-shadow-hover;
			}

			.nb-dataview-selector {
				border-right: $nb-border !important;
				&:hover,
				&:active,
				&:focus {
					background-color: $white !important;
				}
			}

			.nb-dataview-cell {
				padding: 0 !important;
				&:hover,
				&:active,
				&:focus {
					padding: 0 !important;
					background-color: $white !important;
				}
			}
		}
	}

	.nb-dataview-row.selected {
		.nb-dataview-inner {
			.nb-dataview-cell {
				padding: 0px !important;
			}
		}
	}
}

.nb-dataview-row.selected {
	.nb-dataview-inner {
		.nb-dataview-cell {
			padding: 0px !important;
		}
	}
}

.nb-report-viewer-summary-inner {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;

	.report-viewer-graph-container {
		.schedule-performance-chart-horizontal {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: stretch;
			align-content: stretch;

			.schedule-performance-chart-horizontal-item {
				flex: 1;
				min-width: 80px;
				text-align: center;
				@include nb-padding-all-sm;
				border-bottom: 3px solid #ccc;
			}

			.very-early-border {
				border-color: $veryearly;
			}

			.early-border {
				border-color: $early;
			}

			.very-late-border {
				border-color: $verylate;
			}
			.late-border {
				border-color: $late;
			}

			.on-time-border {
				border-color: $ontime;
			}
		}
	}

	.report-viewer-grid-container {
		flex: 1;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: stretch;
		align-content: stretch;

		.report-viewer-grid {
			min-width: 500px;
			flex: 1;
			@include nb-padding-all-sm;

			.sort:after {
				font-family: "data-table";
				font-style: normal;
				font-weight: normal;
				speak: none;
				display: inline-block;
				text-decoration: inherit;
				font-variant: normal;
				text-transform: none;
				line-height: 1em;
				position: relative;
				top: 1px;
			}

			.sort.sort-by-desc:after {
				content: "\66";
			}

			.sort.sort-by-asc:after {
				content: "\65";
			}

			.nb-time-difference {
				text-align: right !important;
				padding-right: 35px;
			}

			.grid-title {
				background-color: $nb-neutral-light;
				min-height: 36px;
				@include nb-padding-all-sm;
				padding-bottom: 0;

				.label {
					font-size: 15px;
				}
			}

			.report-inner-paging-toolbar {
				.paging-toolbar {
					float: right;
					border-top: 0px;
					clear: both;
				}

				&:after {
					content: "";
					clear: both;
					display: table;
				}
			}

			table {
				width: 100%;
				table-layout: fixed;

				thead {
					th {
						@include nb-label;
						border-bottom: $nb-border;
						white-space: nowrap;
						text-align: left;
						@include nb-padding-all-sm;
					}

					th[sortConfig] {
						cursor: pointer;
					}
				}

				.mw-col {
					max-width: 80px;
				}

				tbody.zebra {
					tr:nth-child(even) {
						background-color: #fcfcfc;
						border-bottom: $nb-border;
					}
				}

				tbody {
					tr {
						border-bottom: $nb-border;
						td {
							@include nb-text;
							@include nb-padding-all-sm;

							text-align: left;
							white-space: nowrap;
							overflow-x: hidden;
							text-overflow: ellipsis;
						}

						.route-id {
							border-radius: 3px;
							background-color: #999;
							text-align: center;
							color: #fff;
							@include label;
							@include nb-padding-left-md;
							@include nb-padding-right-md;
							@include nb-padding-top-xs;
							@include nb-padding-bottom-xs;
						}
					}
				}

				td.ellipsis,
				th.ellipsis {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					max-width: 300px;
					text-align: left;
				}
			}
		}

		// adds the hover behavior for navigable grids in report viewer;
		.navigable-report {
			table {
				tbody {
					tr {
						&:hover {
							background-color: #e8f1f7 !important;
							cursor: pointer;
						}
					}

					tr.report-inner-paging-toolbar {
						&:hover {
							background-color: unset;
							cursor: default;
						}

						.paging-toolbar {
							float: right;
							border-top: 0px;
						}
					}
				}
			}
		}
	}
}

.report-title-card {
	transition: all 0.25s linear;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-basis: 0;
	padding: 0 !important;
	background-color: $white;
	.report-card-header {
		transition: 0.2s ease-in-out;
		@include nb-padding-all-sm;
		width: 100%;
		display: flex;
		justify-content: space-between;
		.report-updated-at {
			@include nb-text;
		}
		.report-code {
			color: $white !important;
			max-width: 75%;
			word-wrap: break-word;
			@include nb-text;
		}

		.report-type {
			max-width: 25%;
			font-family: $nb-font;
			font-weight: 300;
			letter-spacing: 1px;
			font-size: 11px !important;
			color: $white !important;
			text-transform: uppercase;
		}
	}

	.report-content {
		flex: 10;
		@include nb-padding-top-sm;
		@include nb-padding-left-sm;

		@include nb-padding-bottom-sm;
		@include nb-padding-right-sm;

		.report-title {
			display: flex;
			justify-content: space-between;
			@include nb-title-sm;
			word-break: normal;
			overflow-wrap: break-word;
			@include nb-margin-bottom-sm;
		}

		.report-description {
			@include nb-text;
			word-break: normal;
			overflow-wrap: break-word;
		}
	}

	.report-footer {
		@include nb-padding-all-sm;
		border-top: $nb-border;

		flex: 1;
		.report-timestamp {
			align-self: flex-end;
			@include nb-timestamp;
			flex: 1;
		}

		.report-status {
			@include nb-timestamp;
		}
	}
}

nb-adherence-daily-stacked-bar-chart {
	.title {
		@include nb-section-title;
	}
}

available-report-grid {
	$disabled-bg: rgb(234, 234, 234);
	$disabled-fg: #aaa;

	.ui-grid-cell {
		transition: background-color 0.5s;
		&.new-report {
			background-color: #e8f1f7 !important;
		}
	}
	.ui-grid-render-container {
		.ui-grid-row-disabled {
			&,
			.ui-grid-cell {
				background-color: $disabled-bg;
				color: $disabled-fg;
				pointer-events: none;
			}
		}
	}
	.ui-grid-render-container-left {
		.ui-grid-row-disabled {
			&,
			* {
				background-color: $disabled-bg;
				border-color: $disabled-bg !important;
				color: $disabled-fg;
				pointer-events: none;
			}
			.ui-grid-selection-row-header-buttons {
				visibility: hidden;
			}
		}
	}
}

.text-align-right {
	text-align: right !important;
}

.ui-grid-cell-contents {
	text-transform: none;
}

.nb-report-popover {
	.nb-heading {
		border-bottom: 1px solid #ccc;
	}

	.nb-popover-section {
		@include nb-padding-top-sm;
		@include nb-padding-bottom-sm;
	}

	.nb-popover-line {
		border-bottom: 1px solid #ccc;
	}
}

.nb-report-modal-trigger {
	border: 1px solid #fff;
}

.nb-report-modal-trigger:hover {
	border: 1px solid #666;
}

.nb-report-modal-trigger {
	border: 1px solid #fff;
}

.default-reports-disabled {
	cursor: not-allowed;
}
