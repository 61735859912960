.vehicle-events-content-area {
	justify-content: space-between;
	flex-wrap: wrap;
	cursor: pointer;
}

.vehicle-events-infoblock {
	@include nb-padding-top-md;
	@include nb-padding-bottom-md;
	@include nb-padding-left-sm;
	@include nb-padding-right-sm;
	width: 100%;
	display: flex;
	.vehicle-events-text {
		@include nb-padding-right-md;
	}
	.vehicle-events-icon {
		@include nb-margin-right-md;
		i {
			font-size: 30px;
		}
	}
}
//When an alarm is triggered the styling changes to alert the user
.vehicle-events-critical-priority {
	width: 100%;
	@include nb-padding-top-md;
	@include nb-padding-bottom-md;
	@include nb-padding-left-sm;
	@include nb-padding-right-sm;
	background-color: $reallybad-caution !important;
	border-left: 4px solid $reallybad !important;
	display: flex;
	.vehicle-events-text {
		color: $white !important;
		@include nb-padding-right-md;
		.nb-title-sm {
			letter-spacing: 0.8px;
			font-weight: 400;
		}
		.nb-title-sm,
		.nb-text,
		.nb-inline-list,
		.nb-inline-label,
		.nb-timestamp {
			color: $white !important;
		}
	}
	.vehicle-events-icon {
		@include nb-margin-right-md;
		i {
			font-size: 30px;
			color: $reallybad !important;
		}
	}
}

.vehicle-events-timestamp {
	@include nb-timestamp;
}

.vehicle-events-container {
	border-bottom: $nb-border;
}
.vehicle-events-container-noborder {
	border-bottom: 0px;
}
.vehicle-events-container,
.vehicle-events-container-noborder {
	&:hover {
		background-color: $nb-primary-lighter;
	}
}
//Edit
.vehicleevents-viewsettings {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	.nb-section-subtitle {
		@include nb-margin-bottom-sm;
	}
}
.vehicleevents-drivercol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.vehicleevents-vehiclecol {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	@include nb-padding-left-lg;
}

.events-sorting-button {
	padding: 0;
	cursor: pointer;
	background: 0 0;
	border: 0;
	i {
		font-size: 22px;
	}
}

.map-vehicle-event-panel {
	position: relative;
	right: 50px;
	z-index: 9000;
	display: flex;
	background: white;
	flex-direction: column;
	overflow-x: hidden;
	text-align: left;
	@include nb-shadow;
}

.panel-header {
	@include nb-padding-all-sm;
	border-bottom: $nb-border;
}

.nb-map-vehicle-events {
	position: absolute;
	max-height: 50%;
	max-width: 50em;
	bottom: 25px;
	right: 10px;
	z-index: 800;
	overflow: hidden;
	display: flex;
	background: white;
	flex-direction: column;
}

.nb-maps-vehicle-events-settings {
	position: absolute;
	max-height: 50%;
	max-width: 50em;
	bottom: 25px;
	right: 10px;
	z-index: 800;
	overflow: hidden;
	display: flex;
	background: white;
	flex-direction: column;
}

.nb-map-vehicle-events-IE {
	position: absolute;
	height: 350px;
	max-width: 50em;
	bottom: 25px;
	right: 10px;
	z-index: 800;
	overflow: hidden;
	display: flex;
	background: white;
	flex-direction: column;
}

.nb-maps-vehicle-events-header {
	height: 40px;
	min-height: 40px;
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	border-bottom: 1px solid #dbdbdb;
}

.nb-maps-vehicle-events-content {
	flex: 1 1 auto;
	flex-direction: column;
	max-height: 863px;
	overflow-y: auto;
	overflow-x: hidden;
}

.maps-vehicles-events-settings-content {
	display: flex;
	flex-direction: column;

	height: 100%;
	width: 100%;
}

.maps-vehicle-events-disabled {
	opacity: 0.6;
	color: $nb-textcolor;
}

.nb-maps-vehicle-events-flex-wrapper {
	display: flex;
}

.nb-picker {
	overflow-y: auto;
	padding-left: 10px;
	padding-right: 0;
	border: 1px solid #ddd;
	border-radius: 4px;
	height: 300px;
}

.nb-picker-item {
	border: none;
	padding: 0;
	text-align: left;
	text-indent: 10px;
}

.nb-group-type-header {
	text-transform: uppercase;
	font-weight: bold;
	text-indent: 0;
}

.nb-event-superscript {
	position: absolute;
	top: -4px;
	left: 18px;
	padding-top: 4px;
	padding-bottom: 3px;
}
