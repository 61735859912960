@import "../../../../../../node_modules/@swimlane/ngx-datatable/themes/material.scss";

/* ngx-datatables - overrides */

.ngx-datatable.material {
    background: #fff;
    border: 1px solid #d4d4d4;
    box-shadow: none;
}

datatable-header {
    height: 33px !important;
}

datatable-header-cell.datatable-header-cell.sortable.resizeable.sort-active.sort-asc {
    border-bottom: 3px #00d9ac solid;
}

datatable-header-cell.datatable-header-cell.sortable.resizeable.sort-active.sort-desc {
    border-bottom: 3px #00d9ac solid;
}

.ngx-datatable.material .datatable-header .datatable-header-cell:first-child {
    padding: 4px 4px 4px 10px;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:first-child {
    padding: 4px 4px 4px 10px;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
    padding: 4px 4px 4px 5px;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    padding: 4px 4px 4px 5px;
}

.page-count {
    padding-left: 5px;
}

.ngx-datatable.material .empty-row {
    padding-top: 15px;
}

.datatable-body-cell-label:hover {
    cursor:pointer;
}

.ngx-datatable.bootstrap:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
   cursor:pointer;
   background: #e8f1f7;
}

.selected-color {
    background-color: #e8f1f7;
}

.ngx-datatable .datatable-footer .datatable-pager {
    text-align: left !important;
    flex: initial !important;
}

.ngx-datatable.material .datatable-footer .datatable-pager a {
    margin: 0px;
    height: 25px;
    min-width: 30px;
    padding-top: 1px;
    border-radius: 0px;
}

.ngx-datatable.material
.datatable-footer
.datatable-pager
.datatable-icon-left, .ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-right,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-prev {
    font-size: 20px;
    line-height: 20px;
    height: 38px;
    padding: 0px;
    margin: 0px;
    position: relative;
    top: 0.5px
}

.datatable-header-cell-label {
    font-size: 13px;
    color: #3d3e40;
    letter-spacing: .3px;
    font-weight: 500;
}

.datatable-body-cell-label {
	height: 30px;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: .3px;
    color: #3d3e40 ;
}

.datatable-body-cell-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ngx-datatable.material .empty-row {
    text-align: center;
    font-family: Roboto,sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    color: #afb1b3;
}

.ngx-datatable .datatable-body-row, .ngx-datatable .datatable-header-inner, .ngx-datatable .datatable-row-center {
    flex-grow: 1;
}

.datatable-header-inner {
    width: inherit !important
}

/* hamburger menu icon */
i.ui-grid-icon-menu {
    font-size: 14px;
    padding: 7px 5px 10px 8px;
    background: #eee;
    position: relative;
    left: 0px;
}

/* loading indicator appears in header */
.ngx-datatable.material .datatable-body .progress-linear .container {
    position: fixed;
    top: 28px;
    height: 5px;
    background-color:#bfe9e0;
}

.ngx-datatable.material .datatable-body .progress-linear .container .bar {
    background-color: #00d9ac;
}

.ngx-datatable.material .datatable-body .progress-linear {
    height: 0px;
}

/* the following section ensure we fill the screen ONLY when table is too big to fit
 on screen and make sure the sticky header/footer and scroll bars work as expected
*/
datatable-scroller {
    width: inherit !important;
}

.datatable-row-group {
    width: inherit !important;
}

datatable-body-row {
    width: inherit !important;
}

.datatable-body {
    height: auto !important;
}

.ngx-datatable {
    overflow-y: auto !important;
}

.ngx-datatable.scroll-vertical .datatable-body {
    overflow-y: auto;
    overflow-x: hidden;
}

.menu-header-cell {
    position: absolute !important;
    right: 0px;
    border-bottom: 3px #eee solid; /* replace what would be the space kept for the green sorting line with same color as the menu */
    padding: 4px 6px 8px 7px !important;
}

/* ensures full column header is clicable for sorting */
.ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
    width: 100% !important;
}

datatable-header-cell .datatable-checkbox input[type='checkbox'] {
    position: relative;
    top: 3px;
}

/* set extra row detail to have same font style as datatable-body-cell-label */
.ngx-datatable.material .datatable-body .datatable-row-detail {
    font: 13px Roboto, sans-serif;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: .3px;
    color: #3d3e40;
}

datatable-row-wrapper:not(:last-child) .datatable-row-detail {
    border-bottom: 1px solid #dbdbdb;
}

.ngx-datatable.material .datatable-body .datatable-row-detail {
    background: none;
    padding: 0px;
    border-top: 1px solid #dbdbdb;
    width: initial;
    margin-left: 30px;
}

a.datatable-icon-up,
a.datatable-icon-down,
a.datatable-icon-left,
a.datatable-icon-right {
    color: #626467;
    position: relative;
    left: 6px;
    font-size: 18px
}

/* stops extra space being added when we show our loading indicator */
.ngx-datatable .datatable-body .datatable-scroll {
    display: initial !important;
}

.datatable-body-cell-label .material-icons {
    position: relative;
    top: -1.5px;
    left: 13px;
}

.ngx-datatable.material .datatable-footer .datatable-pager {
    margin: 1px 10px 0px 10px;
}

.datatable-header-cell-wrapper {
	position: relative;
	top: 1px;
}
