.nb-bottombar-button {
	transition: 0.2s ease-in-out;
	.nb-button-text {
		transition: 0.2s ease-in-out;
		color: $nb-textcolor !important;
	}
	&:hover {
		background-color: $nb-accent;
		color: $white !important;
		.nb-button-text {
			color: $white !important;
		}
	}
}

.nb-map-settings-container {
	position: fixed;
	top: 70px;
	right: 55px;
	z-index: 10000;
	overflow: visible;
	bottom: 10px;

	transform: translate(120%, 0);
	transition-duration: 0.3s;

	pointer-events: none;
	& > * {
		pointer-events: auto;
	}

	&.show {
		transform: translate(0%, 0);
		transition: transform ease 0.3s;
		right: 62px;
	}

	@media (max-width: 350px) {
		left: 10px;
	}
}

.nb-settings-button-open {
	background: #fff;
	position: absolute;
	border-radius: 10px 0 0 10px;
	font-size: 16px;
	top: 0;
	left: 0;
	width: 40px;
	color: grey;
	cursor: pointer;
	border: 1px solid grey;
	border-right-width: 0px;
}

.nb-settings-button-closed {
	background: $white;
	border-radius: $nb-button-radius;
	font-size: 16px;
	color: $nb-textcolor-light;
	cursor: pointer;
	border-right-width: 0px;
}

.nb-map-settings {
	border: 0;
	background-color: #ffffff;
	overflow-x: hidden;
	overflow-y: auto;
	max-height: 100%;
	max-width: 100%;
	@include nb-shadow;
	.close {
		margin-top: 10px;
		opacity: 1;
		padding-right: 8px !important;
	}
	.nb-backarrow {
		@include nb-padding-right-sm;
	}
	.nb-backarrow:hover {
		color: $nb-accent;
	}
	.nb-forward-arrow:hover {
		color: $nb-accent;
	}
	.nb-section-title {
		@include nb-padding-bottom-sm;
	}
	.nb-section-subtitle {
		@include nb-padding-bottom-sm;
	}
	.btn-group {
		@include nb-padding-top-sm;
	}
	.nb-dropdown-label {
		@include nb-padding-top-sm;
		@include nb-padding-bottom-sm;
	}
}

.nb-map-settings-header {
	text-align: left;
	height: $nb-mapsettings-header-height;
	vertical-align: middle;
	@include nb-padding-all-sm;
	.nb-header-icon {
		@include nb-padding-right-sm;
		color: $nb-textcolor-light;
		font-size: 15px;
		padding-bottom: 2px;
	}
}

.nb-map-settings .content {
	border-top: $nb-border;
}

.nb-map-settings-item .first {
}

.nb-map-settings-item {
	border-top: $nb-border;
}

.nb-map-settings-subitem {
	clear: both;
}

.nb-map-settings-item.map {
	text-align: center;
}

.nb-map-settings-item .switch {
	float: right;
}

.nb-map-settings-item a {
	color: #fff;
}

.nb-map-replay-mode {
	background: #fff;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 550px;
	height: 62px;
	margin: 0;
	border: 1px solid grey;
	box-shadow: 1px 1px grey;
	border-radius: 2px 2px 2px 2px;
	pointer-events: auto;
}

.nb-replay-onoffswitch-live {
	width: 80px;
	margin-top: 4px;
}

.nb-replay-onoffswitch-replay {
	width: 80px;
}

.nb-replay-onoffswitch-inner:before {
	content: "Live View";
	background-color: $nb-accent;
}
.nb-replay-onoffswitch-inner:after {
	content: "Replay View";
}

.nb-replay-onoffswitch-switch-replay {
	right: 72px;
}

.nb-replay-control-container-inner {
	margin: 0 0 0 0;
	height: 100%;
	width: 100%;
}

.nb-replay-control-container {
	position: absolute;
	width: 550px;
	pointer-events: none;
	overflow: hidden;
	z-index: 1000 !important;
	bottom: 0;
	height: 100%;
}
