.nb-schedules-item {
	.schedule-dataview-tpl {
		border: $nb-border;
		@include nb-text;
	}

	.toolbar .toolbar-group {
		padding-bottom: 0;
	}
}

.nb-schedules-item-heading {
	@include nb-subhead;
	@include nb-padding-all-md;
}

.nb-overview-item {
	border: $nb-border;
	background-color: $white;
}
