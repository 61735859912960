// Vehicle Messages - Show
.nb-messaging-title-bar {
	border-bottom: $nb-border;
	display: flex;
	justify-content: space-between;
	align-items: center;
	@include nb-padding-all-sm;
}
.nb-message-indicator {
	@include nb-margin-right-md;
	i {
		font-size: 10px;
	}
	.nb-radio-selected {
		color: $acceptable !important;
	}
	.nb-radio-unselected {
		color: $nb-textcolor !important;
	}
}

.nb-incoming-message,
.nb-outgoing-message {
	@include nb-padding-all-sm;
	border-bottom: $nb-border;

	.message-text {
		@include nb-text;
		font-size: 14px;
		@include nb-margin-bottom-xs;
	}
	.delivery-details {
		@include nb-helper-text;
	}
	.message-timestamp {
	}
}

.nb-incoming-message {
	text-align: left;
}

.nb-outgoing-message {
	border-radius: 4px;
	text-align: right;
	background-color: $nb-primary-lighter;
}

.message-critical-priority {
	color: $white;
	background-color: $reallybad;
	padding: 8px;
	border-radius: 2px;
}
