//  font-family: 'Roboto', sans-serif;
//  font-weight:400 (Regular)
//  font-weight:500 (Medium)

/*
<link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500" rel="stylesheet">
manually including the contents of the roboto font below;

*/

$smalltext-spacing: 0.3px;

@mixin nb-text {
	font-size: 13px;
	font-weight: 400;
	letter-spacing: $smalltext-spacing;
	color: $nb-textcolor;
	line-height: 22px;
	a {
		color: $nb-accent !important;
		text-decoration: none;
		transition: 0.2s ease-in-out;
		&:hover,
		&:active,
		&:focus {
			color: $nb-accent-dark !important;
			text-decoration: none;
		}
	}
}

.nb-heading,
.nb-subhead,
.nb-title-sm,
.nb-tab-labels,
.nb-section-title {
	font-family: $nb-font;
	color: $nb-textcolor;
	letter-spacing: 0.1px;
}

.nb-text {
	@include nb-text;
}

@mixin nb-subhead {
	font-size: 16px;
	font-weight: 500;
}

.nb-subhead {
	@include nb-subhead;
}

@mixin small-caps {
	align-items: baseline;
	text-transform: uppercase;
}

@mixin all-caps {
	text-transform: uppercase;
	letter-spacing: 1px;
}

.small-caps {
	@include small-caps;
}

.all-caps {
	@include all-caps;
}

/* cyrillic-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"),
		url("#{$icomoon-font-path}/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2")
			format("woff2");
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
		U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"),
		url("#{$icomoon-font-path}/KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2")
			format("woff2");
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"),
		url("#{$icomoon-font-path}/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2")
			format("woff2");
	unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"),
		url("#{$icomoon-font-path}/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2")
			format("woff2");
	unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"),
		url("#{$icomoon-font-path}/KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2")
			format("woff2");
	unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"),
		url("#{$icomoon-font-path}/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2")
			format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
		U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"),
		url("#{$icomoon-font-path}/KFOlCnqEu92Fr1MmSU5fBBc4.woff2")
			format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"),
		url("#{$icomoon-font-path}/KFOmCnqEu92Fr1Mu72xKOzY.woff2")
			format("woff2");
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
		U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"),
		url("#{$icomoon-font-path}/KFOmCnqEu92Fr1Mu5mxKOzY.woff2")
			format("woff2");
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"),
		url("#{$icomoon-font-path}/KFOmCnqEu92Fr1Mu7mxKOzY.woff2")
			format("woff2");
	unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"),
		url("#{$icomoon-font-path}/KFOmCnqEu92Fr1Mu4WxKOzY.woff2")
			format("woff2");
	unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"),
		url("#{$icomoon-font-path}/KFOmCnqEu92Fr1Mu7WxKOzY.woff2")
			format("woff2");
	unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"),
		url("#{$icomoon-font-path}/KFOmCnqEu92Fr1Mu7GxKOzY.woff2")
			format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
		U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"),
		url("#{$icomoon-font-path}/KFOmCnqEu92Fr1Mu4mxK.woff2") format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"),
		url("#{$icomoon-font-path}/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2")
			format("woff2");
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
		U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"),
		url("#{$icomoon-font-path}/KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2")
			format("woff2");
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"),
		url("#{$icomoon-font-path}/KFOlCnqEu92Fr1MmEU9fCBc4EsA.woff2")
			format("woff2");
	unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"),
		url("#{$icomoon-font-path}/KFOlCnqEu92Fr1MmEU9fBxc4EsA.woff2")
			format("woff2");
	unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"),
		url("#{$icomoon-font-path}/KFOlCnqEu92Fr1MmEU9fCxc4EsA.woff2")
			format("woff2");
	unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"),
		url("#{$icomoon-font-path}/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2")
			format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
		U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"),
		url("#{$icomoon-font-path}/KFOlCnqEu92Fr1MmEU9fBBc4.woff2")
			format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}

*:not(i) {
	font-family: $nb-font;
}

///////////////////////////////////////////////////////

::-moz-selection {
	/* Code for Firefox */
	color: $white;
	background: $nb-accent-dark;
}
::selection {
	color: $white;
	background: $nb-accent-dark;
}

@mixin nb-heading {
	font-size: 20px;
	font-weight: 400 !important;
}

.nb-heading {
	@include nb_heading;
}

.nb-tab-labels {
	font-size: 20px;
	font-weight: 400 !important;
}

@mixin nb-title-sm {
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.3px;
	word-break: break-word;
}

.nb-title-sm {
	@include nb-title-sm;
}

@mixin nb-tab-labels {
	@include all-caps;
	font-size: 13px;
	font-weight: 300;
}

.nb-tab-labels {
	@include nb-tab-labels;
}

@mixin nb-section-title {
	@include all-caps;
	font-family: $nb-font;
	font-size: 14px !important;
	font-weight: 500;
	letter-spacing: 1px;
	color: $nb-textcolor-lighter; //$nb-textcolor;
}

.nb-section-title {
	@include nb-section-title;
}

.nb-section-subtitle {
	font-size: 13px;
	font-weight: 500;
	color: $nb-textcolor;
	letter-spacing: $smalltext-spacing;
}

//SMALL TEXT STYLES

@mixin nb-timestamp {
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0.4px;
	color: $nb-textcolor;
	a {
		color: $nb-accent !important;
		text-decoration: none;
		transition: 0.2s ease-in-out;
	}
}

.nb-timestamp {
	@include nb-timestamp;
}

@mixin nb-numerals {
	// text-align: right !important;
	@include nb-text;
}

.nb-numerals {
	@include nb-numerals;
}

@mixin nb-inline-label {
	font-size: 12px;
	font-weight: 100;
	letter-spacing: $smalltext-spacing;
	color: $nb-textcolor;
	a {
		color: $nb-accent !important;
		text-decoration: none;
		transition: 0.2s ease-in-out;
	}
}

.nb-inline-label {
	@include nb-inline-label;
}

@mixin nb-label {
	font-size: 13px;
	color: $nb-textcolor;
	letter-spacing: $smalltext-spacing;
	font-weight: 500;
}

.nb-label {
	@include nb-label;
}

@mixin nb-text-link {
	font-weight: 400;
	font-size: 13px;
	letter-spacing: $smalltext-spacing;
	color: $nb-accent;
	transition: 0.2s ease-in-out;
	&:hover,
	&:active,
	&:focus {
		color: $nb-accent-darker;
		text-decoration: none;
	}
}

.nb-text-link {
	@include nb-text-link;
}

// Used for purely informational text
.nb-body {
	font-size: 14px;
	font-weight: 400;
	letter-spacing: $smalltext-spacing;
	color: $nb-textcolor;
	line-height: 22px;
}

@mixin nb-text-sm {
	font-size: 13px !important;
	@include nb-text;
}

.nb-text-sm {
	@include nb-text-sm;
}

@mixin nb-helper-text {
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0.4px;
	color: $nb-textcolor-lighter; //$nb-textcolor;
	a {
		color: $nb-accent !important;
		text-decoration: none;
		transition: 0.2s ease-in-out;
	}
}

.nb-helper-text {
	@include nb-helper-text;
}

.nb-breadcrumbs {
	font-size: 13px;
	color: $nb-textcolor;
	font-weight: 400;
	letter-spacing: 0.2px;
	margin: 0 !important;
	a {
		font-size: 13px;
		font-weight: 400;
		letter-spacing: 0.2px;
		color: $nb-accent !important;
		text-decoration: none;
	}
	a:hover,
	a:active,
	a:focus,
	a:visited {
		font-size: 13px;
		font-weight: 400;
		letter-spacing: 0.2px;
		color: $nb-accent-dark;
		text-decoration: none;
	}
}

@mixin nb-error-text {
	@include nb-text;
	color: $nb-error;
}

.nb-error-text {
	@include nb-error-text;
}

//HELPER CLASSES

.flex-align-baseline {
	display: flex;
	align-items: baseline;
}
.flex-justify-spacebetween {
	justify-content: space-between;
}

.bold {
	font-weight: 500 !important;
	letter-spacing: 0.2px;
}
.align-left {
	text-align: left;
}
.align-right {
	text-align: right;
}
.centered {
	text-align: center;
}
.text-accent {
	color: $nb-accent;
}
.text-accent-bright {
	color: $nb-accent-bright;
}
.text-white {
	color: $white;
}

.nb-text-error {
	color: $reallybad-caution;
}
.nb-text-warning {
	color: $bad-caution;
}
.nb-text-info {
	color: $nb-textcolor-lighter;
}
.nb-text-light {
	color: $nb-textcolor-light;
}
