///////////////////////
// UI Elements

.nb-dropdown-position {
	list-style-type: none;
	a {
		color: $nb-textcolor;
	}
}

//filter indicators
.nb-filter-indicator {
	display: inline;
	color: $nb-accent;
	padding-right: 10px;
}

.nb-on-circle {
	font-size: 10px;
	position: relative;
	top: -5px;
	color: $nb-accent;
}

.nb-off-circle {
	font-size: 10px;
	position: relative;
	top: -5px;
	color: $nb-textcolor-light;
}

//Labels
.label-primary,
.badge-primary {
	background-color: $nb-accent;
}

// -----------------------------------------------------
// i-check overrides
.icheckbox_square-green,
.iradio_square-green {
	background: url("/assets/img/nb-aqua_ichecks.png") no-repeat;
}

.checkbox,
.radio {
	position: relative;
	display: block;
	margin-top: 0px;
	margin-bottom: 0px;
}

.nb-checkbox-group {
	@include nb-padding-top-sm;
}
.nb-checkbox {
	@include nb-padding-bottom-xs;
}
.nb-checkbox:last-of-type {
	padding-bottom: 0px;
}
//-----------------------------------------------------
// Modal Dialogs/Popups
//-----------------------------------------------------

.nb-modal-body {
	@include nb-margin-top-md;
	overflow-x: hidden;
	overflow-y: auto;
}

.nb-modal-content {
	max-width: 400px;
}

//Sub forms

.nb-sub-form-title {
	text-align: center;
	font-size: 18px;
	font-weight: 100;
}

.nb-form-label {
	text-align: right;
	padding-top: 6px;
}

.nb-form-text-box {
}

.nb-form-footer {
	text-align: center;
}

.nb-modal-header-with-subform {
	padding-bottom: 0px;
}

.nb-sub-form-title-link {
	margin-left: 30px;
	margin-right: 30px;
	cursor: pointer;
}

//Forms
//
// .form-control {
//   background-color:$white;
// }
//
// .form-control:focus {
//   border-color:$nb-accent !important;
// }
//
// .has-error .help-block {
//   @include nb-label;
//   color:$error;
//   text-align:left;
//   margin-top:0px;
// }
