/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

@import "map-details/nb-route-details.scss";
@import "map-details/nb-vehicle-details.scss";
@import "map-details/nb-block-details.scss";

@import "map-labels/nb-depot-labels.scss";
@import "map-labels/nb-stop-labels.scss";
@import "map-labels/nb-vehicle-labels.scss";

@import "nb-display-modes.scss";
@import "nb-leaflet.scss";
@import "nb-map-settings.scss";
@import "nb-replay.scss";

.nb-map-label-text {
	@include nb-map-label-text;
}

.nb-map-label-chip-text {
	@include nb-map-label-chip-text;
}

.nb-ladder-label-text {
	font-family: $nb-font;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: 0.2px;
	color: $nb-textcolor;
}

@mixin nb-shadow-map-tools {
	-webkit-box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.5);
	border-radius: 4px;
}

.nb-map-button-map {
	position: absolute;
	top: 10px;
	right: 8px;
	width: 50px;
	z-index: 1000;
	@include nb-shadow-map-tools;
}

.nb-map-button-ladder {
	position: absolute;
	top: 75px;
	right: 8px;
	width: 50px;
	z-index: 1000;
	@include nb-shadow-map-tools;
}

.nb-map-button-zoom {
	position: absolute;
	top: 135px;
	right: 8px;
	width: 30px;
	z-index: 1000;
	@include nb-shadow-map-tools;
}

.nb-map-menu-container {
	position: absolute;
	top: 225px;
	right: 8px;
	width: 30px;
	z-index: 1000;
	@include nb-shadow-map-tools;
}

//Button styling
.nb-map-menu-button {
	padding: 5px !important;

	i {
		font-size: 18px !important;
	}

	border: 1px solid $nb-border-color;
	cursor: pointer;
	text-align: center;
	background: $white;
	height: 35px !important;
	width: 30px !important;

	&:hover {
		opacity: 1;
		cursor: pointer;
		text-align: center;
	}
}
.nb-map-menu-button-events {
	padding: 2px !important;

	i {
		font-size: 18px !important;
		position: relative;
		top: 7.5px;
		left: 9px;
	}

	border: 1px solid $nb-border-color;
	cursor: pointer;
	text-align: center;
	background: $white;
	height: 20.5px !important;
	width: 24px !important;

	&:hover {
		opacity: 1;
		cursor: pointer;
		text-align: center;
	}
}

.nb-bell-icon {
	background: $nb-primary-dark;
	width: 35px;
	max-width: 100%;
	border: none;
	display: inline-block;
}

.nb-map-resume-button {
	color: $white !important;
	background: $reallybad;
	border: 1px solid $reallybad;

	&:hover {
		color: $white;
		cursor: pointer;
		text-align: center;
	}
}

.label {
	line-height: 16px;
}

.nb-map-vehicle-marker-container:has(.nb-vehicle-icon-vehicles-top) {
	z-index: 3000 !important;
}

.nb-map-vehicle-marker-container:has(.nb-vehicle-icon-vehicles-bottom) {
	z-index: 1000 !important;
}

.nb-map-vehicle-marker {
}

.nb-map-vehicle-marker-container:has(.nb-map-vehicle-marker) {
	transition-duration: 5s;
}

.nb-map-vehicle-marker-container:has(.nb-map-vehicle-marker-fast-transition) {
	transition-duration: 2s;
}

.nb-map-vehicle-overlay {
	background: #ff0000;
	height: 100%;
	width: 100%;
	opacity: 0;
	top: 0;
	left: 0;
	position: absolute;
	transition: opacity 0.5s;
}

.nb-map {
	position: absolute;
	right: 0;
}

//Map Settings

@mixin nb-vehicle-details-status-base {
	position: relative;
	top: -1px;
	width: 94px;
	display: inline-block;
	padding: 4px 8px 4px 8px;
	border-radius: 0.25em;
	font-size: 12px;
	line-height: 12px;
	font-weight: bold;
	text-align: center;
}

.nb-vehicle-details-status-on-route {
	@include nb-vehicle-details-status-base;
	background-color: $nb-primary;
	color: $white;
}

.nb-vehicle-details-status-stale {
	@include nb-vehicle-details-status-base;
	background-color: $bad-caution;
	color: $white;
}

.nb-vehicle-details-status-unpredictable {
	@include nb-vehicle-details-status-base;
	background-color: $reallybad;
	color: $white;
}

.nb-vehicle-details-status-deassigned {
	@include nb-vehicle-details-status-base;
	background-color: $reallybad;
	color: $white;
}

.nb-vehicle-details-status-unassigned {
	@include nb-vehicle-details-status-base;
	background-color: $nb-neutral-dark;
	color: $nb-textcolor;
}

.nb-clickable {
	cursor: pointer;
}

.nb-table-col-display-btn {
	width: 40px;
}

.nb-map-label-row {
	display: table-row;
}

.nb-map-label-item {
	display: table-cell;
	padding: 0.3em 0.5em;
	border-right: 1px solid #fff;
}

.nb-map-label-item:last-child {
	border-right: none;
}

.nb-map-label-item2 {
	display: table-cell;
	border-right: 1px solid #fff;
}

.nb-map-label-item2:last-child {
	border-right: none;
}

.nb-map-vehicle-adherence-very-early {
	background-color: $veryearly !important;
	color: #fff;
}

.nb-map-vehicle-adherence-early {
	background-color: $early !important;
	color: #fff;
}

.nb-map-vehicle-adherence-on-time {
	background-color: $ontime !important;
	color: #fff;
}

.nb-map-vehicle-adherence-late {
	background-color: $late !important;
	color: #fff;
}

.nb-map-vehicle-adherence-very-late {
	background-color: $verylate !important;
	color: #fff;
}

.nb-map-vehicle-adherence-very-early-text {
	color: $veryearly !important;
}

.nb-map-vehicle-default-vehicle-color {
	background-color: #666666 !important;
}

.nb-map-vehicle-default-vehicle-text-color {
	color: #ffffff !important;
}

.nb-map-vehicle-adherence-early-text {
	color: $early !important;
}

.nb-map-vehicle-adherence-on-time-text {
	color: $ontime !important;
}

.nb-map-vehicle-adherence-late-text {
	color: $late !important;
}

.nb-map-vehicle-adherence-very-late-text {
	color: $verylate !important;
}

.nb-map-vehicle-adherence-very-early-text a {
	color: $veryearly !important;
}

.nb-map-vehicle-adherence-early-text a {
	color: $early !important;
}

.nb-map-vehicle-adherence-on-time-text a {
	color: $ontime !important;
}

.nb-map-vehicle-adherence-very-late-text a {
	color: $verylate !important;
}

.nb-map-vehicle-adherence-late-text a {
	color: $late !important;
}

.nb-performancestatus {
	padding: 3px;
}

.nb-map-vehicle-schedule-past {
}

.nb-map-vehicle-schedule-active {
	font-weight: 500;
}

.nb-map-vehicle-schedule-future {
	font-style: italic;
}

.nb-map-vehicle-schedule-future-row {
	background-color: #f7f7f7;
}

.nb-map-vehicle-schedule-past-marker {
	font-size: 12px;
	color: #cccccc;
}

.nb-map-vehicle-schedule-active-marker {
	font-size: 12px;
}

.nb-map-vehicle-schedule-future-marker {
	font-size: 12px;
}

.nb-map-details-vehicle-schedule-issue-marker {
	font-size: 12px;
	color: $bad-caution;
}

.nb-map-view-icon {
	color: $nb-accent;
}

.nb-map-stop-marker {
}

.nb-map-stop-marker-hidden {
	display: none !important;
}

.nb-map-details-vehicle-trip-chart {
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 10px;
	border: 1px solid $nb-border-color;
}

.leaflet-control-zoom {
	display: none;
}

.mapboxgl-control-container {
	display: none;
}

.noUi-handle:hover {
	z-index: 7 !important;
}

//Button styling
.nb-map-ladder-button {
	padding: 0 !important;

	i {
		font-size: 18px !important;
	}

	border: 1px solid $nb-border-color;
	border-radius: 4px !important;
	cursor: pointer;
	text-align: center;
	height: 50px !important;

	&:disabled {
		background-color: #9a999f !important;

		&:hover {
			background-color: #9a999f !important;
		}
	}
}

.nb-map-ladder-container {
	height: 30px;
	width: 40px;
}

.buttonSelectionBgColor {
	background-color: $nb-neutral-green !important;
}
