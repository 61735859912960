/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
.nb-login-card {
	background-color: white;
	padding-top: 0;
	//height: 492px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@include nb-shadow;
	.nb-login-maincontent {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		// height: 100%;
		// min-height: 432px;
	}
	.nb-login-footer {
		height: 54px;
	}

	.nb-logo-container {
		height: 97px;
		padding-top: 28px !important;
		@include nb-padding-all-sm;
		@include nb-margin-bottom-md;
		.nb-loginlogo {
			width: 75%;
		}
	}
	.nb-reset-password-text {
		@include nb-padding-left-md;
		@include nb-padding-right-md;
		text-align: center;
		@include nb-text;
	}

	.nb-login-invalid-login-error-container {
		@include nb-error-text;
	}

	.nb-login-form-container {
		@include nb-padding-left-md;
		@include nb-padding-right-md;
		//height: 140px;
	}

	.nb-login-error-container {
		@include nb-error-text;
		color: $nb-error !important;
		// height: 36px;
		text-align: center;
		margin: 0;
		padding: 0;
	}

	.nb-button-group-container {
		@include nb-margin-bottom-md;
	}

	.card-footer {
		background-color: $nb-neutral-dark;
	}

	.nb-login-colorblock {
		text-align: center;
		background-color: $nb-primary-darker;
		@include nb-padding-all-sm;
		img {
			width: 150px;
			height: 38px;
		}
	}

	//RESETS
	.form-control {
		margin: 0;
	}
}

//Log In

.nb-login-container {
	margin-top: 10%;
	padding-top: 0;
}

.nb-login-container-with-msg {
	margin-top: 5%;
	padding-top: 0;
}

.nb-login-padded-container {
	padding: 0px 1.2em 0px 1.2em;
}

.nb-fullscreen-login-bg {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	z-index: -100;
}

.nb-fullscreen-bg-video {
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
	min-width: 100%;
	min-height: 100%;
}

@media (min-aspect-ratio: 16/9) {
	.nb-fullscreen-bg-video {
		width: 100% !important;
		height: auto !important;
	}
}

@media (max-aspect-ratio: 16/9) {
	.nb-fullscreen-bg-video {
		width: auto !important;
		height: 100% !important;
	}
}

.nb-not-supported-container {
	margin-top: 3%;
}

.nb-browser-not-supported-container {
	color: $nb-textcolor;
	background-color: #ffffff;
	padding: 0 15px 15px;
	margin-bottom: 20px;
	border: 1px solid #ffffff;
	border-radius: 4px;
}

.nb-browser-not-supported-table {
	border: 0;
}

.nb-browser-not-supported-table-row {
	padding: 5px;
}

.nb-browser-not-supported-table-text-cell {
	text-align: left;
	padding: 5px;
}

.nb-browser-not-supported-table-img-cell {
	text-align: center;
	padding: 5px;
	width: 45px;
}

.nb-browser-icon {
	height: 20px;
	width: 20px;
}

.nb-browser-login-container {
	color: $nb-textcolor !important;
	background-color: #ffffff;
	padding: 10px 10px 5px 10px;
	margin-bottom: 10px;
	border: 1px solid #ffffff;
	border-radius: 4px;
	margin-left: 1px;
	margin-right: 1px;
}

.nb-login-browser-label {
	color: $nb-neutral !important;
	background-color: $nb-primary;
	margin-top: 10px;
}

.nb-browser-link-text-color {
	color: $nb-textcolor !important;
}
