.uib-timepicker {
	.uib-time {
		vertical-align: top;
	}

	.uib-separator {
		padding-right: 1px;
		padding-left: 1px;
		padding-bottom: 10px;
	}
	.am-pm {
		position: relative;
		padding-left: 3px;
	}
}
