.input-group {
	position: relative !important;
	display: table !important;
	border-collapse: separate !important;
}
.input-group[class*="col-"] {
	float: none !important;
	padding-right: 0 !important;
	padding-left: 0 !important;
}
.input-group .form-control {
	position: relative !important;
	z-index: 2 !important;
	float: left !important;
	width: 100% !important;
	margin-bottom: 0 !important;
}
.input-group .form-control:focus {
	z-index: 3 !important;
}
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
	height: 46px !important;
	padding: 10px 16px !important;
	font-size: 18px !important;
	line-height: 1.3333333 !important;
	border-radius: 6px !important;
}
select.input-group-lg > .form-control,
select.input-group-lg > .input-group-addon,
select.input-group-lg > .input-group-btn > .btn {
	height: 46px !important;
	line-height: 46px !important;
}
textarea.input-group-lg > .form-control,
textarea.input-group-lg > .input-group-addon,
textarea.input-group-lg > .input-group-btn > .btn,
select[multiple].input-group-lg > .form-control,
select[multiple].input-group-lg > .input-group-addon,
select[multiple].input-group-lg > .input-group-btn > .btn {
	height: auto !important;
}
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
	height: 30px !important;
	padding: 5px 10px !important;
	font-size: 12px !important;
	line-height: 1.5 !important;
	border-radius: 3px !important;
}
select.input-group-sm > .form-control,
select.input-group-sm > .input-group-addon,
select.input-group-sm > .input-group-btn > .btn {
	height: 30px !important;
	line-height: 30px !important;
}
textarea.input-group-sm > .form-control,
textarea.input-group-sm > .input-group-addon,
textarea.input-group-sm > .input-group-btn > .btn,
select[multiple].input-group-sm > .form-control,
select[multiple].input-group-sm > .input-group-addon,
select[multiple].input-group-sm > .input-group-btn > .btn {
	height: auto !important;
}
.input-group-addon,
.input-group-btn,
.input-group .form-control {
	display: table-cell !important;
}
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
	border-radius: 0 !important;
}
.input-group-addon,
.input-group-btn {
	width: 1% !important;
	white-space: nowrap !important;
	vertical-align: middle !important;
}
.input-group-addon {
	padding: 6px 12px !important;
	font-size: 14px !important;
	font-weight: normal !important;
	line-height: 1 !important;
	// color: #555 !important;
	text-align: center !important;
	// background-color: #eee !important;
	// border: 1px solid #ccc !important;
	border: $nb-form-border !important;
	border-radius: 4px !important;
}
.input-group-addon.input-sm {
	padding: 5px 10px !important;
	font-size: 12px !important;
	border-radius: 3px !important;
}
.input-group-addon.input-lg {
	padding: 10px 16px !important;
	font-size: 18px !important;
	border-radius: 6px !important;
}
.input-group-addon input[type="radio"],
.input-group-addon input[type="checkbox"] {
	margin-top: 0 !important;
}
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}
.input-group-addon:first-child {
	border-right: 0 !important;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}
.input-group-addon:last-child {
	border-left: 0 !important;
}
.input-group-btn {
	position: relative !important;
	font-size: 0 !important;
	white-space: nowrap !important;
}
.input-group-btn > .btn {
	position: relative !important;
}
.input-group-btn > .btn + .btn {
	margin-left: -1px !important;
}
.input-group-btn > .btn:hover,
.input-group-btn > .btn:focus,
.input-group-btn > .btn:active {
	z-index: 2 !important;
}
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group {
	margin-right: -1px !important;
}
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
	z-index: 2 !important;
	margin-left: -1px !important;
}
