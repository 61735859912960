.mat-mdc-checkbox {
    &.mat-primary,
    &.mat-accent {
        --mdc-checkbox-selected-checkmark-color: var(--color-white);
    }
}

.mat-mdc-checkbox-disabled .mdc-checkbox__background {
	background-color: #d1cece !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:not(:checked)~.mdc-checkbox__background {
	border-color: #d1ceced4 !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
    color: #fff !important;
}

.mat-mdc-checkbox .mdc-checkbox__ripple {
	background: none;
}

.mdc-checkbox--disabled .mdc-checkbox{
    cursor: not-allowed;
	pointer-events: auto;
}
