.paging-toolbar {
	min-width: 100%;
	padding-top: $padding-sm;
	padding-bottom: $padding-sm;
	background-color: $white;
	border-top: $nb-border;
	.paging-toolbar-totalCount {
		@include nb-text;
	}

	.paging-toolbar-number-per-page {
		margin-left: $padding-sm;
		margin-right: $padding-sm;
		width: auto;
		float: left;

		select {
			height: 28px;
			border: 1px solid #dddddd;
			padding: 0px $padding-sm;
			border-radius: 4px;
			font-size: 12px;
		}
	}

	.paging-toolbar-buttons {
		margin-top: 0px;
		margin-bottom: 0px;
		margin-left: 5px;
		margin-right: 0px;
		float: left;
	}
}
.pagination-resultcount {
	display: flex;
	.paging-toolbar-totalCount {
		margin-top: 6px;
	}
}
