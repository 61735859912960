.mat-mdc-text-field-wrapper {
    height: 35px !important;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    font-size: 14px !important;
}

.mdc-text-field--filled .mdc-floating-label {
	font-size: 14px !important;
}

.mat-mdc-icon-button img, .mat-mdc-icon-button svg {
    width: 18px !important;
    height: 18px !important;
	position: relative;
	top: -8px;
	left: -8px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 18px !important;
}

.mat-mdc-form-field-infix {
    padding-top: 12px !important;
    padding-bottom: 4px !important;
	min-height: 45px !important;
	width: 140px !important;
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
    padding-right: 5px !important;
	padding-left: 5px !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #fff !important;
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
    padding-left: 5px;
}

.mat-mdc-form-field-hint-wrapper {
    padding-left: 5px !important;
}

.mat-calendar-body-selected {
    background-color: #00D9AC !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: #00D9AC !important;
}

mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 25px !important;
}

.mat-mdc-form-field-subscript-wrapper {
	height: 0px;
}
