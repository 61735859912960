// -----------------------------------------------------
// Type-ahead

.scrollable-dropdown-menu .dropdown-menu {
	width: 100%;
	max-height: 400px;
	overflow-y: auto;
}

.nb-typeahead-suggestion {
	// font-family:$nb-font;
	// font-size:14px;
	// color:$nb-textcolor;
	@include nb-text;
}

.nb-typeahead-suggestion > .active > a:hover,
.nb-typeahead-suggestion > .active > a,
.nb-typeahead-suggestion > .active > a:focus {
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
	color: $nb-textcolor;
	background-color: $nb-neutral-light;
	text-decoration: none;
	outline: 0;
}
